import { useEffect, useState } from "react";

export default function useWindowSize() {
  const isSSR = typeof window === "undefined";
  const [windowSize, setWindowSize] = useState({
    width: isSSR ? 1200 : window.innerWidth,
    height: isSSR ? 800 : window.innerHeight,
  });

  useEffect(() => {
    function changeWindowSize() {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    }

    if (!isSSR) {
      window.addEventListener("resize", changeWindowSize);
      changeWindowSize();
    }

    return () => {
      if (!isSSR) {
        window.removeEventListener("resize", changeWindowSize);
      }
    };
  }, [isSSR]);

  return windowSize;
}
