/* eslint-disable no-unused-vars */
import Styles from "./styles.module.scss";
import React, { useEffect, useState } from "react";
// import Pagination from "@Molecule/Pagination";
import Icon from "@Atom/Icon";
import Checkbox from "@Atom/Checkbox";
import { Link } from "react-router-dom";

// import LoadingAnimation from "@Atom/LoadingAnimation";
// import PLACEHOLDER_TABLE from "@Assets/images/placeholder-table.png";

const Table = (props) => {
  const {
    data,
    columns,
    isLoading,
    type,
    itemsPerPage,
    textLabel,
    // ICCID,
    // MSISDN,
    // others,
    // distribution,
    // stockOpname,
    // master,
    placeholderText,
    total,
    selectedPage,
    setSelectedPage,
    searchTerm,
    setSearchTerm,
    selectedRow,
    recipient,
    selectedItems,
    setSelectedItems,
    selectedUsers,
    setSelectedUsers,
    link,
  } = props;

  // const [sortConfig, setSortConfig] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  // console.log(selectAll);

  // const handleSort = (columnName) => {
  //   let direction = "ascending";

  //   if (
  //     sortConfig &&
  //     sortConfig.column === columnName &&
  //     sortConfig.direction === "ascending"
  //   ) {
  //     direction = "descending";
  //   }

  //   setSortConfig({ column: columnName, direction });
  // };

  //   const handleChecked = (item) => {
  //     let isSelected = false;
  //     for (let i = 0; i < selectedItems.length; i++) {
  //       if (selectedItems[i]?._id === item?._id) {
  //         isSelected = true;
  //         break;
  //       }
  //     }
  //     return isSelected;
  //   };

  //   const handleCheckboxChange = (item) => {
  //     let isSelected = handleChecked(item);
  //     if (isSelected) {
  //       setSelectedItems(
  //         selectedItems.filter((selected) => selected?._id !== item?._id)
  //       );
  //     } else {
  //       setSelectedItems([...selectedItems, item]);
  //     }
  //   };

  // const handleSort = (column) => {
  //   if (column === sortColumn) {
  //     setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  //   } else {
  //     setSortColumn(column);
  //     setSortDirection("asc");
  //   }
  // };

  const handleSort = (columnKey) => {
    if (sortColumn === columnKey) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(columnKey);
      setSortDirection("asc");
    }
  };

  const handleMouseEnter = (rowId) => {
    // console.log(rowId, "ROW ID");
    setHoveredRow(rowId);
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedItems([]);
    } else {
      setSelectedItems(data);
    }

    setSelectAll(!selectAll);
  };

  // const handleFilterChange = (event) => {
  //   setFilterValue(event.target.value);
  // };

  const sortedData = sortColumn
    ? [...data].sort((a, b) => {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];
        if (aValue < bValue) return sortDirection === "asc" ? -1 : 1;
        if (aValue > bValue) return sortDirection === "asc" ? 1 : -1;
        return 0;
      })
    : data;
  // const sortedData = data.sort((a, b) => {
  //   const valueA = a[sortColumn];
  //   const valueB = b[sortColumn];
  //   if (sortDirection === "asc") {
  //     return valueA > valueB ? 1 : -1;
  //   } else {
  //     return valueA < valueB ? 1 : -1;
  //   }
  // });

  // Filter data based on search value
  const filteredData = searchTerm
    ? sortedData.filter((row) => {
        return Object.values(row).some((value) =>
          String(value).toLowerCase().includes(searchTerm.toLowerCase())
        );
      })
    : sortedData;

  const totalItems = filteredData?.length;
  // const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = filteredData?.slice(startIndex, endIndex);

  // console.log(selectedItems, 'selected')

  // console.log(recipient?.map((each) => each?.name), 'wwww')

  const isRecipient = recipient?.map((each) => each?.name);

  // master
  const handleCheckedMaster = (item) => {
    let isSelected = false;
    for (let i = 0; i < selectedUsers.length; i++) {
      if (selectedUsers[i]?.id === item?.id) {
        isSelected = true;
        break;
      }
    }
    return isSelected;
  };

  // console.log(selectedUsers);

  const handleCheckboxChangeMaster = (item) => {
    let isSelected = handleCheckedMaster(item);
    if (isSelected) {
      setSelectedUsers(
        selectedUsers.filter((selected) => selected?.id !== item?.id)
      );
    } else {
      setSelectedUsers([...selectedUsers, item]);
    }
  };

  if (type === "admin-table") {
    return (
      <div className={Styles.body}>
        {isLoading ? (
          <div className={Styles.loading}>{/* <LoadingAnimation /> */}</div>
        ) : (
          <div className={Styles.tableContainer}>
            {data?.length === 0 ? (
              <div className={Styles.placeholder}>
                {/* <img src={PLACEHOLDER_TABLE} alt="placeholder" /> */}
                <span>{placeholderText}</span>
              </div>
            ) : (
              <table
                style={{
                  width: type?.toLowerCase()?.includes("sales") ? "100%" : "",
                }}
              >
                <thead>
                  <tr>
                    {/* <th className={Styles.stickyColumnDefault}></th> */}
                    {columns?.map((column) => (
                      <th
                        key={column.name}
                        onClick={() => handleSort(column.name)}
                        className={Styles.defaultTH}
                      >
                        <div className={Styles.column}>
                          {column.label}
                          {sortColumn === column.name && (
                            <span>
                              {sortDirection.direction === "asc" ? (
                                <Icon icon="chevron-up-down" size="14" />
                              ) : (
                                <Icon icon="chevron-up-down" size="14" />
                              )}
                            </span>
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                  {/* 
                <tr>
                  <th className="sticky-column"></th>
                  {columns.map((column) => (
                    <th key={column.key}>{column.label}</th>
                  ))}
                </tr> */}
                </thead>
                <tbody>
                  {currentItems?.map((row, index) => (
                    <tr
                      key={index}
                      className={`${
                        row.id === hoveredRow || row?.id === selectedRow
                          ? Styles.hovered
                          : ""
                      } ${Styles.hoveredFirstRow}`}
                      onMouseEnter={() => handleMouseEnter(row.id)}
                      onMouseLeave={handleMouseLeave}
                    >
                      {/* <td className={Styles.stickyColumnDefault}>{row.id}</td> */}
                      {columns?.map((column) => (
                        <td
                          key={column.name}
                          // className={Styles.stickyColumnDefault}
                        >
                          <Link to={link + row.id} className={Styles.linkTable}>
                            {column.render
                              ? column.render(row)
                              : row[column.name]}
                          </Link>
                        </td>
                      ))}
                    </tr>
                  ))}

                  {/* <td className="sticky-column">{row.id}</td>
                {columns.map((column) => (
                  <td key={column.key}>
                    {column.render ? (
                      column.render(row)
                    ) : (
                      row[column.key]
                    )}
                  </td>
                ))} */}
                </tbody>
              </table>
            )}
          </div>
        )}
      </div>
    );
  }

  if (type === "admin-service") {
    return (
      <div className={Styles.body}>
        {isLoading ? (
          <div className={Styles.loading}>{/* <LoadingAnimation /> */}</div>
        ) : (
          <div className={Styles.tableContainer}>
            {data?.length === 0 ? (
              <div className={Styles.placeholder}>
                {/* <img src={PLACEHOLDER_TABLE} alt="placeholder" /> */}
                <span>{placeholderText}</span>
              </div>
            ) : (
              <table
                className={Styles.tableServices}
                style={{
                  width: type?.toLowerCase()?.includes("sales") ? "100%" : "",
                }}
              >
                <thead>
                  <tr>
                    {/* <th className={Styles.stickyColumnDefault}></th> */}
                    {columns?.map((column) => (
                      <th
                        key={column.name}
                        onClick={() => handleSort(column.name)}
                        className={Styles.defaultTHServices}
                      >
                        <div className={Styles.column}>
                          {column.label}
                          {sortColumn === column.name && (
                            <span>
                              {sortDirection.direction === "asc" ? (
                                <Icon icon="chevron-up-down" size="14" />
                              ) : (
                                <Icon icon="chevron-up-down" size="14" />
                              )}
                            </span>
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                  {/* 
                <tr>
                  <th className="sticky-column"></th>
                  {columns.map((column) => (
                    <th key={column.key}>{column.label}</th>
                  ))}
                </tr> */}
                </thead>
                <tbody>
                  {currentItems?.map((row, index) => (
                    <tr
                      key={index}
                      className={`${
                        row.id === hoveredRow || row?.id === selectedRow
                          ? Styles.hovered
                          : ""
                      } ${Styles.hoveredFirstRow}`}
                      onMouseEnter={() => handleMouseEnter(row.id)}
                      onMouseLeave={handleMouseLeave}
                    >
                      {/* <td className={Styles.stickyColumnDefault}>{row.id}</td> */}
                      {columns?.map((column) => (
                        <td
                          key={column.name}
                          className={Styles.stickyColumnDefault}
                        >
                          {column.render
                            ? column.render(row)
                            : row[column.name]}
                        </td>
                      ))}
                    </tr>
                  ))}

                  {/* <td className="sticky-column">{row.id}</td>
                {columns.map((column) => (
                  <td key={column.key}>
                    {column.render ? (
                      column.render(row)
                    ) : (
                      row[column.key]
                    )}
                  </td>
                ))} */}
                </tbody>
              </table>
            )}
          </div>
        )}
      </div>
    );
  }

  if (type === "Permata-Motor") {
    return (
      <div className={Styles.body}>
        {isLoading ? (
          <div className={Styles.loading}>{/* <LoadingAnimation /> */}</div>
        ) : (
          <div className={Styles.tableContainerUsers}>
            {data?.length === 0 ? (
              <div className={Styles.placeholder}>
                {/* <img src={PLACEHOLDER_TABLE} alt="placeholder" /> */}
                <span>{placeholderText}</span>
              </div>
            ) : (
              <table>
                <thead>
                  <tr
                  // className={Styles.withCheckbox}
                  >
                    <th className={Styles.stickyColumn}>
                      {/* <Checkbox
                        checked={selectAll}
                        onChange={handleSelectAll}
                        disabled={recipient?.name ? false : true}
                      /> */}
                    </th>
                    {columns?.map((column) => (
                      <th
                        key={column.name}
                        onClick={() => handleSort(column.name)}
                        // className={Styles.th}
                      >
                        <div className={Styles.column}>
                          {column.label}
                          {sortColumn === column.name && (
                            <span>
                              {sortDirection.direction === "asc" ? (
                                <Icon icon="chevron-up-down" size="14" />
                              ) : (
                                <Icon icon="chevron-up-down" size="14" />
                              )}
                            </span>
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {currentItems?.map((row, index) => (
                    <tr
                      key={index}
                      className={`${
                        row.id === hoveredRow ? Styles.hovered : ""
                      } ${Styles.hoveredFirstRow}`}
                      onMouseEnter={() => handleMouseEnter(row.id)}
                      onMouseLeave={handleMouseLeave}
                    >
                      <td className={Styles.stickyColumn}>
                        <div className={Styles.checkBox}>
                          <Checkbox
                            checked={handleCheckedMaster(row)}
                            onChange={() => handleCheckboxChangeMaster(row)}
                            // disabled={row.recipient.name ? true : false}
                          />
                        </div>
                      </td>

                      {columns?.map((column) => (
                        <td key={column.name}>
                          {column.render
                            ? column.render(row)
                            : row[column.name]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={Styles.body}>
      {isLoading ? (
        <div className={Styles.loading}>{/* <LoadingAnimation /> */}</div>
      ) : (
        <div className={Styles.tableContainerUsers}>
          {data?.length === 0 ? (
            <div className={Styles.placeholder}>
              {/* <img src={PLACEHOLDER_TABLE} alt="placeholder" /> */}
              <span>{placeholderText}</span>
            </div>
          ) : (
            <table
              style={{
                width: type?.toLowerCase()?.includes("sales") ? "100%" : "",
              }}
            >
              <thead>
                <tr>
                  {/* <th className={Styles.stickyColumnDefault}></th> */}
                  {columns?.map((column) => (
                    <th
                      key={column.name}
                      onClick={() => handleSort(column.name)}
                    >
                      <div className={Styles.column}>
                        {column.label}
                        {sortColumn === column.name && (
                          <span>
                            {sortDirection.direction === "asc" ? (
                              <Icon icon="chevron-up-down" size="14" />
                            ) : (
                              <Icon icon="chevron-up-down" size="14" />
                            )}
                          </span>
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
                {/* 
                <tr>
                  <th className="sticky-column"></th>
                  {columns.map((column) => (
                    <th key={column.key}>{column.label}</th>
                  ))}
                </tr> */}
              </thead>
              <tbody>
                {currentItems?.map((row, index) => (
                  <tr
                    key={index}
                    className={`${
                      row.id === hoveredRow || row?.id === selectedRow
                        ? Styles.hovered
                        : ""
                    } ${Styles.hoveredFirstRow}`}
                    onMouseEnter={() => handleMouseEnter(row.id)}
                    onMouseLeave={handleMouseLeave}
                  >
                    {/* <td className={Styles.stickyColumnDefault}>{row.id}</td> */}
                    {columns?.map((column) => (
                      <td
                        key={column.name}
                        // className={Styles.stickyColumnDefault}
                      >
                        {column.render ? column.render(row) : row[column.name]}
                      </td>
                    ))}
                  </tr>
                ))}

                {/* <td className="sticky-column">{row.id}</td>
                {columns.map((column) => (
                  <td key={column.key}>
                    {column.render ? (
                      column.render(row)
                    ) : (
                      row[column.key]
                    )}
                  </td>
                ))} */}
              </tbody>
            </table>
          )}
        </div>
      )}
    </div>
  );
};

export default Table;
