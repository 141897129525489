import React from "react";
import Styles from "./styles.module.scss";
import useWindowSize from "@Hooks/useWindowSize";
import Icon from "@Atom/Icon";

export default function ListingPagination({ page, setPage, maxPage }) {
  const { width } = useWindowSize();

  const renderPageButtons = () => {
    const buttons = [];
    if (maxPage === 1) {
      return (
        <button
          key={`only`}
          onClick={() => setPage(1)}
          className={`${Styles.filterPageBullet} ${
            page === 1 && Styles.active
          }`}
        >
          <span>1</span>
        </button>
      );
    }
    buttons.push(
      <button
        key={`first`}
        onClick={() => setPage(1)}
        className={`${Styles.filterPageBullet} ${page === 1 && Styles.active}`}
      >
        <span>1</span>
      </button>
    );

    // Kondisi untuk menampilkan start ellipsis
    if (page > 4) {
      buttons.push(
        <div
          key="start-ellipsis"
          className={`${Styles.pageEllipsis} flex-center`}
          // style={width < 768 ? { display: "none" } : { undefined }}
        >
          <span>...</span>
        </div>
      );
    }

    const startPage = Math.max(2, page - 2);
    const endPage = Math.min(
      maxPage - 1,
      width < 460 && page > 2 ? page + 1 : page > 3 ? page + 1 : page + 2
    );

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={`page-${i}`}
          onClick={() => setPage(i)}
          className={`${Styles.filterPageBullet} ${
            page === i && Styles.active
          }`}
        >
          <span>{i}</span>
        </button>
      );
    }

    // Kondisi untuk menampilkan end ellipsis
    if (page < maxPage - 2) {
      buttons.push(
        <div
          key="end-ellipsis"
          className={`${Styles.pageEllipsis} flex-center`}
        >
          <span>...</span>
        </div>
      );
    }
    if (maxPage > 1) {
      buttons.push(
        <button
          key={`last`}
          onClick={() => setPage(maxPage)}
          className={`${Styles.filterPageBullet} ${
            page === maxPage && Styles.active
          }`}
        >
          <span>{maxPage}</span>
        </button>
      );
    }

    return buttons;
  };

  return (
    <div className={`${Styles.filterPagination} flex-justify-center`}>
      <Icon
        icon={"chevron-left"}
        className={Styles.iconPagination}
        color={page === 1 ? "#C7C7C7" : "#000"}
        style={page === 1 ? { cursor: "not-allowed" } : { undefined }}
        onClick={page === 1 ? () => {} : () => setPage((prev) => prev - 1)}
      />
      {renderPageButtons()}
      <Icon
        icon={"chevron-right"}
        className={Styles.iconPagination}
        onClick={
          page === maxPage ? () => {} : () => setPage((prev) => prev + 1)
        }
        color={page === maxPage ? "#C7C7C7" : "#000"}
        style={page === maxPage ? { cursor: "not-allowed" } : { undefined }}
      />
    </div>
  );
}
