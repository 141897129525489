import SidebarCard from "@Molecule/SidebarCard/";
import Styles from "./styles.module.scss";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function Admin() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname === "/") {
      navigate("/admin/motor");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={Styles.container}>
      <div className={Styles.sidebar}>
        <SidebarCard />
      </div>
      <Outlet />
      {/* <Footer /> */}
    </div>
  );
}
