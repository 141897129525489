import React, { useCallback, useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import { NavbarAdmin } from "@Molecule/NavbarAdmin";
import Icon from "@Atom/Icon";
import InputForm from "@Atom/Input";
import AdminDatePicker from "@Atom/AdminDatePicker";
import TableServices from "@Atom/tableServices";
// import Pagination from "@Molecule/Pagination";
import Modal from "@Atom/Modal";
import { useLocation, useNavigate } from "react-router-dom";
import { deletePromo } from "@Services/admin/promo/deletePromo";
import { updatePromo } from "@Services/admin/promo/updatePromo";
import { useDebounce } from "@Hooks/useDebounce";
import useQuery from "@Hooks/useQuery";
import moment from "moment";
import { deleteMotorPromo } from "@Services/admin/promo/deleteMotorPromo";
import { formatPrice } from "utility/formatCurrency";
import { formatDate } from "utility/formatDate";
import { getVariant } from "@Services/admin/promo/fetchVariant";
import { fileBaseUrl } from "@Config/index";
import { addMotorPromo } from "@Services/admin/promo/addMotorPromo";
import Spinner from "@Atom/Spinner";
import EmptyHandlerSearch from "@Atom/EmptyHandlerSearch";
import ListingPagination from "@Atom/ListingPagination";

export default function DetailPromo({ dataDetail }) {
  moment.locale("id");
  const [variantIdMotor, setVariantIdMotor] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [addPromo, setIsAddPromo] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [loadingPost, setLoadingPost] = useState(false);
  const [isErrorDeleteProduct, setIsErrorDeleteProduct] = useState(false);
  const [selectedButton, setSelectedButton] = useState("DetilPromo");
  const sessionSelectedButtonId = sessionStorage.getItem("idButtonMotorcycle");
  const navigate = useNavigate();

  useEffect(() => {
    if (sessionSelectedButtonId) {
      setSelectedButton(sessionSelectedButtonId);
    } else {
      setSelectedButton("Table");
    }
  }, [sessionSelectedButtonId]);
  const handleDeletePromoVariant = useCallback(
    async (id) => {
      setLoadingPost(true);
      try {
        const response = await deleteMotorPromo(id);
        if (response?.status !== 200) {
          setIsErrorDeleteProduct(true);
          setLoadingPost(false);
        } else {
          console.log(`deleted product promo id: ${id} `);
          setLoadingPost(false);
          navigate("", { replace: true });
        }
      } catch (error) {
        console.log("error: ", error);
        setLoadingPost(false);
        setIsErrorDeleteProduct(false);
      }
    },
    [navigate]
  );
  const dataTable = useMemo(() => {
    return {
      data: dataDetail?.motor?.data?.map((item, idx) => {
        return {
          id: idx + 1 || "0",
          name: item?.name || "-",
          category: item?.category || "-",
          firstPrice: "Rp " + item?.price.toLocaleString("id") || 0,
          afterPromo: "Rp " + item.discount.toLocaleString("id") || 0,
          delete: (
            <Icon
              icon={"x-mark"}
              color={"#D1001C"}
              size={"24"}
              style={{ cursor: "pointer" }}
              onClick={() => handleDeletePromoVariant(item?.promoVariantId)}
            />
          ),
        };
      }),

      columns: [
        {
          name: "id",
          label: "No",
        },
        {
          name: "name",
          label: "Nama Produk",
        },
        {
          name: "category",
          label: "Kategori",
        },
        {
          name: "firstPrice",
          label: "Harga Awal ",
        },
        {
          name: "afterPromo",
          label: "Harga Setelah Promo",
        },
        {
          name: "delete",
          label: "",
        },
      ],
    };
  }, [dataDetail?.motor?.data, handleDeletePromoVariant]);

  const [startDate, setStartDate] = useState(
    formatDate(dataDetail?.promo?.startDate)
  );
  const [endDate, setEndDate] = useState(
    formatDate(dataDetail?.promo?.endDate)
  );
  const listDropdown = ["Rupiah", "Persentase"];
  const [dropdown, setDropdown] = useState(
    dataDetail?.promo?.discount?.includes("%") ? "Persentase" : "Rupiah"
  );
  const [selectedMotors, setSelectedMotors] = useState([]);
  const [selectedMotorsAll, setSelectedMotorsAll] = useState();
  const handleAddMotor = (id) => {
    if (addPromo) {
      setVariantIdMotor((prevSelected) => {
        if (prevSelected.includes(id)) {
          return prevSelected.filter((motorId) => motorId !== id);
        } else {
          return [
            ...prevSelected,
            selectedMotorsAll && !prevSelected.includes(id) ? id : id,
          ];
        }
      });
    }
  };
  const handleSelectAll = () => {
    if (selectedMotorsAll) {
      setSelectedMotors([]);
      setSelectedMotorsAll(false);
    } else {
      setSelectedMotors(dataVariant.map((el) => el?.variantID));
      setSelectedMotorsAll(true);
    }
  };
  const [value, setValue] = useState({
    name: dataDetail?.promo?.name,
    discount: dataDetail?.promo?.discount,
    startDate: dataDetail?.promo?.startDate,
    endDate: dataDetail?.promo?.endDate,
  });
  const isNull = !(
    value?.name &&
    value?.discount &&
    value?.startDate &&
    value?.endDate
  );
  useEffect(() => {
    setVariantIdMotor((prev) => [...prev, ...selectedMotors]);
  }, [selectedMotors]);
  console.log("selec");
  const addItemsPromo = {
    promoID: dataDetail?.promo?.id,
    variantID: variantIdMotor,
  };
  const handleChangeDiscount = (event) => {
    let { name, value } = event?.target;
    if (dropdown === "Persentase") {
      if (parseInt(value) > 100) value = "100";
      setValue((prev) => ({
        ...prev,
        discount: value + "%",
      }));
    } else {
      const numericValue = value.replace(/[^\d]/g, "");
      if (/^\d*$/.test(numericValue)) {
        setValue((prev) => ({
          ...prev,
          [name]: numericValue,
        }));
      }
    }
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  useEffect(() => {
    setValue((prevValue) => ({
      ...prevValue,
      startDate: startDate,
      endDate: endDate,
    }));
  }, [startDate, endDate]);
  useEffect(() => {
    setValue((prevValue) => ({
      ...prevValue,
      variantId: selectedMotors,
    }));
  }, [selectedMotors]);

  const handleDeletePromo = async (id) => {
    setLoadingPost(true);
    try {
      const response = await deletePromo(id);
      if (response?.status !== 200) {
        console.log(`delete promo error id: ${id} `);
        setLoadingPost(false);
      } else {
        setLoadingPost(false);
        console.log(`delete promo success id: ${id} `);
        navigate("/admin/promo");
        console.log("response", response);
      }
    } catch (error) {
      console.log("error: ", error);
      setLoadingPost(false);
    }
  };

  const promoUnAvailable = {
    name: value?.name,
    discount: value?.discount,
    startDate: value?.startDate,
    endDate: value?.endDate,
    status: "unavailable",
  };
  const handleNonActivePromo = async (e) => {
    e?.preventDefault();
    setLoadingPost(true);
    try {
      const response = await updatePromo(
        dataDetail?.promo?.id,
        promoUnAvailable
      );
      if (response?.status === 200) {
        setLoadingPost(false);
        navigate("/admin/promo");
      } else {
        setLoadingPost(false);
        console.log("promo updated", response);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };
  const handleUpdatePromo = async (e) => {
    e?.preventDefault();
    setLoadingPost(true);
    try {
      const response = await updatePromo(dataDetail?.promo?.id, value);
      if (response?.status === 200) {
        setLoadingPost(false);
        navigate("/admin/promo");
      } else {
        setLoadingPost(false);
        console.log("updated", response);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };
  const handleAddPromoMotor = async (e) => {
    e?.preventDefault();
    setLoadingPost(true);
    setIsAddPromo(false);
    try {
      const response = await addMotorPromo(addItemsPromo);
      if (response?.status === 200) {
        setLoadingPost(false);
        navigate("/admin/promo/detail/");
      } else {
        setLoadingPost(false);
        console.log("updated", response);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };
  const [limit, setlimit] = useState(10);
  // const [pages, setPages] = useState(0);
  // const [pageList, setPageList] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const [loadingSearch, setLoadingSearch] = useState(false);

  useEffect(() => {
    if (debouncedSearchQuery?.length > 1) {
      setLoadingSearch(true);
    }
    setTimeout(() => {
      setLoadingSearch(false);
    }, 500);
  }, [debouncedSearchQuery]);
  const { pathname } = useLocation();
  const query = useQuery();
  const pageQuery = query?.get("page");
  const limitQuery = query?.get("limit");
  useEffect(() => {
    setSelectedPage(+pageQuery || 1);
    setlimit(+limitQuery || 10);
  }, [limitQuery, pageQuery]);
  useEffect(() => {
    if (searchQuery) {
      const prevPage = Math.min(dataDetail?.totalPages, selectedPage);
      navigate(
        `${pathname}?limit=${limit}&page=${prevPage}&search=${debouncedSearchQuery}`,
        {
          replace: true,
        }
      );
    } else {
      navigate(`${pathname}?limit=${limit}&page=${selectedPage}`, {
        replace: true,
      });
    }
  }, [
    dataDetail?.totalPages,
    debouncedSearchQuery,
    limit,
    navigate,
    pathname,
    searchQuery,
    selectedPage,
  ]);
  const [searchQueryVariant, setSearchQueryVariant] = useState("");
  const debounceQueryVariant = useDebounce(searchQueryVariant, 500);
  const [dataVariant, setDataVariant] = useState();
  const fetchVariant = useCallback(async () => {
    try {
      const response = await getVariant(debounceQueryVariant);
      setDataVariant(response?.response);
    } catch (error) {
      console.log(error);
    }
  }, [debounceQueryVariant]);
  useEffect(() => {
    fetchVariant();
  }, [fetchVariant]);
  return (
    <div className={Styles.wrapperAddPromo} style={{ width: "1116px" }}>
      {isErrorDeleteProduct && (
        <Modal handleDone={() => setIsErrorDeleteProduct()}>
          <h1 style={{ padding: "1rem" }}>Delete Promo Produk Error</h1>
        </Modal>
      )}
      {loadingPost && (
        <Modal style={{ border: "none", width: "auto" }}>
          <Spinner
            style={{
              background: "#00000080",
              padding: "0",
              border: "none",
            }}
          />
        </Modal>
      )}
      <NavbarAdmin
        icon={"promo"}
        size={32}
        color={"#FFFFFF"}
        label={"Detail Promo"}
        placeholder={"Cari Produk Promo"}
        onChange={(e) => setSearchQuery(e.target.value)}
        value={searchQuery}
      />
      <section className={Styles.mainContent}>
        <div className={Styles.header}>
          <div className={Styles.between}>
            <div className={Styles.iconHeader}>
              <Icon
                icon={"panahkanan"}
                className={Styles.icon}
                onClick={() => navigate("/admin/promo")}
              />
            </div>
            <h1>Detail Promo</h1>
          </div>
          {!isEdit ? (
            <div className={Styles.wrapperCta}>
              <div
                className={Styles.btn}
                onClick={() => handleDeletePromo(dataDetail?.promo?.id)}
              >
                <Icon icon={"delete_forever"} className={Styles.icon} />
                <span>Delete Promo</span>
              </div>
              <div
                className={Styles.btn}
                onClick={() => handleNonActivePromo()}
              >
                <Icon icon={"money_off_csred"} className={Styles.icon} />
                <span>Non Aktifkan Promo</span>
              </div>
              <div className={Styles.btn} onClick={() => setIsEdit(!isEdit)}>
                <Icon icon={"Edit"} className={Styles.icon} />
                <span>Edit</span>
              </div>
            </div>
          ) : (
            <div
              className={Styles.buttonSave}
              onClick={isNull ? () => {} : () => handleUpdatePromo()}
              is-disabled={isNull?.toString()}
            >
              <Icon icon={"check_circle"} color={"white"} size={"24"} />
              <span>Update Promo</span>
            </div>
          )}
        </div>
        <div className={Styles.divider}></div>
        <div className={Styles.inputField}>
          <div className={Styles.labelInput}>
            <label htmlFor="">Nama Promo</label>
            {!isEdit ? (
              <span>{dataDetail?.promo?.name}</span>
            ) : (
              <InputForm
                placeholder="Nama promo"
                name="name"
                value={value?.name || ""}
                onChange={handleChange}
              />
            )}
          </div>
          <div className={Styles.labelInput}>
            <label htmlFor="">Jumlah Potongan</label>
            {!isEdit ? (
              <span>
                {dataDetail?.promo?.discount?.includes("%")
                  ? dataDetail?.promo?.discount
                  : parseInt(
                      dataDetail?.promo?.discount || "0"
                    )?.toLocaleString("id")}
              </span>
            ) : (
              <div className={Styles.twoInput}>
                <InputForm
                  placeholder="Tipe Potongan"
                  dataMapDropdown={listDropdown}
                  type="dropdown"
                  onChange={setDropdown}
                  value={dropdown}
                />
                <InputForm
                  placeholder="Jumlah Potongan"
                  type={dropdown === "Persentase" ? "percent" : undefined}
                  isDisabled={dropdown === "" ? true : false}
                  name="discount"
                  value={
                    value?.discount?.includes("%")
                      ? value?.discount?.replace(/%/g, "")
                      : formatPrice(value?.discount) || ""
                  }
                  onChange={handleChangeDiscount}
                />
              </div>
            )}
          </div>
          <div className={Styles.labelInput}>
            <label htmlFor="">Periode</label>
            {!isEdit ? (
              <span>
                {moment(dataDetail?.promo?.startDate || null).format(
                  "DD MMMM YYYY"
                )}{" "}
                -{" "}
                {moment(dataDetail?.promo?.endDate || null).format(
                  "DD MMMM YYYY"
                )}
              </span>
            ) : (
              <div className={Styles.twoInput}>
                <AdminDatePicker
                  placeholder="Dari"
                  value={startDate}
                  handleChange={setStartDate}
                />
                <AdminDatePicker
                  placeholder="Hingga"
                  value={value?.endDate}
                  handleChange={setEndDate}
                />
              </div>
            )}
          </div>
        </div>
        <div className={Styles.body}>
          <div className={Styles.box}>
            <>
              <div className={Styles.headerTitle}>
                <h1>Daftar Produk Promo</h1>
                <div
                  className={Styles.addItems}
                  onClick={() => setIsAddPromo(!addPromo)}
                >
                  <div>
                    <Icon icon={"plus"} color={"white"} size={"14"} />
                  </div>
                  <span>Tambah Item</span>
                </div>
              </div>

              <TableServices
                itemsPerPage={10}
                data={dataTable?.data || []}
                placeholderText={<EmptyHandlerSearch />}
                columns={dataTable.columns}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                isLoading={loadingSearch}
                type="promo"
                sticky="Motorcycle Name"
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
              />
              <div className={Styles.pagination}>
                <div className={Styles.paginationLeft}>
                  Memperlihatkan {dataDetail?.motor?.data?.length || 0} dari{" "}
                  {dataDetail?.motor?.totalData} produk
                </div>
                <div className={Styles.paginationRight}>
                  {/* <Pagination
                    total={10}
                    totalPage={dataDetail?.motor?.totalPages || 0}
                    totalData={dataDetail?.motor?.totalData || 0}
                    value={limit}
                    setValue={setlimit}
                    pages={pages}
                    setPages={setPages}
                    pageList={pageList}
                    setPageList={setPageList}
                    selectedPage={selectedPage}
                    setSelectedPage={setSelectedPage}
                  /> */}
                  <ListingPagination
                    page={selectedPage}
                    setPage={setSelectedPage}
                    maxPage={dataDetail?.motor?.totalPages || 0}
                  />
                </div>
              </div>
            </>
          </div>
        </div>
        {addPromo && (
          <Modal
            handleDone={() => setIsAddPromo(false)}
            style={{ width: "90%", overflow: "hidden" }}
          >
            <div
              className={Styles.wrapperModalAddPromo}
              style={{ height: "90%" }}
            >
              <div className={Styles.header}>
                <h2>Daftar Produk</h2>
                <Icon
                  icon={"x-mark"}
                  className={Styles.icon}
                  onClick={() => setIsAddPromo(false)}
                />
              </div>
              <NavbarAdmin
                icon={"promoadmin"}
                size={32}
                color={"#FFFFFF"}
                isRoute={true}
                labelRouteSecond="Onderdil (0 Dipilih)"
                placeholder={"Cari Motor"}
                isHiddenProfile
                isHiddenIcon
                onChange={(e) => setSearchQueryVariant(e?.target?.value)}
                value={searchQueryVariant}
                selectedButton={selectedButton}
                labelRouteFirst={`Motor (${variantIdMotor?.length} Dipilih)`}
              />
              <div className={Styles.checkboxAndfilter}>
                <div>
                  <Icon
                    icon={selectedMotorsAll ? "checked" : "checkbox"}
                    onClick={() => handleSelectAll()}
                    size={"24"}
                    className={Styles.checkboxIcon}
                    color={"#323232"}
                  />
                  <span>Pilih semua motor</span>
                </div>
                <div>
                  <Icon icon={"filter_list"} className={Styles.icon} />
                  <span>Filter</span>
                </div>
              </div>
              <section className={Styles.wrapperCardList}>
                {dataVariant?.map((el, idx) => (
                  <div
                    className={Styles.card}
                    key={`list-product-${idx}`}
                    onClick={() => handleAddMotor(el?.variantID)}
                  >
                    <div className={Styles.header}>
                      <div>
                        <span>{el?.name}</span>
                        <Icon
                          icon={
                            variantIdMotor.includes(el?.variantID)
                              ? "checked"
                              : "checkbox"
                          }
                          size={"24"}
                          className={Styles.checkboxIcon}
                          color={"#23517D"}
                        />
                      </div>
                      <span className={Styles.type}>{el?.type}</span>
                    </div>
                    <div className={Styles.image}>
                      <img src={fileBaseUrl + el?.image} alt="list-product" />
                    </div>
                    <span className={Styles.price}>
                      Rp{parseInt(el?.price).toLocaleString("id")}
                    </span>
                  </div>
                ))}
              </section>
              <div className={Styles.buttonListProduct}>
                <div
                  className={Styles.btn}
                  onClick={() => setIsAddPromo(false)}
                >
                  <span>Batal</span>
                </div>
                <div
                  className={Styles.btn}
                  onClick={() => handleAddPromoMotor()}
                >
                  <span>Simpan</span>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </section>
    </div>
  );
}
