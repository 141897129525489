import EmptyHandlerGetData from "@Atom/ErrorGetData";
import Spinner from "@Atom/Spinner";
import Motorcycle from "@Organism/Admin/Motorcycle";
import { getListMotor } from "@Services/admin/motor/getListMotor";
import { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";
export const loader = async (route) => {
  const params = new URLSearchParams(route?.request?.url);
  const page = params?.get("page") || 1;
  const limit = params?.get("limit") || 10;
  const searchQuery = params?.get("search") || "";

  return defer({
    getDataListMotor: await getListMotor(limit, page, searchQuery),
  });
};
export default function AdminMotorcyclePage() {
  const { getDataListMotor } = useLoaderData();
  return (
    <Suspense fallback={<Spinner />}>
      <Await errorElement={<EmptyHandlerGetData />} resolve={getDataListMotor}>
        {(data) => <Motorcycle datas={data} />}
      </Await>
    </Suspense>
  );
}
