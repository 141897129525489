import style from "./styles.module.scss";
import { ButtonCardAdmin } from "../../Atom/ButtonProductComponent";
import Icon from "@Atom/Icon";
import React, { useState } from "react";
import InputForm from "@Atom/Input";

export const FormAddPromoCard = ({
  setShowAdd,
  setShowAlert,
  // xsetShowAlert,
  setResponProses,
}) => {
  const [image, setImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");

  const handleFile = (file) => {
    setImage(file);
    setPreviewUrl(URL.createObjectURL(file));
    // console.log(file);
  };

  // let handleMouseEnter;
  // let handleMouseLeave;
  const handleOndrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    let imageFile = event.dataTransfer.files[0];
    handleFile(imageFile);
    setAddForm({ ...addForm, photo: imageFile.name });
  };

  function handleOnBrows(event) {
    event.preventDefault();
    event.stopPropagation();
    let ximageFile = event.target.files[0];
    // console.log("ximageFile");
    handleFile(ximageFile);
    setAddForm({ ...addForm, photo: ximageFile.name });
  }

  const handleOndragOver = (event) => {
    event.preventDefault();
    setIsHover(true);
    // console.log(event);
  };

  const [isHover, setIsHover] = useState(false);

  const removePhoto = () => {
    setPreviewUrl("");
    setAddForm({ ...addForm, photo: "" });
  };

  let fileSize;
  let fileType;
  if (image) {
    fileType = image.type.split("/");
    fileSize = (image.size / 1048576).toFixed(2);
  }

  const [addForm, setAddForm] = useState({
    name: "",
    ket: "",
    photo: "",
  });

  const onChange = (e) => {
    setAddForm({ ...addForm, [e.target.name]: e.target.value });
  };

  const [respon, setRespon] = useState(null);

  const handleUp = (e) => {
    setResponProses(e);
  };

  const handleSubmitAdd = () => {
    setShowAlert(true);
    handleUp(respon);
  };

  return (
    <>
      <div className={style.modalBackground}>
        <div className={style.modalContainer}>
          <div className={style.titleModal}>
            <div className={style.titlePromo}>
              <h1>Tambah Promo</h1>
              <p>Isi judul dan unggah foto</p>
            </div>
            <div className={style.buttonClose}>
              <span onClick={() => setShowAdd(false)}>
                <Icon icon={"x-mark"} color={"#000"} size={"24"} />
              </span>
            </div>
          </div>
          <div className={style.inputAddPromo}>
            <form>
              <div className={style.inputImagePromo}>
                <label>Konten Promo</label>
                {previewUrl ? (
                  <div className={style.showImagesPreview}>
                    <img src={previewUrl} alt="preview-url" />
                    <span>
                      {image?.name}
                      <br />
                      <span className={style.detailFile}>
                        {fileType[0]}
                        <Icon
                          icon={"i_010-bullet"}
                          size={12}
                          style={{
                            marginBottom: "-2px",
                          }}
                        />
                        {fileSize}
                        Mb
                        <Icon
                          icon={"i_010-bullet"}
                          size={12}
                          style={{
                            marginBottom: "-2px",
                          }}
                        />
                        {fileType[1]}
                      </span>
                    </span>
                    <div
                      className={style.removePhoto}
                      onClick={() => removePhoto()}
                    >
                      Replace Image
                    </div>
                  </div>
                ) : (
                  <>
                    <div
                      onDragOver={handleOndragOver}
                      onDrop={handleOndrop}
                      onMouseLeave={() => setIsHover(false)}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "1rem",
                      }}
                    >
                      {isHover ? (
                        <div className={style.hoverUpload}>
                          <input
                            type="file"
                            accept="image/*"
                            className="input-field"
                            onChange={handleOnBrows}
                            id="create-course-form"
                          />
                          <div className={style.iconLeft}>
                            <svg
                              width="26"
                              height="35"
                              viewBox="0 0 26 35"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M25.3764 27.3822L13.6521 34.1512L11.9 31.141L18.4869 27.3381L8.95445 24.7839C6.0562 24.0073 3.58515 22.1112 2.08491 19.5127C0.584663 16.9142 0.178115 13.8261 0.954699 10.9279L3.88281 0L7.24523 0.900958L4.31712 11.8288C3.19092 16.0319 5.65239 20.2952 9.85541 21.4214L19.3879 23.9756L15.6017 17.3932L18.6074 15.6579L25.3764 27.3822Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                          <div className={style.iconCenter}>
                            <Icon
                              icon={"logoImages"}
                              size={24}
                              className={style.iconImages}
                            />
                            Letakkan di sini!
                          </div>
                          <div className={style.iconright}>
                            <svg
                              width="18"
                              height="35"
                              viewBox="0 0 18 35"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0.846396 34.6406L0.846396 21.0318L4.33518 21.0318L4.34756 28.6651L11.3622 21.6504C14.4551 18.5575 14.4551 13.6089 11.3622 10.516L3.32071 2.47443L5.79503 0.000109231L13.8366 8.04164C15.9693 10.1744 17.1675 13.067 17.1675 16.0832C17.1675 19.0993 15.9693 21.992 13.8366 24.1247L6.82187 31.1394L14.4675 31.1394L14.4551 34.6406L0.846396 34.6406Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                        </div>
                      ) : (
                        <div className={style.inputImages}>
                          <input
                            type="file"
                            accept="image/*"
                            className="input-field"
                            onChange={handleOnBrows}
                            id="create-course-form"
                          />
                          <Icon
                            icon={"logoImages"}
                            size={24}
                            className={style.iconImages}
                          />
                          <span className={style.ket1}>
                            Taruh dan lepas <span>gambar</span>
                          </span>
                          <span className={style.ket2}>
                            atau{" "}
                            <span
                              onClick={() =>
                                document.querySelector(".input-field").click()
                              }
                              style={{
                                textDecoration: "underline",
                                color: "#357ABB",
                                cursor: "pointer",
                              }}
                            >
                              browse files
                            </span>{" "}
                            pada komputer kamu
                          </span>
                          <p>Dimensi gambar pada ukuran: 3000 x 1500</p>
                        </div>
                      )}
                      <div className={style.inputTitlePromo}>
                        <label>
                          Nama Promo <span style={{ color: "red" }}>*</span>{" "}
                        </label>
                        <InputForm
                          placeholder="Example: Promo 20 Januari 2001"
                          name="name"
                          onChange={onChange}
                        />
                      </div>

                      <div className={style.inputTitlePromo}>
                        <label>
                          Deskripsi <span style={{ color: "red" }}>*</span>
                        </label>
                        <textarea
                          name="ket"
                          placeholder="Tulis deskripsi "
                          onChange={onChange}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className={style.buttonSave}>
                <ButtonCardAdmin
                  nameCancel={"Cancel"}
                  nameSubmit={"Upload"}
                  setShowAdd={setShowAdd}
                  handleSubmitAdd={handleSubmitAdd}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
