import React, { useEffect, useState } from "react";
import Styles from "./style.module.scss";
import { NavbarAdmin } from "@Molecule/NavbarAdmin";
import Icon from "@Atom/Icon";
import InputForm from "@Atom/Input";
import AdminDatePicker from "@Atom/AdminDatePicker";
import TableServices from "@Atom/tableServices";
import EmptyHandlerTable from "@Atom/EmptyHandlerTable";
import Modal from "@Atom/Modal";
import { useLocation, useNavigate } from "react-router-dom";
import { createPromo } from "@Services/admin/promo/createPromo";
import { formatPrice } from "utility/formatCurrency";
import { fileBaseUrl } from "@Config/index";
import { useDebounce } from "@Hooks/useDebounce";

export default function PromoPageLayout({ data }) {
  const [searchQuery, setSearchQuery] = useState("");
  const navigateTo = useNavigate();
  const sessionSelectedButtonId = sessionStorage.getItem("idButtonMotorcycle");
  const [selectedButton, setSelectedButton] = useState("DetilPromo");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [addPromo, setIsAddPromo] = useState(false);

  useEffect(() => {
    if (sessionSelectedButtonId) {
      setSelectedButton(sessionSelectedButtonId);
    } else {
      setSelectedButton("Table");
    }
  }, [sessionSelectedButtonId]);

  const navigate = useNavigate();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const listDropdown = ["Rupiah", "Persentase"];
  const [dropdown, setDropdown] = useState("");
  const [selectedMotors, setSelectedMotors] = useState([]);
  const [selectedMotorsAll, setSelectedMotorsAll] = useState();
  const handleChooseMotor = (id) => {
    setSelectedMotors((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((motorId) => motorId !== id);
      } else {
        return [
          ...prevSelected,
          selectedMotorsAll && !prevSelected.includes(id) ? id : id,
        ];
      }
    });
  };
  const handleSelectAll = () => {
    if (selectedMotorsAll) {
      setSelectedMotors([]);
      setSelectedMotorsAll(false);
    } else {
      setSelectedMotors(data?.response?.map((el) => el?.variantID));
      setSelectedMotorsAll(true);
    }
  };
  const [value, setValue] = useState({
    name: "",
    discount: "",
    startDate: "",
    endDate: "",
    variantId: selectedMotors,
  });
  useEffect(() => {
    setValue((prevValue) => ({
      ...prevValue,
      variantId: selectedMotors,
    }));
  }, [selectedMotors]);
  const isNull = !(
    value?.name &&
    value?.discount &&
    value?.startDate &&
    value?.endDate &&
    value?.variantId?.length
  );
  const handleChange = (event) => {
    let { name, value } = event?.target;
    if (dropdown === "Persentase") {
      if (parseInt(value) > 100) value = "100";
      setValue((prev) => ({
        ...prev,
        discount: value + "%",
      }));
    } else if (dropdown === "Rupiah") {
      const numericValue = value.replace(/[^\d]/g, "");
      if (/^\d*$/.test(numericValue)) {
        setValue((prev) => ({
          ...prev,
          [name]: numericValue,
        }));
      }
    } else {
      setValue((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };
  useEffect(() => {
    if (dropdown) {
      setValue((prev) => ({
        ...prev,
        discount: "",
      }));
    }
  }, [dropdown]);
  useEffect(() => {
    setValue((prevValue) => ({
      ...prevValue,
      startDate: startDate,
      endDate: endDate,
    }));
  }, [startDate, endDate]);

  const handleResetForm = () => {
    setValue({
      name: "",
      discount: "",
      startDate: "",
      endDate: "",
    });
    setStartDate();
    setEndDate();
    setDropdown("");
  };

  const [laodingPost, setLoadingPost] = useState(false);
  const dataToSend = {
    name: value?.name,
    discount: value?.discount,
    startDate: value?.startDate,
    endDate: value?.endDate,
    variantId: selectedMotors,
  };
  const handlePostPromo = async (e) => {
    setIsAddPromo(false);
    e?.preventDefault();
    setLoadingPost(true);
    try {
      const response = await createPromo(dataToSend);

      if (response?.status === 201) {
        setLoadingPost(false);
        handleResetForm();
        navigateTo("/admin/promo");
      } else {
        console.log(response?.data);
        setLoadingPost(false);
      }
    } catch (error) {
      console.log(error);
      setLoadingPost(false);
    }
  };
  const { pathname } = useLocation();
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  useEffect(() => {
    if (searchQuery) {
      navigate(`${pathname}?s=query&search=${debouncedSearchQuery}`, {
        replace: true,
      });
    } else {
      navigate(`${pathname}`);
    }
  }, [debouncedSearchQuery, navigate, pathname, searchQuery]);
  return (
    <div className={Styles.wrapperAddPromo} style={{ minWidth: "1116px" }}>
      {laodingPost && (
        <Modal>
          <h1 style={{ padding: "1rem" }}>Submitting...</h1>
        </Modal>
      )}
      <NavbarAdmin
        icon={"promo"}
        size={32}
        color={"#FFFFFF"}
        label={"Promo"}
        placeholder={"Cari Promo"}
        isHiddenSearchBar
      />
      <section className={Styles.mainContent}>
        <div className={Styles.header}>
          <div className={Styles.between}>
            <div className={Styles.iconHeader}>
              <Icon
                icon={"panahkanan"}
                className={Styles.icon}
                onClick={() => navigate("/admin/promo")}
              />
            </div>
            <h1>Tambah Promo baru</h1>
          </div>

          <div
            className={Styles.buttonSave}
            onClick={isNull ? () => {} : () => handlePostPromo()}
            is-disabled={isNull?.toString()}
            style={isNull ? { cursor: "not-allowed" } : { undefined }}
          >
            <Icon icon={"check_circle"} color={"white"} size={"24"} />
            <span>Simpan Promo</span>
          </div>
        </div>
        <div className={Styles.divider}></div>
        <div className={Styles.inputField}>
          <div className={Styles.labelInput}>
            <label htmlFor="">Nama Promo</label>
            <InputForm
              placeholder="Nama promo"
              name="name"
              value={value?.name || ""}
              onChange={handleChange}
            />
          </div>
          <div className={Styles.labelInput}>
            <label htmlFor="">Jumlah Potongan</label>

            <div className={Styles.twoInput}>
              <InputForm
                placeholder="Tipe Potongan"
                dataMapDropdown={listDropdown}
                type="dropdown"
                onChange={setDropdown}
                value={dropdown}
              />
              <InputForm
                placeholder="Jumlah Potongan"
                type={dropdown === "Persentase" ? "percent" : undefined}
                isDisabled={dropdown === "" ? true : false}
                name="discount"
                // value={value?.discount}
                value={
                  value?.discount?.includes("%")
                    ? value?.discount?.replace(/%/g, "")
                    : formatPrice(value?.discount) || ""
                }
                onChange={handleChange}
              />
            </div>
          </div>
          <div className={Styles.labelInput}>
            <label htmlFor="">Periode</label>
            <div className={Styles.twoInput}>
              <AdminDatePicker
                placeholder="Dari"
                value={startDate}
                handleChange={setStartDate}
              />
              <AdminDatePicker
                placeholder="Hingga"
                value={endDate}
                handleChange={setEndDate}
              />
            </div>
          </div>
        </div>
        <div className={Styles.body}>
          <div className={Styles.box}>
            <>
              <div className={Styles.headerTitle}>
                <h1>Daftar Produk Promo</h1>
              </div>

              <TableServices
                itemsPerPage={10}
                data={[]}
                placeholderText=<EmptyHandlerTable
                  isAddButton
                  onClick={() => setIsAddPromo(!addPromo)}
                />
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                isLoading={false}
                type="promo"
                sticky="Motorcycle Name"
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                link="/admin/motor/detail/"
              />
              <div className={Styles.pagination}>
                <div className={Styles.paginationLeft}></div>
                <div className={Styles.paginationRight}></div>
              </div>
            </>
          </div>
        </div>
        {addPromo && (
          <Modal
            handleDone={() => setIsAddPromo(false)}
            style={{ width: "90%", maxWidth: "1280px", overflow: "hidden" }}
          >
            <div
              className={Styles.wrapperModalAddPromo}
              style={{ height: "90%" }}
            >
              <div className={Styles.header}>
                <h2>Daftar Produk</h2>
                <Icon
                  icon={"x-mark"}
                  className={Styles.icon}
                  onClick={() => setIsAddPromo(false)}
                />
              </div>

              <NavbarAdmin
                icon={"promoadmin"}
                size={32}
                color={"#FFFFFF"}
                selectedButton={selectedButton}
                isRoute={true}
                labelRouteFirst={`Motor (${selectedMotors?.length} Dipilih)`}
                labelRouteSecond="Onderdil (0 Dipilih)"
                placeholder={"Cari Motor"}
                isHiddenProfile
                isHiddenIcon
                onChange={(e) => setSearchQuery(e?.target?.value)}
                value={searchQuery}
              />
              <div className={Styles.checkboxAndfilter}>
                <div>
                  <Icon
                    icon={selectedMotorsAll ? "checked" : "checkbox"}
                    onClick={() => handleSelectAll()}
                    size={"24"}
                    className={Styles.checkboxIcon}
                    color={"#323232"}
                  />
                  <span>Pilih semua motor</span>
                </div>
                <div>
                  <Icon icon={"filter_list"} className={Styles.icon} />
                  <span>Filter</span>
                </div>
              </div>
              <section className={Styles.wrapperCardList}>
                {data?.response?.map((el, idx) => (
                  <div
                    className={Styles.card}
                    key={`list-product-${idx}`}
                    onClick={() => handleChooseMotor(el?.variantID)}
                  >
                    <div className={Styles.header}>
                      <div>
                        <span>{el?.name} </span>
                        <Icon
                          icon={
                            selectedMotors.includes(el?.variantID)
                              ? "checked"
                              : "checkbox"
                          }
                          size={"24"}
                          className={Styles.checkboxIcon}
                          color={"#23517D"}
                        />
                      </div>
                      <span className={Styles.type}>{el?.type}</span>
                    </div>
                    <div className={Styles.image}>
                      <img src={fileBaseUrl + el?.image} alt="list-product" />
                    </div>
                    <span className={Styles.price}>
                      Rp{parseInt(el?.price).toLocaleString("id")}
                    </span>
                  </div>
                ))}
              </section>
              <div className={Styles.buttonListProduct}>
                <div
                  className={Styles.btn}
                  onClick={() => setIsAddPromo(false)}
                >
                  <span>Batal</span>
                </div>
                <div
                  className={Styles.btn}
                  onClick={isNull ? () => {} : () => handlePostPromo()}
                >
                  <span>Simpan</span>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </section>
    </div>
  );
}
