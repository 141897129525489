import React from "react";
import Styles from "./style.module.scss";
import errorImgIcon from "@Assets/error.png";
import RefreshIcon from "@Assets/svg/RefreshIcon";

export default function EmptyHandlerGetData() {
  return (
    <div className={Styles.wrapperErrorGetData}>
      <div className={Styles.containError}>
        <img src={errorImgIcon} alt="error-icon-info" />
        <h1>Oppss...!</h1>
        <p>Terjadi kesahalahan, data tidak dapat di muat</p>
      </div>
      <form action="">
        <button type="submit" className={Styles.btnModalError}>
          <strong>Refresh</strong>
          <span>
            <RefreshIcon width="20" height="20" color="white" />
          </span>
        </button>
      </form>
    </div>
  );
}
