import React, { useState, useRef, useEffect, useCallback } from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";

const Dropdown = ({
  placeholder,
  disabled,
  options,
  value,
  setValue,
  valueTimeRight,
  setValueTimeRight,
  autoClose,
  fixedHeight,
  selectedStoreCode,
  setSelectedStoreCode,
  selectedTimesCode,
  setSelectedTimesCode,
  selectedStoreId,
  setSelectedStoreId,
  selectedTimesId,
  setSelectedTimesId,
  type,
  optionsTimeRight,
  ...props
}) => {
  const ref = useRef(null);
  const [isActive, setIsActive] = useState(false);

  const activateDropdown = () => {
    if (!disabled) {
      setIsActive(!isActive);
    }
  };

  const valueRight = valueTimeRight === null ? "00" : valueTimeRight;

  const closeOpenMenus = useCallback(
    (e) => {
      if (ref.current && isActive && !ref.current.contains(e.target)) {
        setIsActive(false);
      }
    },
    [ref, isActive]
  );

  const selectedItem = (item) => {
    setValue(item.name);
    setSelectedStoreCode(item.code);
    setSelectedStoreId(item._id);
  };

  const selectedItemTimes = (item) => {
    setValueTimeRight(item.name);
    setSelectedTimesCode(item.code);
    setSelectedTimesId(item._id);
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeOpenMenus);
  }, [ref, closeOpenMenus]);

  useEffect(() => {
    if (disabled) {
      setValue(null);
    }
  }, [disabled, setValue]);

  if (type === "motorcycleDropdown") {
    return (
      <div ref={ref} style={{ position: "relative" }}>
        <div
          className={`${Styles.containerMotorcycleDropdown} ${
            isActive ? Styles.activeContainer : null
          }
        ${disabled ? Styles.containerColorDisabled : null}
        `}
          onClick={activateDropdown}
          style={{ height: fixedHeight || "auto" }}
        >
          <div
            className={value ? Styles.valueText : Styles.placeholderText}
            style={{ fontSize: "14px" }}
          >
            {disabled ? "Select" : value ? value : placeholder} {value}
          </div>
          {isActive ? (
            <Icon icon="chevron-up" size="24px" />
          ) : (
            <Icon icon="chevron-down" size="24px" />
          )}
        </div>
        {isActive && !disabled ? (
          <div className={Styles.options}>
            {options.map((option, id) => {
              return (
                <div className={Styles.optionWrapper} key={id}>
                  <div
                    className={Styles.option}
                    onClick={() => {
                      if (autoClose) {
                        selectedItem(option);
                        setIsActive(false);
                      } else {
                        selectedItem(option);
                      }
                    }}
                  >
                    {option?.name}
                  </div>
                  {id === options.length - 1 ? null : (
                    <div className={Styles.borderBottom}></div>
                  )}
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  }

  // console.log(`${value}:${valueRight}`);

  if (type === "timeDropdown") {
    return (
      <div ref={ref} style={{ position: "relative" }}>
        <div
          className={`${Styles.container} ${
            isActive ? Styles.activeContainer : null
          }
        ${disabled ? Styles.containerColorDisabled : null}
        `}
          onClick={activateDropdown}
          style={{ height: fixedHeight || "auto" }}
        >
          <div className={value ? Styles.valueText : Styles.placeholderText}>
            {disabled
              ? "Select"
              : value || valueTimeRight
              ? value + ":" + valueRight
              : placeholder}
          </div>
          {isActive ? (
            <Icon icon="clock-o" size="24px" />
          ) : (
            <Icon icon="clock-o" size="24px" />
          )}
        </div>
        {isActive && !disabled ? (
          <div className={Styles.dropdownOptions}>
            <div className={Styles.optionsTimes}>
              {options.map((option, id) => {
                return (
                  <div className={Styles.optionWrapper} key={id}>
                    <div
                      className={Styles.option}
                      onClick={() => {
                        if (autoClose) {
                          selectedItem(option);
                          setIsActive(false);
                        } else {
                          selectedItem(option);
                        }
                      }}
                    >
                      {option?.name}
                    </div>
                    {id === options.length - 1 ? null : (
                      <div className={Styles.borderBottom}></div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className={Styles.optionsTimes}>
              {optionsTimeRight.map((option, id) => {
                return (
                  <div className={Styles.optionWrapper} key={id}>
                    <div
                      className={Styles.option}
                      onClick={() => {
                        if (autoClose) {
                          selectedItemTimes(option);
                          setIsActive(false);
                        } else {
                          selectedItemTimes(option);
                        }
                      }}
                    >
                      {option?.name}
                    </div>
                    {id === options.length - 1 ? null : (
                      <div className={Styles.borderBottom}></div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
  if (type === "dateDropdown") {
    return (
      <div ref={ref} style={{ position: "relative" }}>
        <div
          className={`${Styles.container} ${
            isActive ? Styles.activeContainer : null
          }
        ${disabled ? Styles.containerColorDisabled : null}
        `}
          onClick={activateDropdown}
          style={{ height: fixedHeight || "auto" }}
        >
          <div className={value ? Styles.valueText : Styles.placeholderText}>
            {disabled
              ? "Select"
              : value || valueTimeRight
              ? value + ":" + valueRight
              : placeholder}
          </div>
          {isActive ? (
            <Icon icon="calendar" size="24px" />
          ) : (
            <Icon icon="calendar" size="24px" />
          )}
        </div>
        {isActive && !disabled ? (
          <div className={Styles.dropdownOptions}>
            <div className={Styles.optionsTimes}>
              {options.map((option, id) => {
                return (
                  <div className={Styles.optionWrapper} key={id}>
                    <div
                      className={Styles.option}
                      onClick={() => {
                        if (autoClose) {
                          selectedItem(option);
                          setIsActive(false);
                        } else {
                          selectedItem(option);
                        }
                      }}
                    >
                      {option?.name}
                    </div>
                    {id === options.length - 1 ? null : (
                      <div className={Styles.borderBottom}></div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className={Styles.optionsTimes}>
              {optionsTimeRight.map((option, id) => {
                return (
                  <div className={Styles.optionWrapper} key={id}>
                    <div
                      className={Styles.option}
                      onClick={() => {
                        if (autoClose) {
                          selectedItemTimes(option);
                          setIsActive(false);
                        } else {
                          selectedItemTimes(option);
                        }
                      }}
                    >
                      {option?.name}
                    </div>
                    {id === options.length - 1 ? null : (
                      <div className={Styles.borderBottom}></div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  if (type === "timeDropdown") {
    return (
      <div ref={ref} style={{ position: "relative" }}>
        <div
          className={`${Styles.container} ${
            isActive ? Styles.activeContainer : null
          }
        ${disabled ? Styles.containerColorDisabled : null}
        `}
          onClick={activateDropdown}
          style={{ height: fixedHeight || "auto" }}
        >
          <div className={value ? Styles.valueText : Styles.placeholderText}>
            {disabled
              ? "Select"
              : value || valueTimeRight
              ? value + ":" + valueRight
              : placeholder}
          </div>
          {isActive ? (
            <Icon icon="clock-o" size="24px" />
          ) : (
            <Icon icon="clock-o" size="24px" />
          )}
        </div>
        {isActive && !disabled ? (
          <div className={Styles.dropdownOptions}>
            <div className={Styles.optionsTimes}>
              {options.map((option, id) => {
                return (
                  <div className={Styles.optionWrapper} key={id}>
                    <div
                      className={Styles.option}
                      onClick={() => {
                        if (autoClose) {
                          selectedItem(option);
                          setIsActive(false);
                        } else {
                          selectedItem(option);
                        }
                      }}
                    >
                      {option?.name}
                    </div>
                    {id === options.length - 1 ? null : (
                      <div className={Styles.borderBottom}></div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className={Styles.optionsTimes}>
              {optionsTimeRight.map((option, id) => {
                return (
                  <div className={Styles.optionWrapper} key={id}>
                    <div
                      className={Styles.option}
                      onClick={() => {
                        if (autoClose) {
                          selectedItemTimes(option);
                          setIsActive(false);
                        } else {
                          selectedItemTimes(option);
                        }
                      }}
                    >
                      {option?.name}
                    </div>
                    {id === options.length - 1 ? null : (
                      <div className={Styles.borderBottom}></div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <div ref={ref} style={{ position: "relative" }}>
      <div
        className={`${Styles.container} ${
          isActive ? Styles.activeContainer : null
        }
        ${disabled ? Styles.containerColorDisabled : null}
        `}
        onClick={activateDropdown}
        style={{ height: fixedHeight || "auto" }}
      >
        <div className={value ? Styles.valueText : Styles.placeholderText}>
          {disabled ? "Select" : value ? value : placeholder}
        </div>
        {isActive ? (
          <Icon icon="chevron-up" size="24px" />
        ) : (
          <Icon icon="chevron-down" size="24px" />
        )}
      </div>
      {isActive && !disabled ? (
        <div className={Styles.options}>
          {options.map((option, id) => {
            return (
              <div className={Styles.optionWrapper} key={id}>
                <div
                  className={Styles.option}
                  onClick={() => {
                    if (autoClose) {
                      selectedItem(option);
                      setIsActive(false);
                    } else {
                      selectedItem(option);
                    }
                  }}
                >
                  {option?.name}
                </div>
                {id === options.length - 1 ? null : (
                  <div className={Styles.borderBottom}></div>
                )}
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default Dropdown;
