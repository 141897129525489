const { API } = require("@Config/index");

export const addMotorPromo = (addMotor) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, status } = await API.post(`/promovariant`, addMotor, {
        headers: {
          // Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NCwiZW1wbG95ZWVJZCI6IjIwMTIxMTEyNSIsInJvbGUiOiJBRE1JTiIsImlhdCI6MTcyMTk3NTM2M30.FV43g455wl9DbxeENh4Qp9O-gI9FvKkhDrKqcCJrteQ",
        },
      });
      resolve({ data, status });
    } catch (error) {
      reject(error);
    }
  });
};
