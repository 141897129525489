import { useLocation } from "react-router-dom";
import Style from "./styles.module.scss";
import Icon from "@Atom/Icon";
import AVATAR from "@Assets/avatar-default.png";
import InputForm from "@Atom/Input";
import useWindowSize from "@Hooks/useWindowSize";

export const NavbarAdmin = ({
  icon,
  size,
  color,
  selectedButton,
  onClickTable,
  onClickGrid,
  label,
  isRoute = false,
  labelRouteFirst,
  labelRouteSecond,
  onChange = () => {},
  value = "",
  placeholder,
  isHiddenProfile = false,
  isHiddenSearchBar = false,
  isHiddenIcon = false,
}) => {
  const { pathname } = useLocation();
  const { width } = useWindowSize();
  return (
    <div className={Style.body}>
      <div className={Style.left}>
        {isHiddenIcon ? (
          ""
        ) : (
          <div className={Style.iconBox}>
            <Icon icon={icon} size={size} color={color} />
          </div>
        )}
        <div className={Style.titleBox}>
          <span>{label}</span>
          {isRoute ? (
            <div className={Style.routeBox}>
              <span
                className={` ${
                  selectedButton === "Table" || selectedButton === "DetilPromo"
                    ? Style.active
                    : Style.click
                }`}
                onClick={onClickTable}
              >
                {labelRouteFirst}
              </span>
              <span
                className={` ${
                  selectedButton === "Grid" ||
                  selectedButton === "TampilanPromo"
                    ? Style.active
                    : Style.click
                }`}
                onClick={onClickGrid}
              >
                {labelRouteSecond}
              </span>
            </div>
          ) : null}
        </div>
      </div>
      <div className={Style.right}>
        {isHiddenSearchBar ? (
          ""
        ) : (
          <div
            className={Style.searchBox}
            style={width > 768 ? { width: "512px" } : { width: "100%" }}
          >
            <InputForm
              type="search"
              placeholder={placeholder}
              onChange={onChange}
              value={value}
            />
          </div>
        )}
        {isHiddenProfile ? (
          ""
        ) : (
          <div
            className={Style.profileBox}
            style={
              pathname === "/admin/motor/add" || pathname === "/admin/promo/add"
                ? {
                    justifyContent: "right",
                    width: "100%",
                    display: "flex",
                  }
                : { undefined }
            }
          >
            <img src={AVATAR} alt="avatar_profile" />
            <Icon icon={"panahbawah"} size={24} color={"#6D6D6D"} />
          </div>
        )}
      </div>
    </div>
  );
};
