import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
// import App from "./App";
// import { ThemeProvider } from "@emotion/react";
// import theme from "theme";
import { RouterProvider } from "react-router-dom";
import routeAdmin from "./navigators";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={routeAdmin} />
  </React.StrictMode>
);
