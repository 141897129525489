// import Select from "@Atom/Select";
import { useEffect } from "react";
import Styles from "./style.module.scss";
import usePagination, { DOTS } from "@Hooks/usePagination";
import Icon from "@Atom/Icon";

const Pagination = ({
  total,
  value,
  setValue,
  pages,
  setPages,
  selectedPage,
  setSelectedPage,
  pageList,
  setPageList,
  totalPage,
  listName,
  totalData = 1,
  siblingCount = 1,
  ...props
}) => {
  const paginationRange = usePagination({
    selectedPage,
    siblingCount,
    totalPage,
  });

  const onNext = () => {
    if (selectedPage < totalPage) {
      setSelectedPage(selectedPage + 1);
    }
  };

  const onPrevious = () => {
    if (selectedPage > 1) {
      setSelectedPage(selectedPage - 1);
    }
  };

  let lastPage = paginationRange[paginationRange?.length - 1];

  const prevDisabled = selectedPage === 1;
  const nextDisabled = selectedPage === lastPage || totalPage === 0;

  useEffect(() => {
    if (value) {
      setPages(`1-${value}`);
      setSelectedPage(1);
    }
  }, [value, setPages, setSelectedPage]);

  useEffect(() => {
    const list = [];
    if (value) {
      if (total / value >= 1) {
        for (let i = 1; i <= total / value; i++) {
          list.push({ number: i });
        }
      }
      if (total / value < 1) {
        list.push({ number: 1 });
      }
      if (total / value >= 1 && total % value > 0) {
        list.push({ number: Math.floor(total / value + 1) });
      }

      setPageList(list);
    }
  }, [value, total, setPageList]);

  useEffect(() => {
    if (value) {
      if (selectedPage > 1) {
        setPages(
          `${+(selectedPage - 1) * +value + 1}-${
            +(selectedPage - 1) * +value + +value <= total
              ? +(selectedPage - 1) * +value + +value
              : total
          }`
        );
      }
      if (selectedPage <= 1) {
        setPages(`1-${value}`);
      }
    }
  }, [selectedPage, value, total, setPages]);

  return (
    <div className={Styles.container}>
      {/* <div className={Styles.leftSide}>
        <div className={Styles.content}>
          Menampilkan
          <span>{` ${total}`}</span> dari
          <span>{` ${totalData} ${listName}`}</span>
        </div>
      </div> */}
      <div className={Styles.rightSide}>
        <div className={Styles.pageIndicator}>
          <div className={Styles.arrowWrapper}>
            <div
              className={`${Styles.arrow} ${
                prevDisabled ? Styles.disabled : ""
              }`}
              onClick={onPrevious}
            >
              <Icon
                icon="chevron-left"
                size={18}
                className={prevDisabled ? Styles.iconDisabled : Styles.icon}
              />
            </div>
          </div>
          {paginationRange.map((each) => {
            if (each === DOTS) {
              return <div key={each + Math.random()}>&#8230;</div>;
            }
            return (
              <li
                key={each + Math.random()}
                className={`${
                  selectedPage === each ? Styles.selectedPage : ""
                } ${Styles.page}`}
                onClick={() => setSelectedPage(each)}
              >
                {each}
              </li>
            );
          })}
          <div className={Styles.arrowWrapper}>
            <div
              className={`${Styles.arrow} ${
                nextDisabled ? Styles.disabled : ""
              }`}
              onClick={onNext}
            >
              <Icon
                icon="chevron-right"
                size={18}
                className={nextDisabled ? Styles.iconDisabled : Styles.icon}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
