import Styles from "./styles.module.scss";
import React from "react";

const Checkbox = (props) => {
  const { checked, onChange, disabled } = props;
  // let xcheckbox;
  // const onChange = (event) => {
  //   // if (event.target.checked) {
  //   //   let xcheckbox = "checkin";
  //   //   // console.log("✅ Checkbox is checked");
  //   // } else {
  //   //   // console.log("⛔️ Checkbox is NOT checked");
  //   //   let xcheckbox = "checkout";
  //   // }
  //   // // setIsSubscribed(current => !current);
  // };
  // const xxcheckbox = xcheckbox;

  // console.log(xcheckbox);
  return (
    <label className={Styles.container}>
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        // value="check"
        // name="check"
      />
      <span className={Styles.checkmark}></span>
    </label>
  );
};

export default Checkbox;
