import EmptyHandlerGetData from "@Atom/ErrorGetData";
import Spinner from "@Atom/Spinner";
import PromoPageLayout from "@Organism/Admin/PromoAddLayout";
import { getVariant } from "@Services/admin/promo/fetchVariant";
import { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";
export const loader = async (route) => {
  const params = new URLSearchParams(route?.request?.url);
  const searchQuery = params?.get("search") || "";

  return defer({
    getVariant: await getVariant(searchQuery),
  });
};
export default function AdminMotorcyclePage() {
  const { getVariant } = useLoaderData();
  return (
    <Suspense fallback={<Spinner />}>
      <Await errorElement={<EmptyHandlerGetData />} resolve={getVariant}>
        {(data) => <PromoPageLayout data={data} />}
      </Await>
    </Suspense>
  );
}
