import { NavbarAdmin } from "@Molecule/NavbarAdmin";
import Styles from "./styles.module.scss";
import TableHeader from "@Molecule/TableHeader";
import { useEffect, useState } from "react";
import Icon from "@Atom/Icon";
import SPAREPART_DETAIL from "@Assets/Frame 106.png";
import CurrencyInput from "react-currency-input-field";
import { Link } from "react-router-dom";
import axios from "axios";
import AlertShow from "@Molecule/Alert";
// import { ButtonCardAdmin } from "@Atom/ButtonProductComponent";

export default function SparepartsDetail() {
  const dataProduk = {
    id: 1,
    name: "Oli Filter",
    price: "20000000",
    priceDiskon: "19000000",
    promo: "murah",
    keterangan:
      "Filter oli asli Yamaha adalah filter oli yang optimal untukmesin Yamaha karena daya tahan dan filtrasi kinerja yangtinggi.Dalam mengembangkan filter oli, Yamaha melakukan tesuntuk memeriksa bagaimana filter sesuai dengan mesin. filteroli asli Yamaha adalah satu-satunya filter minyak yang dapatdigunakan sesuai mesin Yamaha.Pompa oli mengatur sirkulasioli dalam mesin. Minyak menjadi kotor selama operasi normalkarena bersirkulasi melalui transmisi, piston dan silinder,kepala silinder, kopling, oil cooler, dll, dan filter olimenyaring benda asing keluar dari oli mesin.Filter olimenyaring benda asing dalam oli mesin, dan menjadi kotor.filter tidak dapat memperbaiki degradasi oli mesin itusendiri. Ketika filter oli mencapai batas kemampuanfiltrasi, mesin kotor dan oli terus beredar di dalam mesin.Oil mesin menjadi kotor karena pembersihan dan dispersieffect oli mesin membuat bagian dalam mesin bersih denganmengambil partikel jelaga dan partikel dari keausan logamyang dihasilkan di dalam mesin ke dalam minyak. Engine OilMenjadi Kotor Karena Efek Anti-karat oli mesin menempel padapermukaan logam di dalam mesin dan melindungi permukaanlogam dari karat dan korosi. melindungi bagian dalam mesindari kelembaban, udara, dan asam yang terkandung dalamcampuran udara-bahan bakar. Engine Oil Menjadi Kotor Karenamesin Panas oli mesin memberikan pelumas dengan masuk kecelah antara piston suhu tinggi dan silinder. untuk alasanini, Oli kadang-kadang terbakar dan secara bertahap menjadiberwarna lebih gelap. Ketika oli mesin beredar untuk jangkawaktu yang panjang, menjadi kotor, karena partikel logam dankarbon dibakar di mesin. Jika partikel-partikel ini dikirimke mesin dengan oli mesin, dapat menyebabkan mesin untukrusak. Filter oli yang menyaring partikel-partikel inikeluar dari oli mesin.Filter oli Asli Yamaha menjagakeseimbangan daya tahan dan kinerja filtrasi oli yangtinggi.",
    stok: "80",
    photo: SPAREPART_DETAIL,
  };

  const dataJual = {
    id: 1,
    sellOut: 8,
    total: "200000",
    date: "20 Juni 2021",
    status: "Available",
  };

  const [image, setImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");

  const handleFile = (file) => {
    setImage(file);
    setPreviewUrl(URL.createObjectURL(file));
    // console.log(file);
  };
  // console.log(URL.createObjectURL(img));

  // console.log(previewUrl);

  let fileSize;
  let fileType;
  // let fileDetail;
  if (image) {
    fileType = image.type.split("/");
    fileSize = (image.size / 1048576).toFixed(2);
  }

  const handleOndrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    let imageFile = event.dataTransfer.files[0];
    handleFile(imageFile);
    setDataForm({ ...dataForm, photo: imageFile.name });
    // setDataForm({ photo: imageFile.name });
    // console.log(imageFile);
  };

  // console.log(mime.getType(dataProduk.photo));

  const handleOndragOver = (event) => {
    event.preventDefault();
  };

  const [dataForm, setDataForm] = useState({
    name: dataProduk.name,
    stok: dataProduk.stok,
    price: dataProduk.price,
    priceDiskon: dataProduk.priceDiskon,
    promo: dataProduk.promo,
    keterangan: dataProduk.keterangan,
    photo: dataProduk.photo,
  });

  const onChange = (e) => {
    // e.persist();
    setDataForm({ ...dataForm, [e.target.name]: e.target.value });
    // xsetCatatanOrder(e.target.value.length);
  };

  const removePhoto = () => {
    setPreviewUrl("");
    setDataForm({ ...dataForm, photo: "" });

    // console.log("asdf");
  };

  const [errorsText, setErrorsText] = useState();
  const [errorsRespon, setErrorsRespon] = useState();
  const [showAlert, setShowAlert] = useState(false);

  // let text = "The, rain in SPAIN stays mainly in the plain";
  // let result = dataForm.stok.length;
  // console.log(result);

  const handleSubmit = () => {
    validateValues();
    setShowAlert(true);

    let sisa;
    if (dataForm.stok.length > 3) {
      sisa = dataForm.stok.replaceAll(",", "");
    } else {
      sisa = dataForm.stok;
    }

    let harga;
    if (dataForm.price.length > 3) {
      harga = dataForm.price.replaceAll(",", "");
    } else {
      harga = dataForm.price;
    }

    let diskon;
    if (dataForm.priceDiskon.length > 3) {
      diskon = dataForm.priceDiskon.replaceAll(",", "");
    } else {
      diskon = dataForm.priceDiskon;
    }

    // const sisa = dataForm.stok.replaceAll(",", "");
    // const harga = dataForm.price.replaceAll(",", "");
    // const diskon = dataForm.priceDiskon.replaceAll(",", "");
    console.log(
      dataForm.name +
        "-" +
        sisa.replace(/[A-Z][a-z][|&;$%@"<>()+.]/, "").trim() +
        // dataForm.price  +
        "-" +
        harga.replace(/[A-Z][a-z][|&;$%@"<>()+.]/, "").trim() +
        // // dataForm.price +
        "-" +
        diskon.replace(/[A-Z][a-z][|&;$%@"<>()+.]/, "").trim() +
        // dataForm.priceDiskon +
        "-" +
        dataForm.promo +
        "-"
      // vimg
    );
  };

  const validateValues = () => {
    // let errors = {};
    let errors1;
    let errors2;
    let errors3;
    let errors4;
    let errors5;
    let respon;
    if (dataForm.photo === "") {
      errors1 = " Gambar, ";
    } else {
      errors1 = "";
    }
    if (dataForm.name === "") {
      errors2 = " Nama produk, ";
    } else {
      errors2 = "";
    }
    if (dataForm.stok === "") {
      errors3 = " Sisa stok, ";
    } else {
      errors3 = "";
    }
    if (dataForm.price === "") {
      errors4 = " Harga utama, ";
    } else {
      errors4 = "";
    }
    // return errors;

    if (errors1 !== "" || errors2 !== "" || errors3 !== "" || errors4 !== "") {
      errors5 = errors1 + errors2 + errors3 + errors4 + " masih kosong";
      respon = "error";
    } else {
      errors5 = "Proses berhasil";
      respon = "success";
    }
    setErrorsText(errors5);
    setErrorsRespon(respon);
  };
  // console.log(errorsText);
  const handleAlert = (e) => {
    setShowAlert(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 5000);
  }, [showAlert]);

  useEffect(() => {
    axios.get(dataProduk.photo, { responseType: "blob" }).then((res) => {
      const respon = {
        name: dataProduk.photo.split("/").pop(),
        lastModified: "",
        webkitRelativePath: "",
        size: res.data.size,
        type: res.data.type,
      };
      setImage(respon);
      setPreviewUrl(dataProduk.photo);
    });
  }, []);

  function handleOnBrows(event) {
    event.preventDefault();
    event.stopPropagation();
    let ximageFile = event.target.files[0];
    // console.log(ximageFile);
    handleFile(ximageFile);
    setDataForm({ ...dataForm, photo: ximageFile.name });
  }

  // console.log(dataForm);

  return (
    <div className={Styles.body}>
      <NavbarAdmin
        icon={"sparepartsadmin"}
        size={32}
        color={"#FFFFFF"}
        label={"Onderdil"}
        // isRoute={true}
      />
      <div className={Styles.box}>
        <TableHeader
          type="admin-detail"
          labelNameDetail="Edit Produk"
          pathname={"/admin/spareparts"}
          pathname2={"/admin/spareparts/detail/" + dataProduk.id}
          textButton="Detail"
        />
        <div className={Styles.detailBox}>
          <div className={Styles.leftDetail}>
            <span>Foto Produk*</span>
            <div className={Styles.imagesBox}>
              <img src={dataProduk.photo} alt="spareparts-images" />
              {previewUrl ? (
                <div className={Styles.showImagesPreview}>
                  <img
                    src={previewUrl}
                    alt="preview-url"
                    // Styles={{ width: "80px", height: "50px" }}
                  />
                  <span>
                    {image?.name}
                    <br />
                    <span className={Styles.detailFile}>
                      {fileType[0]}
                      <Icon
                        icon={"i_010-bullet"}
                        size={12}
                        style={{
                          marginBottom: "-2px",
                        }}
                      />
                      {fileSize} Mb
                      <Icon
                        icon={"i_010-bullet"}
                        size={12}
                        style={{
                          marginBottom: "-2px",
                        }}
                      />
                      {fileType[1]}
                    </span>
                  </span>
                  {/* {console.log(image)} */}
                  <div
                    className={Styles.removePhoto}
                    onClick={() => removePhoto()}
                  >
                    Replace Image
                  </div>
                </div>
              ) : (
                <>
                  <div
                    className={Styles.inputImages}
                    onDragOver={handleOndragOver}
                    onDrop={handleOndrop}
                  >
                    {" "}
                    <input
                      type="file"
                      accept="image/*"
                      className="input-field"
                      onChange={handleOnBrows}
                      id="create-course-form"
                    />
                    <Icon
                      icon={"logoImages"}
                      size={24}
                      className={Styles.iconImages}
                    />
                    <span className={Styles.h4}>
                      Taruh dan lepas <span>gambar</span>
                    </span>
                    <span className={Styles.h5}>
                      atau{" "}
                      <span
                        onClick={() =>
                          document.querySelector(".input-field").click()
                        }
                      >
                        browse files
                      </span>{" "}
                      pada komputer kamu
                    </span>{" "}
                    <p>Dimensi gambar pada ukuran: 3000 x 1500 </p>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className={Styles.rightDetail}>
            <div className={Styles.detail}>
              <span>Nama Produk*</span>
              <input
                type="text"
                name="name"
                placeholder="Oil Filter"
                onChange={onChange}
                defaultValue={dataProduk.name}
                // ref={inputFile}
              />
            </div>

            <div className={Styles.detail}>
              <span>Stok tersisa*</span>
              <CurrencyInput
                id="input-example"
                name="stok"
                placeholder="1"
                // defaultValue={0}
                decimalsLimit={0}
                // prefix="Rp. "
                onChange={onChange}
                defaultValue={dataProduk.stok}
                // ref={money}
              />
            </div>

            <div className={Styles.detail}>
              <span>Harga Utama*</span>
              <CurrencyInput
                id="input-example"
                name="price"
                placeholder="1.000.000"
                // defaultValue={0}
                decimalsLimit={0}
                prefix="Rp. "
                onChange={onChange}
                defaultValue={dataProduk.price}
                // ref={money}
              />
            </div>

            <div className={Styles.detail}>
              <span>Harga Diskon</span>
              <CurrencyInput
                id="input-example"
                name="priceDiskon"
                placeholder="1.000.000"
                // defaultValue={0}
                decimalsLimit={0}
                prefix="Rp. "
                onChange={onChange}
                defaultValue={dataProduk.priceDiskon}
                // ref={money}
              />
            </div>

            <div className={Styles.detail}>
              <span>Promo</span>
              <input
                type="text"
                name="promo"
                placeholder="2JT"
                onChange={onChange}
                defaultValue={dataProduk.promo}
                // ref={inputFile}
              />
            </div>

            <div className={Styles.detail}>
              <span>Keterangan</span>
              <input
                type="text"
                name="keterangan"
                placeholder="Filter oli asli Yamaha adalah"
                onChange={onChange}
                defaultValue={dataProduk.keterangan}
                // ref={inputFile}
              />
            </div>

            <div className={Styles.detail}>
              <span>Penjualan</span>
              <label>{dataJual.sellOut}</label>
            </div>

            <div className={Styles.detail}>
              <span>Total Penjualan</span>
              Rp. {new Intl.NumberFormat().format(dataJual.total)}
            </div>

            <div className={Styles.detail}>
              <span>Tanggal data masuk</span>
              <label>{dataJual.date}</label>
            </div>

            <div className={Styles.detail}>
              <span>Status</span>
              <div
                className={
                  dataJual.status === "Available"
                    ? Styles.statusGreen
                    : Styles.statusRed
                }
              >
                {dataJual.status}
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.buttonBox}>
          <div className={Styles.null}></div>
          <div className={Styles.button}>
            <span
              className={Styles.buttonAdd}
              onClick={() => {
                handleSubmit();
                handleAlert();
              }}
            >
              Simpan
            </span>
            <Link to="/admin/spareparts" className={Styles.buttonCancel}>
              <span>Batalkan</span>
            </Link>
            <AlertShow
              xshowAlert={showAlert}
              statusAlert={errorsRespon === "success" ? "success" : "error"}
              iconAlert={
                errorsRespon === "success" ? "checkmark" : "cancel-circle"
              }
              textAlert={errorsText}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
