import { NavbarAdmin } from "@Molecule/NavbarAdmin";
import Styles from "./styles.module.scss";
import TableHeader from "@Molecule/TableHeader";
import { useState } from "react";
import Table from "@Atom/Table";
import TableServices from "@Atom/tableServices";
import Pagination from "@Molecule/Pagination";
import { Link } from "react-router-dom";

export default function ServicesMotorcycle() {
  const itemsPerPage = 100;
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  // const [selectedPage, setSelectedPage] = useState(1);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const columns = [
    {
      name: "name",
      label: "Nama Lengkap",
    },
    {
      name: "date",
      label: "Tanggal",
    },
    {
      name: "time",
      label: "Jam",
    },
    {
      name: "email",
      label: "Email",
    },
    {
      name: "numberPhone",
      label: "Nomor HP",
    },
    {
      name: "Model",
      label: "Model Kendaraan",
    },
    {
      name: "type",
      label: "Tipe Kendaraan",
    },
    {
      name: "PlatNumber",
      label: "Plat Nomor",
    },
    {
      name: "services",
      label: "Layanan",
    },
    {
      name: "dealer",
      label: "Dealer",
    },
  ];

  let data = [
    {
      id: 1,
      name: "Fuzi Kaze1",
      date: "20 Juni 2021",
      time: "08:00",
      email: "fuzikaze@gmail.com",
      numberPhone: "085627464826",
      Model: "Motorcycle",
      type: "Aeox100",
      PlatNumber: "B 13 LO",
      services: "services",
      dealer: "dealer 10",
    },
    {
      id: 2,
      name: "Fuzi Kaze2",
      date: "20 Juni 2021",
      time: "08:00",
      email: "fuzikaze@gmail.com",
      numberPhone: "085627464826",
      Model: "Motorcycle",
      type: "Aeox100",
      PlatNumber: "B 13 LO",
      services: "services",
      dealer: "dealer 10",
    },
    {
      id: 3,
      name: "Fuzi Kaze3",
      date: "20 Juni 2021",
      time: "08:00",
      email: "fuzikaze@gmail.com",
      numberPhone: "085627464826",
      Model: "Motorcycle",
      type: "Aeox100",
      PlatNumber: "B 13 LO",
      services: "services",
      dealer: "dealer 10",
    },
    {
      id: 4,
      name: "Fuzi Kaze4",
      date: "20 Juni 2021",
      time: "08:00",
      email: "fuzikaze@gmail.com",
      numberPhone: "085627464826",
      Model: "Motorcycle",
      type: "Aeox100",
      PlatNumber: "B 13 LO",
      services: "services",
      dealer: "dealer 10",
    },
    {
      id: 5,
      name: "Fuzi Kaze5",
      date: "20 Juni 2021",
      time: "08:00",
      email: "fuzikaze@gmail.com",
      numberPhone: "085627464826",
      Model: "Motorcycle",
      type: "Aeox100",
      PlatNumber: "B 13 LO",
      services: "services",
      dealer: "dealer 10",
    },
    {
      id: 6,
      name: "Fuzi Kaze6",
      date: "20 Juni 2021",
      time: "08:00",
      email: "fuzikaze@gmail.com",
      numberPhone: "085627464826",
      Model: "Motorcycle",
      type: "Aeox100",
      PlatNumber: "B 13 LO",
      services: "services",
      dealer: "dealer 10",
    },
    {
      id: 7,
      name: "Fuzi Kaze7",
      date: "20 Juni 2021",
      time: "08:00",
      email: "fuzikaze@gmail.com",
      numberPhone: "085627464826",
      Model: "Motorcycle",
      type: "Aeox100",
      PlatNumber: "B 13 LO",
      services: "services",
      dealer: "dealer 10",
    },
    {
      id: 8,
      name: "Fuzi Kaze8",
      date: "20 Juni 2021",
      time: "08:00",
      email: "fuzikaze@gmail.com",
      numberPhone: "085627464826",
      Model: "Motorcycle",
      type: "Aeox100",
      PlatNumber: "B 13 LO",
      services: "services",
      dealer: "dealer 10",
    },
    {
      id: 9,
      name: "Fuzi Kaze9",
      date: "20 Juni 2021",
      time: "08:00",
      email: "fuzikaze@gmail.com",
      numberPhone: "085627464826",
      Model: "Motorcycle",
      type: "Aeox100",
      PlatNumber: "B 13 LO",
      services: "services",
      dealer: "dealer 10",
    },
    {
      id: 10,
      name: "Fuzi Kaze10",
      date: "20 Juni 2021",
      time: "08:00",
      email: "fuzikaze@gmail.com",
      numberPhone: "085627464826",
      Model: "Motorcycle",
      type: "Aeox100",
      PlatNumber: "B 13 LO",
      services: "services",
      dealer: "dealer 10",
    },
    {
      id: 11,
      name: "Fuzi Kaze11",
      date: "20 Juni 2021",
      time: "08:00",
      email: "fuzikaze@gmail.com",
      numberPhone: "085627464826",
      Model: "Motorcycle",
      type: "Aeox100",
      PlatNumber: "B 13 LO",
      services: "services",
      dealer: "dealer 10",
    },
    {
      id: 12,
      name: "Fuzi Kaze12",
      date: "20 Juni 2021",
      time: "08:00",
      email: "fuzikaze@gmail.com",
      numberPhone: "085627464826",
      Model: "Motorcycle",
      type: "Aeox100",
      PlatNumber: "B 13 LO",
      services: "services",
      dealer: "dealer 10",
    },
  ];

  const [valuePage, setValuePage] = useState(10);
  const [pages, setPages] = useState(0);
  const [pageList, setPageList] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);

  const totalData = data.length;
  const recordsPrePage = 10;
  const totalPage = Math.ceil(totalData / recordsPrePage);
  const xpages = "" + pages + "";
  const replacePage = xpages.replace("-", " ");
  const splitPage = replacePage.split(" ");
  const firstList = splitPage[0] - 1;
  const lastList = firstList + recordsPrePage;
  const dataSemua = data.slice(firstList, lastList);

  const [xfilter, xsetFilter] = useState(1);

  const filterChange = (e) => {
    // console.log(e, "parent");
    xsetFilter(e);
  };

  let sortrating;
  if (xfilter === "Paling lama") {
    sortrating = [...dataSemua].sort((a, b) => a.id - b.id);
  } else if (xfilter === "Paling baru") {
    sortrating = [...dataSemua].sort((a, b) => b.id - a.id);
  } else {
    sortrating = [...dataSemua].sort((a, b) => a.id - b.id);
  }

  return (
    <div className={Styles.body}>
      <NavbarAdmin
        icon={"serviceadmin"}
        size={32}
        color={"#FFFFFF"}
        label={"Servis"}
        // isRoute={true}
      />

      <div className={Styles.box}>
        <TableHeader
          type="motorcycle"
          labelAdd="Tambah Motor Baru"
          labelName="Servis"
          filterChange={filterChange}
        />
        <TableServices
          itemsPerPage={recordsPrePage}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          data={sortrating}
          placeholderText="Belum ada data yang di masukkan"
          columns={columns}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          isLoading={isLoadingData}
          // type={"admin-table"}
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
        />
        <div className={Styles.pagination}>
          <div className={Styles.paginationLeft}>
            Memperlihatkan {parseInt(firstList + 1)} - {parseInt(lastList)} dari{" "}
            {totalData} produk
          </div>
          <div className={Styles.paginationRight}>
            <Pagination
              total={10}
              totalPage={totalPage}
              totalData={totalData}
              value={valuePage}
              setValue={setValuePage}
              pages={pages}
              setPages={setPages}
              pageList={pageList}
              setPageList={setPageList}
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
            />
          </div>
        </div>
      </div>
    </div>
    // <div className={Styles.body}>
    //   <NavbarAdmin
    //     icon={"serviceadmin"}
    //     size={32}
    //     color={"#FFFFFF"}
    //     label={"Servis"}
    //     // isRoute={true}
    //   />

    //   <div className={Styles.box}>
    //     <TableHeader
    //       type="motorcycle"
    //       labelAdd="Tambah Motor Baru"
    //       labelName="Servis"
    //     />
    //     <Table
    //       itemsPerPage={itemsPerPage}
    //       selectedPage={selectedPage}
    //       setSelectedPage={setSelectedPage}
    //       data={data}
    //       placeholderText="Belum ada data yang di masukkan"
    //       columns={columns}
    //       searchTerm={searchTerm}
    //       setSearchTerm={setSearchTerm}
    //       isLoading={isLoadingData}
    //       type={"admin-service"}
    //       selectedUsers={selectedUsers}
    //       setSelectedUsers={setSelectedUsers}
    //     />
    //   </div>
    // </div>
  );
}
