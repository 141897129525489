import Style from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import { fileBaseUrl } from "@Config/index";

const AdminProductCard = ({ data }) => {
  const navigate = useNavigate();
  return (
    <div className={Style.wrapperCard}>
      {data?.map((el, idx) => (
        <div className={Style.card} key={`list-card-motor-${idx}`}>
          <div className={Style.wrapImg}>
            <img src={fileBaseUrl + el?.thumbnail} alt="motor" />
          </div>
          <div className={Style.wrapDescription}>
            <div className={Style.between}>
              <h4>{el?.name} </h4>
              <div className={Style.variant}>
                <div>
                  <span className={Style.variantTag}>Variants</span>
                  <span className={Style.totalVariant}>{el?.variants}</span>
                </div>
                <div>
                  <span className={Style.variantTag}>Colors</span>
                  <span className={Style.totalVariant}>{el?.colors}</span>
                </div>
              </div>
              <div className={Style.wrapPrice}>
                <span>
                  Rp{parseInt(el?.fixedPrice[0] || 0)?.toLocaleString("id-ID")}
                </span>
                {el?.fixedPrice[1] ? (
                  <span>
                    - Rp
                    {parseInt(el?.fixedPrice[1] || 0)?.toLocaleString(
                      "id-ID"
                    )}{" "}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className={Style.buttonCard}>
              <div onClick={() => navigate(`/admin/motor/edit/${el?.id}`)}>
                <span>Edit</span>
              </div>
              <div onClick={() => navigate(`/products/detail/${el?.id}`)}>
                <span>Detail</span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AdminProductCard;
