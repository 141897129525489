import { NavbarAdmin } from "@Molecule/NavbarAdmin";
import Styles from "./styles.module.scss";
import { useEffect, useMemo, useState } from "react";
import TableHeader from "@Molecule/TableHeader";
import TableServices from "@Atom/tableServices";
// import Pagination from "@Molecule/Pagination";
import moment from "moment";
import AdminProductCard from "@Molecule/AdminProductCard";
import { useDebounce } from "@Hooks/useDebounce";
import { useLocation, useNavigate } from "react-router-dom";
import useQuery from "@Hooks/useQuery";
import ListingPagination from "@Atom/ListingPagination";
import EmptyHandlerSearch from "@Atom/EmptyHandlerSearch";
import Dropdown from "@Atom/DropDown";
import Icon from "@Atom/Icon";

export default function Motorcycle({ datas }) {
  const [limit, setlimit] = useState(10);
  // const [pages, setPages] = useState(0);
  // const [pageList, setPageList] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const [loadingSearch, setLoadingSearch] = useState(false);

  useEffect(() => {
    if (debouncedSearchQuery?.length > 1) {
      setLoadingSearch(true);
    }
    setTimeout(() => {
      setLoadingSearch(false);
    }, 500);
  }, [debouncedSearchQuery]);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const query = useQuery();
  const pageQuery = query?.get("page");
  const limitQuery = query?.get("limit");
  useEffect(() => {
    setSelectedPage(+pageQuery || 1);
    setlimit(+limitQuery || 10);
  }, [limitQuery, pageQuery]);
  useEffect(() => {
    if (searchQuery) {
      const prevPage = Math.min(datas?.totalPages, selectedPage);
      navigate(
        `${pathname}?limit=${limit}&page=${prevPage}&search=${debouncedSearchQuery}`,
        {
          replace: true,
        }
      );
    } else {
      navigate(`${pathname}?limit=${limit}&page=${selectedPage}`, {
        replace: true,
      });
    }
  }, [
    datas?.totalPages,
    debouncedSearchQuery,
    limit,
    navigate,
    pathname,
    searchQuery,
    selectedPage,
  ]);
  const sessionSelectedButtonId = sessionStorage.getItem("idButtonMotorcycle");
  const [selectedButton, setSelectedButton] = useState("Table");

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const dataTable = useMemo(() => {
    return {
      data: datas?.data?.map((item) => {
        return {
          id: item?.id || "0",
          name: item?.name || "-",
          variants: item?.variants || "0",
          colors: item?.colors || "0",

          fixedPrice: `Rp ${parseInt(item?.fixedPrice[0] || 0)?.toLocaleString(
            "id"
          )} ${
            item?.fixedPrice?.length > 1
              ? `- Rp ${parseInt(item?.fixedPrice[1] || 0)?.toLocaleString(
                  "id-ID"
                )}`
              : ""
          }`,

          date: moment(item?.dateInput).format("D MMM YYYY") || "-",
          action: (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => navigate("edit/" + item?.id)}
            >
              <Icon icon={"Edit"} color={"#357ABB"} size={"24"} />
            </div>
          ),
        };
      }),

      columns: [
        {
          name: "name",
          label: "Motorcycle Name",
        },
        {
          name: "variants",
          label: "Variants",
        },
        {
          name: "colors",
          label: "Colors",
        },
        {
          name: "fixedPrice",
          label: "Fixed Price",
        },
        {
          name: "date",
          label: "Date Input",
        },
        {
          name: "action",
          label: "Action",
        },
      ],
    };
  }, [datas?.data, navigate]);

  const setActiveButton = (idButtonMotorcycle) => {
    setSelectedButton(idButtonMotorcycle);
    sessionStorage.setItem("idButtonMotorcycle", idButtonMotorcycle);
  };

  useEffect(() => {
    if (sessionSelectedButtonId) {
      setSelectedButton(sessionSelectedButtonId);
    } else {
      setSelectedButton("Table");
    }
  }, [sessionSelectedButtonId]);
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedModelCode, setSelectedModelCode] = useState(null);
  const [selectedModelId, setSelectedModelId] = useState(null);
  const [xfilter, xsetFilter] = useState("Paling lama");
  let option = [
    {
      id: 1,
      name: "Paling baru",
      // code: "DC01",
    },
    {
      id: 2,
      name: "Paling lama",
      // code: "DC02",
    },
  ];
  let sortrating;
  if (xfilter === "Paling lama") {
    sortrating = [...dataTable?.data].sort((a, b) => a.id - b.id);
  } else if (xfilter === "Paling baru") {
    sortrating = [...dataTable?.data].sort((a, b) => b.id - a.id);
  } else {
    sortrating = [...dataTable?.data].sort((a, b) => a.id - b.id);
  }
  const filterChange = (e) => {
    xsetFilter(e);
  };

  const [selectedModelGrid, setSelectedModelGrid] = useState("");
  const [selectedModelCodeGrid, setSelectedModelCodeGrid] = useState(null);
  const [selectedModelIdGrid, setSelectedModelIdGrid] = useState(null);
  const [xfilterGrid, xsetFilterGrid] = useState("Paling lama");

  let optionGrid = [
    {
      id: 1,
      name: "Paling baru",
      // code: "DC01",
    },
    {
      id: 2,
      name: "Paling lama",
      // code: "DC02",
    },
  ];
  let sortratingGrid;
  if (xfilterGrid === "Paling lama") {
    sortratingGrid = [...datas?.data].sort((a, b) => a.id - b.id);
  } else if (xfilterGrid === "Paling baru") {
    sortratingGrid = [...datas?.data].sort((a, b) => b.id - a.id);
  } else {
    sortratingGrid = [...datas?.data].sort((a, b) => a.id - b.id);
  }
  const filterChangeGrid = (e) => {
    xsetFilterGrid(e);
  };

  return (
    <div className={Styles.body} style={{ maxWidth: "1116px" }}>
      <NavbarAdmin
        icon={"motoradmin"}
        size={32}
        color={"#FFFFFF"}
        selectedButton={selectedButton}
        onClickTable={() => setActiveButton("Table")}
        onClickGrid={() => setActiveButton("Grid")}
        label={"Motor"}
        isRoute={true}
        labelRouteFirst="Table"
        labelRouteSecond="Grid"
        onChange={(e) => setSearchQuery(e?.target?.value)}
        value={searchQuery}
        placeholder={"Cari Motor"}
      />

      <div className={Styles.box}>
        {selectedButton === "Table" && (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingRight: "16px",
              }}
            >
              <TableHeader
                type="motorcycle"
                labelAdd="Tambah Motor Baru"
                labelName="Daftar Motor"
                isAdd={true}
                pathname={"/admin/motor/add"}
              />

              <Dropdown
                options={option}
                // name="filter"
                value={selectedModel}
                // setValue={setSelectedModel}
                setValue={(e) => {
                  setSelectedModel(e);
                  filterChange(e);
                }}
                selectedStoreCode={selectedModelCode}
                setSelectedStoreCode={setSelectedModelCode}
                selectedStoreId={selectedModelId}
                setSelectedStoreId={setSelectedModelId}
                placeholder={"Urutkan dari :"}
                autoClose
                name="filterTable"
                type={"motorcycleDropdown"}
                // onChange={handleChange}
              />
            </div>

            <TableServices
              itemsPerPage={10}
              data={sortrating || []}
              placeholderText={<EmptyHandlerSearch />}
              columns={dataTable.columns}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              isLoading={loadingSearch}
              type="sticky"
              sticky="Motorcycle Name"
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              link="/admin/motor/detail/"
            />

            <div className={Styles.pagination}>
              <div className={Styles.paginationLeft}>
                Memperlihatkan {datas?.data?.length || 0} dari{" "}
                {datas?.totalData} produk
              </div>
              <div className={Styles.paginationRight}>
                {/* <Pagination
                  total={10}
                  totalPage={datas?.totalPages || 0}
                  totalData={datas?.totalDatas || 0}
                  value={limit}
                  setValue={setlimit}
                  pages={pages}
                  setPages={setPages}
                  pageList={pageList}
                  setPageList={setPageList}
                  selectedPage={selectedPage}
                  setSelectedPage={setSelectedPage}
                /> */}
                <ListingPagination
                  page={selectedPage}
                  setPage={setSelectedPage}
                  maxPage={datas?.totalPages || 0}
                />
              </div>
            </div>
          </>
        )}
        {selectedButton === "Grid" && (
          <div
            style={{
              minWidth: "460px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingRight: "16px",
              }}
            >
              <TableHeader
                type="motorcycle"
                labelAdd="Tambah Motor Baru"
                labelName="Daftar Motor"
                isAdd={true}
                pathname={"/admin/motor/add"}
              />

              <Dropdown
                options={optionGrid}
                // name="filter"
                value={selectedModelGrid}
                // setValue={setSelectedModel}
                setValue={(e) => {
                  setSelectedModelGrid(e);
                  filterChangeGrid(e);
                }}
                selectedStoreCode={selectedModelCodeGrid}
                setSelectedStoreCode={setSelectedModelCodeGrid}
                selectedStoreId={selectedModelIdGrid}
                setSelectedStoreId={setSelectedModelIdGrid}
                placeholder={"Urutkan dari :"}
                autoClose
                name="filterTable"
                type={"motorcycleDropdown"}
                // onChange={handleChange}
              />
            </div>
            <AdminProductCard
              data={sortratingGrid}
              link="/admin/motor/detail/"
            />
            {sortratingGrid === 0 && <EmptyHandlerSearch />}
            <div className={Styles.pagination}>
              <div className={Styles.paginationLeft}>
                Memperlihatkan {datas?.data?.length || 0} dari{" "}
                {datas?.totalData || 0} produk
              </div>
              <div className={Styles.paginationRight}>
                {/* <Pagination
                  total={10}
                  totalPage={datas?.totalPages || 0}
                  totalData={datas?.totalDatas || 0}
                  value={limit}
                  setValue={setlimit}
                  pages={pages}
                  setPages={setPages}
                  pageList={pageList}
                  setPageList={setPageList}
                  selectedPage={selectedPage}
                  setSelectedPage={setSelectedPage}
                /> */}
                <ListingPagination
                  page={selectedPage}
                  setPage={setSelectedPage}
                  maxPage={datas?.totalPages || ""}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
