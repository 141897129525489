import { NavbarAdmin } from "@Molecule/NavbarAdmin";
import Styles from "./styles.module.scss";
import { useEffect, useMemo, useState } from "react";
import TableHeader from "@Molecule/TableHeader";
// import Pagination from "@Molecule/Pagination";
import { IsAvailable } from "@Atom/Status";
import moment from "moment";
import TableServices from "@Atom/tableServices";
import { useLocation, useNavigate } from "react-router-dom";
import { useDebounce } from "@Hooks/useDebounce";
import useQuery from "@Hooks/useQuery";
import EmptyHandlerSearch from "@Atom/EmptyHandlerSearch";
import ListingPagination from "@Atom/ListingPagination";
export default function Promo({ data }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const navigate = useNavigate();
  const dataTable = useMemo(() => {
    return {
      data: data?.promos?.map((item) => {
        return {
          id: item?.id || "0",
          name:
            (
              <span
                navigate={"true"}
                onClick={() => navigate(`detail/${item?.id}`)}
              >
                {item?.name}
              </span>
            ) || "-",
          discount: item?.discount.includes("%")
            ? item?.discount
            : `Rp ${parseInt(item?.discount).toLocaleString("id")}` || "-",
          periode:
            moment(item?.startDate).format("DD MMMM YYYY") +
              " - " +
              moment(item?.endDate).format("DD MMMM YYYY") || "-",
          status:
            item?.status?.toLowerCase() === "available".toLowerCase() ? (
              <IsAvailable isAvailable={true} trueText="Aktif" />
            ) : (
              <IsAvailable isAvailable={false} falseText="Tidak Aktif" /> || "-"
            ),
        };
      }),
      columns: [
        {
          name: "name",
          label: "Nama Promo",
        },
        {
          name: "discount",
          label: "Jumlah Potongan",
        },
        {
          name: "periode",
          label: "Periode ",
        },
        {
          name: "status",
          label: "Status",
        },
      ],
    };
  }, [data?.promos, navigate]);
  const [limit, setlimit] = useState(10);
  // const [pages, setPages] = useState(0);
  // const [pageList, setPageList] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const [loadingSearch, setLoadingSearch] = useState(false);

  useEffect(() => {
    if (debouncedSearchQuery?.length > 1) {
      setLoadingSearch(true);
    }
    setTimeout(() => {
      setLoadingSearch(false);
    }, 500);
  }, [debouncedSearchQuery]);
  const { pathname } = useLocation();
  const query = useQuery();
  const pageQuery = query?.get("page");
  const limitQuery = query?.get("limit");
  useEffect(() => {
    setSelectedPage(+pageQuery || 1);
    setlimit(+limitQuery || 10);
  }, [limitQuery, pageQuery]);
  useEffect(() => {
    if (searchQuery) {
      const prevPage = Math.min(data?.totalPages, selectedPage);
      navigate(
        `${pathname}?limit=${limit}&page=${prevPage}&search=${debouncedSearchQuery}`,
        {
          replace: true,
        }
      );
    } else {
      navigate(`${pathname}?limit=${limit}&page=${selectedPage}`, {
        replace: true,
      });
    }
  }, [
    data?.totalPages,
    debouncedSearchQuery,
    limit,
    navigate,
    pathname,
    searchQuery,
    selectedPage,
  ]);
  return (
    <div className={Styles.body} style={{ maxWidth: "1116px" }}>
      <NavbarAdmin
        icon={"promo"}
        size={32}
        color={"#FFFFFF"}
        label={"Promo"}
        onChange={(e) => setSearchQuery(e.target.value)}
        value={searchQuery}
        placeholder={"Cari Promo"}
      />

      <div className={Styles.box}>
        <TableHeader
          type="motorcycle"
          labelAdd="Tambah Promo Baru"
          labelName="Daftar Promo"
          isAdd={true}
          pathname={"/admin/promo/add"}
        />
        <TableServices
          itemsPerPage={10}
          data={dataTable?.data || []}
          placeholderText={<EmptyHandlerSearch />}
          columns={dataTable.columns}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          isLoading={loadingSearch}
          type="promo"
          sticky="Motorcycle Name"
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
        />

        <div className={Styles.pagination}>
          <div className={Styles.paginationLeft}>
            Memperlihatkan {data?.promos?.length || 0} dari {data?.totalData}{" "}
            produk
          </div>
          <div className={Styles.paginationRight}>
            {/* <Pagination
              total={10}
              totalPage={data?.totalPages || 0}
              totalData={data?.totalDatas || 0}
              value={limit}
              setValue={setlimit}
              pages={pages}
              setPages={setPages}
              pageList={pageList}
              setPageList={setPageList}
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
            /> */}
            <ListingPagination
              page={selectedPage}
              setPage={setSelectedPage}
              maxPage={data?.totalPages || 0}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
