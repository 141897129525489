import Styles from "./styles.module.scss";
import Icon from "../../Atom/Icon";
import { Link, useLocation } from "react-router-dom";
import LOGO from "@Assets/PermataMotorLogo.png";
import LOGO_TEXT_BLACK from "@Assets/admin-logo-title.png";
import LOGO_TEXT_BLACK_RESPONSIVE from "@Assets/PermataMotorTextBlack.png";
import { useState } from "react";

export default function SidebarCard() {
  const { pathname } = useLocation();
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openDropdownSide, setOpenDropdownSide] = useState(false);

  return (
    <div>
      <div className={Styles.bodySidebar}>
        <div
          className={`${Styles.sidebarContainer} ${
            openDropdownSide === true && Styles.sidebarResp
          }`}
        >
          <div className={Styles.imageBox}>
            <img src={LOGO} alt="LOGO" className={Styles.imagesLogo} />
            <img
              src={LOGO_TEXT_BLACK}
              alt="logo"
              className={Styles.imagesTitleLogo}
            />
          </div>
          <div className={Styles.routesBox}>
            <div className={Styles.routes} style={{ cursor: "not-allowed" }}>
              <Icon icon={"dashboardadmin"} size={20} color={"#6D6D6D"} />
              <span className={Styles.text}>Halaman Utama</span>
            </div>
            <Link
              to={"/admin/motor"}
              className={`${Styles.routes} ${
                (pathname === `/admin/motor` && Styles.routesActive) ||
                (pathname === `/admin/motor/add` && Styles.routesActive)
              }`}
            >
              <Icon icon={"motoradmin"} size={20} color={"#6D6D6D"} />
              <span
                className={`${Styles.text} ${
                  (pathname === `/admin/motor` && Styles.textActive) ||
                  (pathname === `/admin/motor/add` && Styles.textActive)
                }`}
              >
                Motor
              </span>
            </Link>
            <Link
              // to={"/admin/spareparts"}
              className={`${Styles.routes} ${
                pathname === `/admin/spareparts` && Styles.routesActive
              }`}
              style={{ cursor: "not-allowed" }}
            >
              <Icon icon={"sparepartsadmin"} size={20} color={"#6D6D6D"} />
              <span
                className={`${Styles.text} ${
                  pathname === `/admin/spareparts` && Styles.textActive
                }`}
              >
                Onderdil
              </span>
            </Link>
            <Link
              // to={"/admin/servis"}
              className={`${Styles.routes} ${
                pathname === `/admin/servis` && Styles.routesActive
              }`}
              style={{ cursor: "not-allowed" }}
            >
              <Icon icon={"serviceadmin"} size={20} color={"#6D6D6D"} />
              <span
                className={`${Styles.text} ${
                  pathname === `/admin/servis` && Styles.textActive
                }`}
              >
                Servis Motor
              </span>
            </Link>

            <Link
              // to={"/admin/ads"}
              className={`${Styles.routes} ${
                pathname === `/admin/ads` && Styles.routesActive
              }`}
              style={{ cursor: "not-allowed" }}
            >
              <Icon icon={"promoadmin"} size={20} color={"#6D6D6D"} />
              <span
                className={`${Styles.text} ${
                  pathname === `/admin/ads` && Styles.textActive
                }`}
              >
                Iklan
              </span>
            </Link>
            <Link
              to={"/admin/promo"}
              className={`${Styles.routes} ${
                (pathname === `/admin/promo` && Styles.routesActive) ||
                (pathname === `/admin/promo/add` && Styles.routesActive)
              }`}
            >
              <Icon icon={"promo"} size={20} color={"#6D6D6D"} />
              <span
                className={`${Styles.text} ${
                  (pathname === `/admin/promo` && Styles.textActive) ||
                  (pathname === `/admin/promo/add` && Styles.routesActive)
                }`}
              >
                Promo
              </span>
            </Link>
          </div>

          <div className={Styles.logoutBox} style={{ cursor: "not-allowed" }}>
            <Icon icon={"logoutadmin"} size={20} color={"#6D6D6D"} />
            <span className={Styles.text}>Logout</span>
          </div>
        </div>
      </div>
      <div className={Styles.bodyResponsive}>
        <div className={Styles.sidebarContainer}>
          <div className={Styles.top}>
            {openDropdownSide ? (
              <div
                className={Styles.iconListXmark}
                onClick={() => setOpenDropdownSide(false)}
              >
                <Icon icon={"x-mark"} size={24} color={"black"} />
              </div>
            ) : (
              <div
                className={Styles.iconList}
                onClick={() => setOpenDropdownSide(true)}
              >
                <Icon icon={"list-bullet"} size={24} color={"black"} />
              </div>
            )}

            <div className={Styles.imageBox}>
              <img src={LOGO} alt="LOGO" className={Styles.imagesLogo} />
            </div>
          </div>

          <div className={Styles.routesBox}>
            <div className={Styles.routes}>
              <Icon icon={"dashboardadmin"} size={20} color={"#6D6D6D"} />
              {/* <span className={Styles.text}>Halaman Utama</span> */}
            </div>
            <Link
              to={"/admin/motor "}
              className={`${Styles.routes} ${
                pathname === `/admin/motor` && Styles.routesActive
              }`}
            >
              <Icon icon={"motoradmin"} size={20} color={"#6D6D6D"} />
              {/* <span className={Styles.text}>Motor</span> */}
            </Link>
            <Link
              to={"/admin/spareparts "}
              className={`${Styles.routes} ${
                pathname === `/admin/spareparts` && Styles.routesActive
              }`}
            >
              <Icon icon={"sparepartsadmin"} size={20} color={"#6D6D6D"} />
              {/* <span className={Styles.text}>Onderdil</span> */}
            </Link>
            <Link
              to={"/admin/servis "}
              className={`${Styles.routes} ${
                pathname === `/admin/servis` && Styles.routesActive
              }`}
            >
              <Icon icon={"serviceadmin"} size={20} color={"#6D6D6D"} />
              {/* <span className={Styles.text}>Servis Motor</span> */}
            </Link>
            <Link
              to={"/admin/promo/detail"}
              className={`${Styles.routes} ${
                pathname === `/admin/promo/detail` && Styles.routesActive
              }`}
            >
              <Icon icon={"promoadmin"} size={20} color={"#6D6D6D"} />
              <span
                className={`${Styles.text} ${
                  pathname === `/admin/promo/detail` && Styles.textActive
                }`}
              >
                Promo
              </span>
            </Link>
          </div>

          <div className={Styles.logoutBox}>
            <Icon icon={"logoutadmin"} size={20} color={"#6D6D6D"} />
            {/* <span className={Styles.text}>Logout</span> */}
          </div>
          {openDropdownSide && (
            <div className={Styles.bodyDropdownSide}>
              <div className={Styles.top}>
                <div
                  className={Styles.iconList}
                  onClick={() => setOpenDropdownSide(true)}
                >
                  {/* <Icon
                      icon={"list-bullet"}
                      size={24}
                      color={"black"}
                      onClick={() => {
                        if (openDropdown === false) {
                          setOpenDropdown(true);
                        } else {
                          setOpenDropdown(false);
                        }
                      }}
                    /> */}
                </div>
                <div className={Styles.imageBox}>
                  <img
                    src={LOGO_TEXT_BLACK}
                    alt="LOGO"
                    className={Styles.imagesLogo}
                  />
                </div>
              </div>

              <div className={Styles.routesBox}>
                <div className={Styles.routes}>
                  {/* <Icon icon={"dashboardadmin"} size={20} color={"#6D6D6D"} /> */}
                  <span className={Styles.textDashbord}>Halaman Utama</span>
                </div>
                <div className={Styles.routes}>
                  {/* <Icon icon={"motoradmin"} size={20} color={"#6D6D6D"} /> */}
                  <span className={Styles.text}>Motor</span>
                </div>
                <div className={Styles.routes}>
                  {/* <Icon icon={"sparepartsadmin"} size={20} color={"#6D6D6D"} /> */}
                  <span className={Styles.textOnderdil}>Onderdil</span>
                </div>
                <div className={Styles.routes}>
                  {/* <Icon icon={"serviceadmin"} size={20} color={"#6D6D6D"} /> */}
                  <span className={Styles.textServis}>Servis Motor</span>
                </div>
                <div className={Styles.routes}>
                  {/* <Icon icon={"promoadmin"} size={20} color={"#6D6D6D"} /> */}
                  <span className={Styles.textIklan}>Iklan</span>
                </div>
              </div>

              <div className={Styles.logoutBox}>
                {/* <Icon icon={"logoutadmin"} size={20} color={"#6D6D6D"} /> */}
                <span className={Styles.text}>Logout</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className={Styles.bodySidebarResponsive}>
        <div className={Styles.container}>
          <div className={Styles.imageBox}>
            <img src={LOGO} alt="LOGO" className={Styles.imagesLogo} />
            <img
              src={LOGO_TEXT_BLACK_RESPONSIVE}
              alt=""
              className={Styles.imagesTitleLogo}
            />
          </div>
          <div className={Styles.iconList}>
            <Icon
              icon={"list-bullet"}
              size={24}
              color={"black"}
              onClick={() => {
                if (openDropdown === false) {
                  setOpenDropdown(true);
                } else {
                  setOpenDropdown(false);
                }
              }}
            />
          </div>
        </div>

        {openDropdown && (
          <div className={Styles.bodDropdown}>
            <div className={Styles.routesDropdown}>
              <div className={Styles.routesBox}>
                <div className={Styles.routes}>
                  <Icon icon={"dashboardadmin"} size={20} color={"#6D6D6D"} />
                  <span className={Styles.text}>Halaman Utama</span>
                </div>
                <div className={Styles.routes}>
                  <Icon icon={"motoradmin"} size={20} color={"#6D6D6D"} />
                  <span className={Styles.text}>Motor</span>
                </div>
                <div className={Styles.routes}>
                  <Icon icon={"sparepartsadmin"} size={20} color={"#6D6D6D"} />
                  <span className={Styles.text}>Onderdil</span>
                </div>
                <div className={Styles.routes}>
                  <Icon icon={"serviceadmin"} size={20} color={"#6D6D6D"} />
                  <span className={Styles.text}>Servis Motor</span>
                </div>
                <div className={Styles.routes}>
                  <Icon icon={"promoadmin"} size={20} color={"#6D6D6D"} />
                  <span className={Styles.text}>Iklan</span>
                </div>
              </div>

              <div className={Styles.logoutBox}>
                <Icon icon={"logoutadmin"} size={20} color={"#6D6D6D"} />
                <span className={Styles.text}>Logout</span>
              </div>
            </div>
            <div
              className={Styles.closeDropdown}
              onClick={() => setOpenDropdown(false)}
            ></div>
          </div>
        )}
      </div>
    </div>
  );
}
