export const IconReset = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_374_7859)">
          <path
            d="M13.2508 3.00008C8.16075 2.86008 3.99075 6.94008 3.99075 12.0001H2.20075C1.75075 12.0001 1.53075 12.5401 1.85075 12.8501L4.64075 15.6501C4.84075 15.8501 5.15075 15.8501 5.35075 15.6501L8.14075 12.8501C8.46075 12.5401 8.23075 12.0001 7.79075 12.0001H5.99075C5.99075 8.10008 9.17075 4.95008 13.0908 5.00008C16.8108 5.05008 19.9408 8.18008 19.9908 11.9001C20.0408 15.8101 16.8908 19.0001 12.9908 19.0001C11.3808 19.0001 9.89075 18.4501 8.71075 17.5201C8.31075 17.2101 7.75075 17.2401 7.39075 17.6001C6.97075 18.0301 7.00075 18.7301 7.47075 19.1001C8.99075 20.2901 10.9108 21.0001 12.9908 21.0001C18.0408 21.0001 22.1308 16.8301 21.9908 11.7401C21.8608 7.05008 17.9408 3.13008 13.2508 3.00008ZM12.7408 8.00008C12.3308 8.00008 11.9908 8.34008 11.9908 8.75008V12.4301C11.9908 12.7801 12.1808 13.1101 12.4808 13.2901L15.6008 15.1401C15.9608 15.3501 16.4208 15.2301 16.6308 14.8801C16.8408 14.5201 16.7208 14.0601 16.3708 13.8501L13.4908 12.1401V8.74008C13.4908 8.34008 13.1608 8.00008 12.7408 8.00008Z"
            fill="#212121"
          />
        </g>
        <defs>
          <clipPath id="clip0_374_7859">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};
