export const IconPromo = () => {
  return (
    <>
      <svg
        width="191"
        height="199"
        viewBox="0 0 191 199"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="0.583496" width="95" height="95" rx="8" fill="#F5F5F5" />
        <rect
          x="109"
          y="21.5835"
          width="78"
          height="70"
          rx="4"
          fill="#EAEAEA"
          stroke="#F5F5F5"
          stroke-width="8"
        />
        <rect
          x="109"
          y="109.583"
          width="61"
          height="70"
          rx="4"
          fill="#EAEAEA"
          stroke="#F5F5F5"
          stroke-width="8"
        />
        <rect
          x="11"
          y="109.583"
          width="80"
          height="79"
          rx="4"
          fill="#EAEAEA"
          stroke="#F5F5F5"
          stroke-width="8"
        />
        <path
          d="M83.8337 81.75C83.8337 79.5399 84.7116 77.4202 86.2744 75.8574C87.8372 74.2946 89.9569 73.4167 92.167 73.4167C94.3771 73.4167 96.4967 74.2946 98.0595 75.8574C99.6223 77.4202 100.5 79.5399 100.5 81.75V119L110.584 120.083L151.75 138.333C156.167 140.333 158.834 144.75 158.834 149.583V185.917C158.584 192.75 153.167 198.167 146.334 198.417H92.167C89.0003 198.417 86.0003 197.167 83.8337 194.833L43.0003 159.833L49.167 153.417C50.7503 151.667 53.0003 150.75 55.3337 150.75H57.167L83.8337 165.083V81.75ZM92.167 48.4167C101.008 48.4167 109.486 51.9286 115.737 58.1798C121.988 64.431 125.5 72.9095 125.5 81.75C125.5 94.25 118.834 104.833 108.834 110.583V100.417C113.917 95.8333 117.167 89.1667 117.167 81.75C117.167 75.1196 114.533 68.7607 109.845 64.0723C105.156 59.3839 98.7974 56.75 92.167 56.75C85.5366 56.75 79.1777 59.3839 74.4893 64.0723C69.8009 68.7607 67.167 75.1196 67.167 81.75C67.167 89.1667 70.417 95.8333 75.5003 100.417V110.583C65.5003 104.833 58.8337 94.25 58.8337 81.75C58.8337 72.9095 62.3456 64.431 68.5968 58.1798C74.848 51.9286 83.3264 48.4167 92.167 48.4167ZM92.167 31.75C105.428 31.75 118.146 37.0178 127.522 46.3947C136.899 55.7715 142.167 68.4892 142.167 81.75C142.167 95.9167 136.25 108.667 126.834 117.833L118.5 114.083C123.289 110.164 127.148 105.231 129.8 99.6393C132.451 94.0481 133.829 87.9381 133.834 81.75C133.834 70.6993 129.444 60.1012 121.63 52.2872C113.816 44.4732 103.218 40.0833 92.167 40.0833C81.1163 40.0833 70.5182 44.4732 62.7042 52.2872C54.8902 60.1012 50.5003 70.6993 50.5003 81.75C50.5003 98.8333 60.7503 113.5 75.5003 119.917V128.917C56.0837 122 42.167 103.5 42.167 81.75C42.167 68.4892 47.4348 55.7715 56.8117 46.3947C66.1885 37.0178 78.9062 31.75 92.167 31.75Z"
          fill="#C7C7C7"
        />
      </svg>
    </>
  );
};
