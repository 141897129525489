import { useRef } from "react";
import style from "./index.module.scss";
import Icon from "@Atom/Icon";
export default function AdminDatePicker({
  placeholder = "Mulai Dari",
  handleChange = () => {},
  value,
  ...props
}) {
  const ref = useRef(null);
  const handleDateAndTimeChange = (e) => {
    handleChange(e?.target?.value);
  };
  return (
    <div className={style.container}>
      <input
        type={"date"}
        isselected={value ? "true" : "false"}
        onChange={handleDateAndTimeChange}
        value={value}
        className={style.input}
        ref={ref}
        data-text={placeholder}
        {...props}
      />
      <Icon
        icon={"calendar_today"}
        color={"#C2C2C2"}
        size={"24px"}
        className={style.icon}
        onClick={() => ref.current?.showPicker()}
      />
    </div>
  );
}
