import Icon from "../Icon";
import style from "./styles.module.scss";

export const Button = ({ name }) => {
  return (
    <section>
      <div className={style.buttonSection}>
        <button type="submit">{name}</button>
      </div>
    </section>
  );
};

export const ButtonCard = ({ name }) => {
  return (
    <section>
      <div className={style.boxContent}>
        <button type="submit">
          {name}
          <span>
            <Icon icon={"panahkanan"} size={10} />
          </span>
        </button>
      </div>
    </section>
  );
};

export const ButtonCardAdmin = ({
  nameSubmit,
  nameCancel,
  setShowAdd,
  handleSubmitAdd,
}) => {
  return (
    <>
      <div className={style.buttonAdmin}>
        {/* <a onClick={() => setShowAdd(false)}>{nameCancel}</a> */}
        <button
          onClick={() => setShowAdd(false)}
          className={style.buttonAdminCancel}
        >
          {nameCancel}
        </button>
        <button
          onClick={() => {
            handleSubmitAdd();
            setShowAdd(false);
          }}
          className={style.buttonAdminSubmit}
        >
          {nameSubmit}
        </button>
      </div>
    </>
  );
};
