import { useEffect, useState } from "react";
import Styles from "./styles.module.scss";
import { NavbarAdmin } from "@Molecule/NavbarAdmin";
import TableHeader from "@Molecule/TableHeader";
import AdminProdukCard from "@Molecule/AdminProductCard";
import Table from "@Atom/Table";
import Pagination from "@Molecule/Pagination";
import { Link } from "react-router-dom";
import image1 from "../../../../assets/products/1.png";

export default function Spareparts() {
  const sessionSelectedButtonId = sessionStorage.getItem("idButtonSpareparts");
  const [selectedButton, setSelectedButton] = useState("Table");

  const itemsPerPage = 100;
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  // const [selectedPage, setSelectedPage] = useState(1);
  const [selectedUsers, setSelectedUsers] = useState([]);

  console.log(selectedButton);

  const columns = [
    {
      name: "name",
      label: "Product Name",
    },
    {
      name: "stok",
      label: "Stock Left",
      render: (row) => (
        <div style={{ textAlign: "right" }}>
          {Intl.NumberFormat().format(row.stok)}
        </div>
      ),
    },
    {
      name: "price",
      label: "Fixed Price",
      render: (row) => (
        <div style={{ textAlign: "right" }}>
          Rp. {Intl.NumberFormat().format(row.price)}
        </div>
      ),
    },
    {
      name: "priceDiskon",
      label: "priceDiskon Price",
      render: (row) => (
        <div style={{ textAlign: "right" }}>
          Rp. {Intl.NumberFormat().format(row.priceDiskon)}
        </div>
      ),
    },
    {
      name: "sellOut",
      label: "Sell Out",
      render: (row) => (
        <div style={{ textAlign: "right" }}>
          {Intl.NumberFormat().format(row.sellOut)}
        </div>
      ),
    },
    {
      name: "total",
      label: "Proce x Sell Out",
      render: (row) => (
        <div style={{ textAlign: "right" }}>
          Rp. {Intl.NumberFormat().format(row.total)}
        </div>
      ),
    },
    {
      name: "date",
      label: "Date Input",
    },
    {
      name: "status",
      label: "Status",
      render: (row) => (
        <div>
          {row.status === "Available" ? (
            <div className={Styles.statusGreen}>{row.status}</div>
          ) : (
            <div className={Styles.statusRed}>{row.status}</div>
          )}
        </div>
      ),
    },
  ];

  let data = [
    {
      id: 1,
      name: "Oli Filter1",
      stok: 8,
      price: "200000",
      priceDiskon: "20000",
      sellOut: 8,
      total: "200000",
      date: "20 Juni 2021",
      status: "Available2",
      photo: image1,
    },
    {
      id: 2,
      name: "Oli Filter2",
      stok: 8,
      price: "200000",
      priceDiskon: "20000",
      sellOut: 8,
      total: "200000",
      date: "20 Juni 2021",
      status: "Available",
      photo: image1,
    },
    {
      id: 3,
      name: "Oli Filter3",
      stok: 8,
      price: "200000",
      priceDiskon: "20000",
      sellOut: 8,
      total: "200000",
      date: "20 Juni 2021",
      status: "Available",
      photo: image1,
    },
    {
      id: 3,
      name: "Oli Filter4",
      stok: 8,
      price: "200000",
      priceDiskon: "20000",
      sellOut: 8,
      total: "200000",
      date: "20 Juni 2021",
      status: "Available",
      photo: image1,
    },
    {
      id: 4,
      name: "Oli Filter5",
      stok: 8,
      price: "200000",
      priceDiskon: "20000",
      sellOut: 8,
      total: "200000",
      date: "20 Juni 2021",
      status: "Available",
      photo: image1,
    },
    {
      id: 5,
      name: "Oli Filter6",
      stok: 8,
      price: "200000",
      priceDiskon: "20000",
      sellOut: 8,
      total: "200000",
      date: "20 Juni 2021",
      status: "Available",
      photo: image1,
    },
    {
      id: 6,
      name: "Oli Filter7",
      stok: 8,
      price: "200000",
      priceDiskon: "20000",
      sellOut: 8,
      total: "200000",
      date: "20 Juni 2021",
      status: "Available",
      photo: image1,
    },
    {
      id: 7,
      name: "Oli Filter8",
      stok: 8,
      price: "200000",
      priceDiskon: "20000",
      sellOut: 8,
      total: "200000",
      date: "20 Juni 2021",
      status: "Available",
      photo: image1,
    },
    {
      id: 8,
      name: "Oli Filter9",
      stok: 8,
      price: "200000",
      priceDiskon: "20000",
      sellOut: 8,
      total: "200000",
      date: "20 Juni 2021",
      status: "Available",
      photo: image1,
    },
    {
      id: 9,
      name: "Oli Filter10",
      stok: 8,
      price: "200000",
      priceDiskon: "20000",
      sellOut: 8,
      total: "200000",
      date: "20 Juni 2021",
      status: "Available",
      photo: image1,
    },
    {
      id: 10,
      name: "Oli Filter11",
      stok: 8,
      price: "200000",
      priceDiskon: "20000",
      sellOut: 8,
      total: "200000",
      date: "20 Juni 2021",
      status: "Available",
      photo: image1,
    },
    {
      id: 11,
      name: "Oli Filter12",
      stok: 8,
      price: "200000",
      priceDiskon: "20000",
      sellOut: 8,
      total: "200000",
      date: "20 Juni 2021",
      status: "Available",
      photo: image1,
    },
    {
      id: 12,
      name: "Oli Filter13",
      stok: 8,
      price: "200000",
      priceDiskon: "20000",
      sellOut: 8,
      total: "200000",
      date: "20 Juni 2021",
      status: "Available",
      photo: image1,
    },
    {
      id: 13,
      name: "Oli Filter14",
      stok: 8,
      price: "200000",
      priceDiskon: "20000",
      sellOut: 8,
      total: "200000",
      date: "20 Juni 2021",
      status: "Available",
      photo: image1,
    },
  ];

  const setActiveButton = (idButtonSpareparts) => {
    setSelectedButton(idButtonSpareparts);
    sessionStorage.setItem("idButtonSpareparts", idButtonSpareparts);
  };

  useEffect(() => {
    if (sessionSelectedButtonId) {
      setSelectedButton(sessionSelectedButtonId);
    } else {
      setSelectedButton("Table");
    }
  }, [sessionSelectedButtonId]);

  const [valuePage, setValuePage] = useState(10);
  const [xvaluePage, xsetValuePage] = useState(12);
  const [pages, setPages] = useState(0);
  const [pageList, setPageList] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);

  const totalData = data.length;
  const recordsPrePage = 10;
  const xrecordsPrePage = 12;
  const totalPage = Math.ceil(totalData / recordsPrePage);
  const xtotalPage = Math.ceil(totalData / xrecordsPrePage);
  const xpages = "" + pages + "";
  const replacePage = xpages.replace("-", " ");
  const splitPage = replacePage.split(" ");
  const firstList = splitPage[0] - 1;
  const lastList = firstList + recordsPrePage;
  const xlastList = firstList + xrecordsPrePage;
  const dataSemua = data.slice(firstList, lastList);
  const xdataSemua = data.slice(firstList, xlastList);

  const [xfilter, xsetFilter] = useState(1);

  const filterChange = (e) => {
    // console.log(e, "parent");
    xsetFilter(e);
  };

  let sortrating;
  if (xfilter === "Paling lama") {
    if (selectedButton === "Table") {
      sortrating = [...dataSemua].sort((a, b) => a.id - b.id);
    } else {
      sortrating = [...xdataSemua].sort((a, b) => a.id - b.id);
    }
  } else if (xfilter === "Paling baru") {
    if (selectedButton === "Table") {
      sortrating = [...dataSemua].sort((a, b) => b.id - a.id);
    } else {
      sortrating = [...xdataSemua].sort((a, b) => b.id - a.id);
    }
  } else {
    if (selectedButton === "Table") {
      sortrating = [...dataSemua].sort((a, b) => a.id - b.id);
    } else {
      sortrating = [...xdataSemua].sort((a, b) => a.id - b.id);
    }
  }

  return (
    <div className={Styles.body}>
      <NavbarAdmin
        icon={"sparepartsadmin"}
        size={32}
        color={"#FFFFFF"}
        selectedButton={selectedButton}
        onClickTable={() => setActiveButton("Table")}
        onClickGrid={() => setActiveButton("Grid")}
        label={"Onderdil"}
        isRoute={true}
        labelRouteFirst="Table"
        labelRouteSecond="Grid"
      />

      <div className={Styles.box}>
        {selectedButton === "Table" && (
          <>
            <TableHeader
              type="motorcycle"
              labelAdd="Tambah Produk Baru"
              labelName="Daftar Onderdil"
              isAdd={true}
              pathname={"/admin/spareparts/add"}
              filterChange={filterChange}
            />

            <Table
              itemsPerPage={recordsPrePage}
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
              data={sortrating}
              placeholderText="Belum ada data yang di masukkan"
              columns={columns}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              isLoading={isLoadingData}
              type={"admin-table"}
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              link="/admin/spareparts/detail/"
            />

            <div className={Styles.pagination}>
              <div className={Styles.paginationLeft}>
                Memperlihatkan {parseInt(firstList + 1)} - {parseInt(lastList)}{" "}
                dari {totalData} produk
              </div>
              <div className={Styles.paginationRight}>
                <Pagination
                  total={10}
                  totalPage={totalPage}
                  totalData={totalData}
                  value={valuePage}
                  setValue={setValuePage}
                  pages={pages}
                  setPages={setPages}
                  pageList={pageList}
                  setPageList={setPageList}
                  selectedPage={selectedPage}
                  setSelectedPage={setSelectedPage}
                />
              </div>
            </div>
          </>
        )}
        {selectedButton === "Grid" && (
          <>
            <TableHeader
              type="motorcycle"
              labelAdd="Tambah Produk Baru"
              labelName="Daftar Onderdil"
              isAdd={true}
              pathname={"/admin/spareparts/add"}
              filterChange={filterChange}
            />
            <AdminProdukCard
              data={sortrating}
              link="/admin/spareparts/detail/"
            />
            <div className={Styles.pagination}>
              <div className={Styles.paginationLeft}>
                Memperlihatkan {parseInt(firstList + 1)} - {parseInt(xlastList)}{" "}
                dari {totalData} produk
              </div>
              <div className={Styles.paginationRight}>
                <Pagination
                  total={10}
                  totalPage={xtotalPage}
                  totalData={totalData}
                  value={xvaluePage}
                  setValue={xsetValuePage}
                  pages={pages}
                  setPages={setPages}
                  pageList={pageList}
                  setPageList={setPageList}
                  selectedPage={selectedPage}
                  setSelectedPage={setSelectedPage}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
