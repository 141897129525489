import React from "react";
import Styles from "./style.module.scss";
import ImgSearch from "@Assets/Images/search-empty.png";
export default function EmptyHandlerSearch({
  description = "Tidak ada data yang ditampilkan",
}) {
  return (
    <div className={Styles.wrapperEmptyHandlerSearch}>
      <img src={ImgSearch} alt="images-empty" />
      <p className={Styles.description}>{description}</p>
    </div>
  );
}
