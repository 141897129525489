import EmptyHandlerGetData from "@Atom/ErrorGetData";
import Spinner from "@Atom/Spinner";
import MotorCycleEdit from "@Organism/Admin/MotorcycleEdit";
import { getDetailMotor } from "@Services/admin/motor/getDetailMotor";
import { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";
export const loader = async (route) => {
  const { id } = route?.params;
  return defer({
    getData: await getDetailMotor(id),
  });
};
export default function AdminMotorcycleEditPage() {
  const { getData } = useLoaderData();
  return (
    <Suspense fallback={<Spinner />}>
      <Await errorElement={<EmptyHandlerGetData />} resolve={getData}>
        {(data) => <MotorCycleEdit dataForm={data} />}
      </Await>
    </Suspense>
  );
}
