import EmptyHandlerGetData from "@Atom/ErrorGetData";
import Spinner from "@Atom/Spinner";
import DetailPromo from "@Organism/Admin/DetailPromo";
import { getDetailPromo } from "@Services/admin/promo/getListPromoById";
import { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";
export const loader = async (route) => {
  const params = new URLSearchParams(route?.request?.url);
  const page = params?.get("page") || 1;
  const limit = params?.get("limit") || 10;
  const searchQuery = params?.get("search") || "";
  const { id } = route?.params;
  return defer({
    getDetailPromo: await getDetailPromo(id, limit, page, searchQuery),
  });
};

export default function DetailPromoPage() {
  const { getDetailPromo } = useLoaderData();
  return (
    <Suspense fallback={<Spinner />}>
      <Await errorElement={<EmptyHandlerGetData />} resolve={getDetailPromo}>
        {(data) => <DetailPromo dataDetail={data} />}
      </Await>
    </Suspense>
  );
}
