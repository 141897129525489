import Styles from "./style.module.scss";
export const IsAvailable = ({
  isAvailable,
  trueText = "Available",
  falseText = "Unavailable",
}) => {
  return (
    <div className={Styles.isAvailable} is-true={isAvailable?.toString()}>
      <span>{isAvailable === true ? trueText : falseText}</span>
    </div>
  );
};
