import { NavbarAdmin } from "@Molecule/NavbarAdmin";
import Styles from "./styles.module.scss";
import { useEffect, useState } from "react";
import Icon from "@Atom/Icon";
import { AdminTitleProductDetail } from "@Atom/titleComponent/index";
import { AdminTitleProductEdit } from "@Atom/titleComponent/index";
import { AdminDisplayEdit } from "@Atom/titleComponent/index";
import { IconPromo } from "@Atom/svg/IconPromo";
import { AdminPromoCard } from "@Molecule/AdminPromoCard/index";
import { FormAddPromoCard } from "@Molecule/AdminFormAddPromoCard";
import Dropdown from "@Atom/DropDown";
import image1 from "../../../../assets/Screenshot 2023-02-28 at 10.15.png";
import axios from "axios";
import AlertShowProcess from "@Molecule/Alert/AlertShowProcess";
import { Link } from "react-router-dom";

export default function AdminAdsLayout() {
  const [showAdd, setShowAdd] = useState(false);
  const sessionSelectedButtonId = sessionStorage.getItem("idButtonPromo");
  const [selectedButton, setSelectedButton] = useState("DetilPromo");
  const [dropDownRemove, setDropDownRemove] = useState("false");

  const setActiveButton = (idButtonPromo) => {
    setSelectedButton(idButtonPromo);
    sessionStorage.setItem("idButtonPromo", idButtonPromo);
  };

  useEffect(() => {
    if (sessionSelectedButtonId) {
      setSelectedButton(sessionSelectedButtonId);
    } else {
      setSelectedButton("DetilPromo");
    }
  }, [sessionSelectedButtonId]);

  const [xfilter, xsetFilter] = useState("Paling lama");
  const [idPromo, setIdPromo] = useState(null);
  const [formStatus, setFormStatus] = useState("Detail");
  const [displayStatus, setDisplayStatus] = useState("Detail");

  const filterChange = (e) => {
    xsetFilter(e);
  };

  const [selectedModel, setSelectedModel] = useState("");
  const [selectedModelCode, setSelectedModelCode] = useState(null);
  const [selectedModelId, setSelectedModelId] = useState(null);

  let option = [
    {
      id: 1,
      name: "Paling baru",
      // code: "DC01",
    },
    {
      id: 2,
      name: "Paling lama",
      // code: "DC02",
    },
  ];

  let dataPromo = [
    {
      id: 1,
      name: "Yamaha Alfa Scorpii 1",
      ket: "Pilih satu konten di daftar, untuk melihat pratinjau detail kontenPilih satu konten di daftar, untuk melihat pratinjau detail kontenPilih satu konten di daftar, untuk melihat pratinjau detail kontenPilih satu konten di daftar, untuk melihat pratinjau detail",
      tanggal: "Created on 10 April 2022",
      photo: image1,
    },
    {
      id: 2,
      name: "Yamaha Alfa Scorpii 2",
      ket: "Pilih satu konten di daftar, untuk melihat pratinjau detail kontenPilih satu konten di daftar, untuk melihat pratinjau detail kontenPilih satu konten di daftar, untuk melihat pratinjau detail kontenPilih satu konten di daftar, untuk melihat pratinjau detail",
      tanggal: "Created on 10 April 2022",
      photo: image1,
    },
    {
      id: 3,
      name: "Yamaha Alfa Scorpii 3",
      ket: "Pilih satu konten di daftar, untuk melihat pratinjau detail kontenPilih satu konten di daftar, untuk melihat pratinjau detail kontenPilih satu konten di daftar, untuk melihat pratinjau detail kontenPilih satu konten di daftar, untuk melihat pratinjau detail",
      tanggal: "Created on 10 April 2022",
      photo: image1,
    },
    {
      id: 4,
      name: "Yamaha Alfa Scorpii 4",
      ket: "Pilih satu konten di daftar, untuk melihat pratinjau detail kontenPilih satu konten di daftar, untuk melihat pratinjau detail kontenPilih satu konten di daftar, untuk melihat pratinjau detail kontenPilih satu konten di daftar, untuk melihat pratinjau detail",
      tanggal: "Created on 10 April 2022",
      photo: image1,
    },
    {
      id: 5,
      name: "Yamaha Alfa Scorpii 5",
      ket: "Pilih satu konten di daftar, untuk melihat pratinjau detail kontenPilih satu konten di daftar, untuk melihat pratinjau detail kontenPilih satu konten di daftar, untuk melihat pratinjau detail kontenPilih satu konten di daftar, untuk melihat pratinjau detail",
      tanggal: "Created on 10 April 2022",
      photo: image1,
    },
    {
      id: 6,
      name: "Yamaha Alfa Scorpii 6",
      ket: "Pilih satu konten di daftar, untuk melihat pratinjau detail kontenPilih satu konten di daftar, untuk melihat pratinjau detail kontenPilih satu konten di daftar, untuk melihat pratinjau detail kontenPilih satu konten di daftar, untuk melihat pratinjau detail",
      tanggal: "Created on 10 April 2022",
      photo: image1,
    },
  ];

  let sortrating;
  if (xfilter === "Paling lama") {
    sortrating = [...dataPromo].sort((a, b) => a.id - b.id);
  } else if (xfilter === "Paling baru") {
    sortrating = [...dataPromo].sort((a, b) => b.id - a.id);
  } else {
    sortrating = [...dataPromo].sort((a, b) => a.id - b.id);
  }

  const clickStatusFormDetail = (e) => {
    setIdPromo(e);
    setFormStatus("Edit");
  };
  const clickStatusFormEdit = (e) => {
    setIdPromo(e);
    setFormStatus("Detail");
  };

  const [dataFormEdit, setDataFormEdit] = useState({
    id: "",
    name: "",
    ket: "",
    photo: "",
  });

  const clickChange = (e) => {
    setIdPromo(e);
    setFormStatus("Detail");
    getDataPromo(e);
  };

  const getDataPromo = (e) => {
    const filterPromo = dataPromo.filter((course) => course.id === e);

    filterPromo.map((each) => {
      return axios.get(each.photo, { responseType: "blob" }).then((res) => {
        const getFileInfo = res.data.type.split("/");
        const getDataPromoPespon = {
          name: each.photo.split("/").pop(),
          lastModified: "",
          webkitRelativePath: "",
          size: (res.data.size / 1000).toFixed(2),
          type: getFileInfo[0],
          format: getFileInfo[1],
        };

        const get = {
          id: each.id,
          name: each.name,
          ket: each.ket,
          photo: each.photo,
          photoName: getDataPromoPespon.name,
          lastModified: getDataPromoPespon.lastModified,
          fileSize: getDataPromoPespon.size,
          fileType: getDataPromoPespon.type,
          fileFormat: getDataPromoPespon.format,
          previewUrl: each.photo,
        };
        setDataFormEdit(get);
      });
    });
  };

  const handlesubmit = () => {
    setShowAlert(true);
  };

  const handleDisplay = () => {
    setShowAlert(true);

    console.log(displayPromo, "tes");
  };

  const onChange = (e) => {
    setDataFormEdit({ ...dataFormEdit, [e.target.name]: e.target.value });
  };

  const handleFile = (file) => {
    const postFileInfo = file.type.split("/");

    const post = {
      id: dataFormEdit.id,
      name: dataFormEdit.name,
      ket: dataFormEdit.ket,
      photo: dataFormEdit.photo,
      photoName: file.name,
      lastModified: file.lastModified,
      fileSize: (file.size / 1000).toFixed(2),
      fileType: postFileInfo[0],
      fileFormat: postFileInfo[1],
      previewUrl: URL.createObjectURL(file),
    };
    setDataFormEdit(post);
  };

  const removePhoto = () => {
    setDataFormEdit({
      ...dataFormEdit,
      photoName: "",
      lastModified: "",
      fileSize: "",
      fileType: "",
      fileFormat: "",
      previewUrl: "",
    });
  };

  function handleOnBrows(event) {
    event.preventDefault();
    event.stopPropagation();
    let ximageFile = event.target.files[0];
    handleFile(ximageFile);
  }

  const [showAlert, setShowAlert] = useState(false);
  const [responProses, setResponProses] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      setResponProses("success");
    }, 2000);
  }, [showAlert]);

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
      setResponProses(null);
    }, 1000);
  }, [responProses]);

  let getlist = [1, 2, 3, 4];

  const [displayPromo, setDisplayPromo] = useState(getlist);

  let displayFilter;
  let displayCopy;
  const addDisplay = (e) => {
    if (e === null) {
    } else {
      displayFilter = displayPromo.filter((el) => el === e);

      if (displayFilter.length > 0) {
        displayCopy = displayPromo.filter(function (letter) {
          return letter !== e;
        });
      } else {
        displayCopy = [...displayPromo, e];
      }
      setDisplayPromo(displayCopy);
    }
  };

  const resetButton = () => {
    setDisplayPromo([]);
  };

  const xdisplayFilter = dataPromo.filter(({ id }) =>
    displayPromo.includes(id)
  );
  return (
    <div className={Styles.body} id="create-course-form">
      <NavbarAdmin
        icon={"promoadmin"}
        size={32}
        color={"#FFFFFF"}
        selectedButton={selectedButton}
        onClickTable={() => setActiveButton("DetilPromo")}
        onClickGrid={() => setActiveButton("TampilanPromo")}
        label={"Iklan"}
        isRoute={true}
        labelRouteFirst="Detail Iklan"
        labelRouteSecond="Pengaturan Carousel"
        placeholder={"Cari Iklan"}
      />

      <div className={Styles.box}>
        <div className={Styles.detailBox}>
          {selectedButton === "DetilPromo" && (
            <>
              <div className={Styles.leftDetail}>
                <div className={Styles.title}>
                  <div className={Styles.text}>Daftar Iklan</div>
                  <div
                    className={Styles.filterItems}
                    // style={{ width: "195px" }}
                  >
                    <Dropdown
                      options={option}
                      // name="filter"
                      value={selectedModel}
                      // setValue={setSelectedModel}
                      setValue={(e) => {
                        setSelectedModel(e);
                        filterChange(e);
                      }}
                      selectedStoreCode={selectedModelCode}
                      setSelectedStoreCode={setSelectedModelCode}
                      selectedStoreId={selectedModelId}
                      setSelectedStoreId={setSelectedModelId}
                      placeholder="Urutkan dari :"
                      autoClose
                      name="filterTable"
                      type={"motorcycleDropdown"}
                      // onChange={handleChange}
                    />
                  </div>
                  <div className={Styles.listButton}>
                    <Link onClick={() => setShowAdd(true)}>
                      <Icon
                        icon={"plus"}
                        size={20}
                        color={"white"}
                        className={Styles.iconPlus}
                      />
                    </Link>

                    <Link
                      onClick={() => {
                        if (dropDownRemove === false) {
                          setDropDownRemove(true);
                        } else {
                          setDropDownRemove(false);
                        }
                      }}
                    ></Link>
                    {showAdd ? (
                      <div>
                        <FormAddPromoCard
                          setShowAdd={setShowAdd}
                          setShowAlert={setShowAlert}
                          setResponProses={setResponProses}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className={Styles.cardProduct}>
                  {/* <div className={Styles.judul}> */}
                  <div className={Styles.judul}>{dataPromo.length} Promo</div>
                  {/* </div> */}
                  <div className={Styles.cards}>
                    <AdminPromoCard
                      dataPromo={sortrating}
                      clickChange={clickChange}
                      idPromo={idPromo}
                      selectedButton={selectedButton}
                      displayPromo={displayPromo}
                    />
                  </div>
                </div>
              </div>

              <div className={Styles.rightDetail}>
                {idPromo === null ? (
                  <>
                    <div className={Styles.title}>
                      <div className={Styles.text}>Detail Iklan</div>
                    </div>
                    <div className={Styles.cardsDetail}>
                      <IconPromo />
                      <p>
                        Pilih salah satu konten pada daftar di kiri, untuk
                        melihat detail konten promo
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    {formStatus === "Detail" ? (
                      <>
                        <div className={Styles.title}>
                          <AdminTitleProductDetail
                            name={"Detail Promo"}
                            textButton="Edit"
                            formStatus={clickStatusFormDetail}
                            idPromo={idPromo}
                          />
                        </div>
                        <div className={Styles.xcardsDetail}>
                          <span className={Styles.judul}>Judul</span>
                          <span
                            className={Styles.ket}
                            // onChange={each.name}
                          >
                            {dataFormEdit.name}
                          </span>
                          <span className={Styles.judul}>Deskripsi</span>
                          <span className={Styles.ket}>{dataFormEdit.ket}</span>
                          <span className={Styles.judul}>Detail Gambar</span>
                          <span className={Styles.ket}>
                            <img
                              src={dataFormEdit.photo}
                              alt=""
                              style={{ borderRadius: "8px" }}
                              // style={{ width: "100%" }}
                            />
                          </span>
                          <span className={Styles.xket}>
                            {dataFormEdit.fileSize} kb
                          </span>
                          <span className={Styles.textUpload}>
                            Your image should be 3000x1500px and must be in JPG
                            or PNG format.
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={Styles.title}>
                          <AdminTitleProductEdit
                            name={"Detail Promo"}
                            textButton="Detail"
                            // path={"/admin/promo/edit/" + each.id}
                            formStatus={clickStatusFormEdit}
                            idPromo={idPromo}
                            // style={{ padding: "50px 0" }}
                            submitButton={handlesubmit}
                          />
                        </div>
                        <div className={Styles.xcardsDetail}>
                          <span className={Styles.judul}>Judul</span>
                          <span className={Styles.ket}>
                            <input
                              type="text"
                              name="name"
                              // placeholder="Oil Filter"
                              onChange={onChange}
                              // ref={inputFile}
                              value={dataFormEdit.name}
                            />
                          </span>
                          <span className={Styles.judul}>Deskripsi</span>
                          <span className={Styles.ket}>
                            <textarea
                              // type="text"
                              name="ket"
                              value={dataFormEdit.ket}
                              // placeholder="Oil Filter"
                              onChange={onChange}
                              // ref={inputFile}
                            />
                          </span>
                          <span className={Styles.judul}>Detail Gambar</span>
                          <span className={Styles.ket}>
                            {dataFormEdit.previewUrl ? (
                              <>
                                <div className={Styles.imgFoto}>
                                  <span
                                    className={Styles.removePhoto}
                                    onClick={() => removePhoto()}
                                  >
                                    X
                                  </span>
                                  <img
                                    src={
                                      dataFormEdit.previewUrl
                                        ? dataFormEdit.previewUrl
                                        : dataFormEdit.photo
                                    }
                                    style={{ width: "100%" }}
                                    alt=""
                                  />
                                </div>
                                <span className={Styles.namaFoto}>
                                  {/* {dataFormEdit.previewUrl
                                  ? "dataFormEdit.photoname"
                                  : "dataFormEdit.photo"} */}
                                  {dataFormEdit.photoName.split("/").pop()}
                                  {/* asdasd */}
                                </span>
                                {dataFormEdit.fileType}
                                <Icon
                                  icon={"i_010-bullet"}
                                  size={12}
                                  style={{
                                    marginBottom: "-2px",
                                  }}
                                />
                                {dataFormEdit.fileSize} Mb
                                <Icon
                                  icon={"i_010-bullet"}
                                  size={12}
                                  style={{
                                    marginBottom: "-2px",
                                  }}
                                />
                                {dataFormEdit.fileFormat}
                              </>
                            ) : (
                              <>
                                <input
                                  type="file"
                                  accept="image/*"
                                  className="input-field"
                                  onChange={handleOnBrows}
                                  id="create-course-form"
                                />
                                <div className={Styles.uploadBox}>
                                  <div
                                    className={Styles.buttonUpload}
                                    onClick={() =>
                                      document
                                        .querySelector(".input-field")
                                        .click()
                                    }
                                  >
                                    <Icon icon={"download"} size={18} /> Upload
                                  </div>
                                </div>
                                <span className={Styles.textUpload}>
                                  Dimensi gambar pada ukuran: 3000 x 1500
                                </span>
                              </>
                            )}
                          </span>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
              {showAlert === true ? (
                <>
                  <AlertShowProcess
                    statusAlert={
                      responProses === "success"
                        ? "success"
                        : responProses === "error"
                        ? "error"
                        : responProses === null
                        ? null
                        : ""
                    }
                    iconAlert={
                      responProses === "success"
                        ? "checkmark"
                        : responProses === "error"
                        ? "x-cross1"
                        : ""
                    }
                    textAlert={
                      responProses === "success"
                        ? "File berhasil diunggah"
                        : responProses === "error"
                        ? "File gagal diunggah"
                        : ""
                    }
                  />
                </>
              ) : (
                ""
              )}
            </>
          )}

          {selectedButton === "TampilanPromo" && (
            <>
              <div className={Styles.leftDetail}>
                <div className={Styles.title}>
                  <div className={Styles.text}>Daftar Iklan</div>

                  <div
                    className={Styles.filterItems}
                    // style={{ width: "195px" }}
                  >
                    <Dropdown
                      options={option}
                      // name="filter"
                      value={selectedModel}
                      // setValue={setSelectedModel}
                      setValue={(e) => {
                        setSelectedModel(e);
                        filterChange(e);
                      }}
                      selectedStoreCode={selectedModelCode}
                      setSelectedStoreCode={setSelectedModelCode}
                      selectedStoreId={selectedModelId}
                      setSelectedStoreId={setSelectedModelId}
                      placeholder="Urutkan dari :"
                      autoClose
                      name="filterTable"
                      type={"motorcycleDropdown"}
                      // onChange={handleChange}
                    />
                  </div>
                </div>
                <div className={Styles.cardProduct}>
                  <p>{dataPromo.length} Promo</p>
                  <div className={Styles.cards}>
                    <AdminPromoCard
                      dataPromo={sortrating}
                      clickChange={clickChange}
                      idPromo={idPromo}
                      selectedButton={selectedButton}
                      displayPromo={displayPromo}
                      addDisplay={addDisplay}
                      displayStatus={displayStatus}
                    />
                  </div>
                </div>
              </div>
              <div className={Styles.rightDetail}>
                <div className={Styles.title}>
                  {displayStatus === "Detail" ? (
                    <AdminDisplayEdit
                      name={"Tampilan Carousel"}
                      textButton="Edit"
                      // path={"/admin/promo/edit/" + each.id}
                      formStatus={clickStatusFormEdit}
                      idPromo={idPromo}
                      // style={{ padding: "50px 0" }}
                      displayStatus={displayStatus}
                      setDisplayStatus={setDisplayStatus}
                      resetButton={resetButton}
                    />
                  ) : (
                    <>
                      <div className={Styles.text} style={{ width: "100%" }}>
                        Tampilan Carousel
                      </div>
                    </>
                  )}
                </div>
                {displayPromo.length > 0 ? (
                  <div className={Styles.xcardsDetail}>
                    <span style={{ marginBottom: "15px" }}>
                      {displayPromo.length} Iklan terpilih
                    </span>
                    <div style={{ height: "630px", overflowY: "scroll" }}>
                      {xdisplayFilter.map((each) => {
                        return (
                          <>
                            <div className={Styles.bodyCarousel}>
                              <div className={Styles.bodyCarouselKiri}>
                                <img
                                  src={each.photo}
                                  style={{ width: "100%" }}
                                  alt=""
                                />
                              </div>
                              <div className={Styles.bodyCarouselKanan}>
                                <span className={Styles.judul}>Judul</span>
                                <span
                                  className={Styles.ket}
                                  // onChange={each.name}
                                >
                                  {each.name}
                                </span>
                                <span className={Styles.judul}>Deskripsi</span>
                                <span className={Styles.ket}>{each.ket}</span>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <div className={Styles.cardsDetail}>
                    <IconPromo />
                    <p>
                      Pilih satu konten di daftar, untuk melihat pratinjau
                      detail konten
                    </p>
                  </div>
                )}
              </div>
            </>
          )}
        </div>

        {displayStatus === "Edit" ? (
          <div className={Styles.buttonBox}>
            <div className={Styles.null}></div>
            <div className={Styles.button}>
              <span
                className={Styles.buttonAdd}
                onClick={() => {
                  handleDisplay();
                }}
              >
                Save Carousel Display
              </span>
              <span
                className={Styles.buttonCancel}
                onClick={() => setDisplayStatus("Detail")}
              >
                <span>Cancel</span>
              </span>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      {/* {showAlert === true ? ( */}
      <div>
        {/* <AlertShow
          xshowAlert={showAlert}
          statusAlert={errorsRespon === "success" ? "success" : "error"}
          iconAlert={errorsRespon === "success" ? "checkmark" : "cancel-circle"}
          textAlert={errorsText}
        /> */}
        {/* ) : (
        ""
      )} */}
      </div>
    </div>
  );
}
