import { NavbarAdmin } from "@Molecule/NavbarAdmin";
import Styles from "./styles.module.scss";
import Icon from "@Atom/Icon";
import InputForm from "@Atom/Input";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Modal from "@Atom/Modal";
import ImgQuestion from "@Assets/Images/Question.png";
import { formatPrice } from "utility/formatCurrency";
import Lottie from "lottie-react";
import loadPost from "@Assets/Animate/loadPost.json";
import { createMotor } from "@Services/admin/motor/createMotor";
import errorImgIcon from "@Assets/error.png";
import succesImgIcon from "@Assets/check_circle.png";
// import useImageUploader from "@Hooks/useImageUploader";
import Polygon from "@Assets/Polygon.png";
import ErrorInfo from "@Assets/Images/exclamation-circle.png";
import Spinner from "@Atom/Spinner";
export default function MotorcycleAdd() {
  // const {
  //   handleImageUpload: handleCompressThumbnail,
  //   compressedImage: compressedImageThumbnail,
  // } = useImageUploader();
  //START FUNCTION
  const [variants, setVariants] = useState([
    {
      id: 1,
      name: "",
      price: "",
      variantColors: [
        {
          id: 1,
          name: "",
          code: "",
          picture: "",
        },
      ],
    },
  ]);
  const onClickAddColor = (variantId) => {
    setVariants(
      variants?.map((obj) => {
        if (obj?.id === variantId) {
          return {
            ...obj,
            variantColors: [
              ...obj?.variantColors,
              {
                id: new Date()?.getTime(),
                name: "",
                code: "",
                picture: "",
              },
            ],
          };
        }
        return obj;
      })
    );
  };
  const handleRemoveVariantComponent = (id) => {
    setVariants(variants?.filter((obj) => obj?.id !== id));
  };

  const navigate = useNavigate();
  const refImgThumbnail = useRef(null);

  const [previewThumbnail, setPreviewThumbnail] = useState(null);
  const handleReffClick = () => {
    refImgThumbnail?.current?.click();
  };
  const [thumbnailImg, setThumbnailImg] = useState(null);
  const categoryItemsDropdown = useMemo(() => {
    return ["MAXi", "Classy", "Matic", "Sport", "Off-Road", "Moped/Bebek"];
  }, []);
  const [categoryItems, setIsCategoryItems] = useState("");
  const [categoryID, setCategoryID] = useState(null);
  useEffect(() => {
    if (categoryItemsDropdown[0] === categoryItems) {
      setCategoryID(1);
    } else if (categoryItemsDropdown[1] === categoryItems) {
      setCategoryID(2);
    } else if (categoryItemsDropdown[2] === categoryItems) {
      setCategoryID(3);
    } else if (categoryItemsDropdown[3] === categoryItems) {
      setCategoryID(4);
    } else if (categoryItemsDropdown[4] === categoryItems) {
      setCategoryID(5);
    } else if (categoryItemsDropdown[5] === categoryItems) {
      setCategoryID(6);
    }
  }, [categoryItems, categoryItemsDropdown]);

  const [dataAddMotor, setAddDataMotor] = useState({
    name: "",
    description: "",
    cc: "",
    year: "",
  });
  const handleChangeInput = (event) => {
    const { name, value } = event.target;
    if (value) {
      setIsErrorField((prevErrors) => ({
        ...prevErrors,
        [name]: false,
      }));
    }
    if (name === "cc" || name === "year") {
      const isNumber = /^[0-9]*$/.test(value);
      if (isNumber) {
        setAddDataMotor((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    } else {
      setAddDataMotor((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };
  const [addFeatures, setAddFeatures] = useState([
    {
      id: 1,
      title: "",
      description: "",
      image: "",
    },
  ]);
  const [isResetFeature, setIsResetFeature] = useState(false);
  const handleRemoveAddFeature = (id) => {
    setAddFeatures(addFeatures?.filter((obj) => obj?.id !== id));
  };
  const [imgPreviewColor, setImgPreviewColor] = useState();
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);

  const handleResetForm = useCallback(() => {
    setVariants([
      {
        id: 1,
        name: "",
        price: "",
        variantColors: [
          {
            id: 1,
            name: "",
            color_code: "",
            color_picture: "",
          },
        ],
      },
    ]);
    setAddFeatures([
      {
        id: 1,
        title: "",
        description: "",
        image: "",
      },
    ]);
    setIsResetFeature(!isResetFeature);
    setAddDataMotor({});
    setIsCategoryItems("");
    setPreviewThumbnail(null);
    setImgPreviewColor();
    setDataSpecEngine({});
    setDataSpecFrame({});
    setDataSpecDimensions({});
    setDataSpecElectrical({});
    setLink([
      {
        id: 1,
        link_url: "",
      },
    ]);
    if (refImgThumbnail.current) {
      refImgThumbnail.current.value = "";
    }
    setIsErrorField({
      name: false,
      description: false,
      cc: false,
      year: false,
      imgThumbnail: false,
      categoryID: false,
      nameVariant: [false],
      priceVariant: [false],
      nameVariantColors: [[false]],
      codeVariantColors: [[false]],
      imageVariantColors: [[false]],
      titleFeature: [false],
      descriptionFeature: [false],
      imageFeature: [false],
    });
  }, [isResetFeature]);
  const [dataSpecEngine, setDataSpecEngine] = useState({
    position_cilinder: "",
    engine_capacity: "",
    diameter: "",
    compression: "",
    power_maximum: "",
    torque_maximum: "",
    system_starter: "",
    system_oil: "",
    oil_capacity: "",
    system_fuel: "",
    type_clucth: "",
    type_tranmission: "",
    type_engine: "",
  });
  const handleChangeSpecEngine = (event) => {
    const { name, value } = event?.target;
    setDataSpecEngine((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const [dataSpecFrame, setDataSpecFrame] = useState({
    type_frame: "",
    front_suspension: "",
    rear_suspension: "",
    rear_tire: "",
    front_brake: "",
    rear_brake: "",
  });
  const handleChangeSpecFrame = (e) => {
    const { name, value } = e?.target;
    setDataSpecFrame((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [dataSpecDimensions, setDataSpecDimensions] = useState({
    length: "",
    width: "",
    height: "",
    wheelbase: "",
    ground_clearance: "",
    seat_height: "",
    curb_weight: "",
    fuel_tank_capacity: "",
  });
  const handleChangeSpecDimensions = (e) => {
    const { name, value } = e.target;
    setDataSpecDimensions((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [dataSpecElectrical, setDataSpecElectrical] = useState({
    ignition_system: "",
    battery: "",
    spark_plug_type: "",
  });
  const handleChangeSpecElectrical = (e) => {
    const { name, value } = e.target;
    setDataSpecElectrical((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [link, setLink] = useState([
    {
      id: 1,
      link_url: "",
    },
  ]);
  useEffect(() => {
    const updatedLinks = link.map((l) => {
      const isError = !l.link_url.startsWith("https://");
      return { ...l, isError };
    });
    const hasChanges = JSON.stringify(link) !== JSON.stringify(updatedLinks);
    if (hasChanges) {
      setLink(updatedLinks);
    }
  }, [link]);

  const handleRemoveLink = (id) => {
    setLink(link.filter((el) => el?.id !== id));
  };

  const [errorImgThumbnail, setErrorImgThumbnail] = useState("");
  const [loadingUploadThumbnail, setLoadingUploadThumbnail] = useState(false);
  const handleFileChangeValidate = async (event) => {
    setErrorImgThumbnail("");
    const file = event?.target?.files[0];
    // const optionThumbnail = {
    //   maxSizeMB: 1,
    //   maxWidthOrHeight: 500,
    //   useWebWorker: true,
    // };
    if (file) {
      setLoadingUploadThumbnail(true);
      try {
        setThumbnailImg(file);
        // await handleCompressThumbnail(file, 500, 500, optionThumbnail);
        setPreviewThumbnail(URL.createObjectURL(file));
        setLoadingUploadThumbnail(false);
      } catch (err) {
        setErrorImgThumbnail(err.message);
        setLoadingUploadThumbnail(false);
      }
    }
  };
  // useEffect(() => {
  //   if (compressedImageThumbnail) {
  //     setThumbnailImg(compressedImageThumbnail);
  //     setPreviewThumbnail(URL.createObjectURL(compressedImageThumbnail));
  //   }
  // }, [compressedImageThumbnail, thumbnailImg]);
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setOpenModal(!openModal);
  };
  const dataSpecification = useMemo(() => {
    return [
      {
        label: "Jumlah/Posisi Silinder",
        value: dataSpecEngine?.position_cilinder || "",
      },
      {
        label: "Kapasitas Mesin",
        value: dataSpecEngine?.engine_capacity || "",
      },
      {
        label: "DIameter X Langkah",
        value: dataSpecEngine?.diameter || "",
      },
      {
        label: "Perbandingan Kompresi",
        value: dataSpecEngine?.compression || "",
      },
      {
        label: "Daya Maksimum",
        value: dataSpecEngine?.power_maximum || "",
      },
      {
        label: "Torsi Maksimum",
        value: dataSpecEngine?.torque_maximum || "",
      },
      {
        label: "Sistem Starter",
        value: dataSpecEngine?.system_starter || "",
      },
      {
        label: "Sistem Pelumanasan",
        value: dataSpecEngine?.system_oil || "",
      },
      {
        label: "Kapasitas Oli Mesin",
        value: dataSpecEngine?.oil_capacity || "",
      },
      {
        label: "Sistem Bahan Bakar",
        value: dataSpecEngine?.system_fuel || "",
      },
      {
        label: "Tipe Kopling",
        value: dataSpecEngine?.type_clucth || "",
      },
      {
        label: "Tipe Transimsi",
        value: dataSpecEngine?.type_tranmission || "",
      },
      {
        label: "Tipe Mesin",
        value: dataSpecEngine?.type_engine || "",
      },
    ];
  }, [dataSpecEngine]);
  const dataFrame = useMemo(() => {
    return [
      {
        label: "Tipe Rangka",
        value: dataSpecFrame?.type_frame,
      },
      {
        label: "Suspensi Depan",
        value: dataSpecFrame?.front_suspension,
      },
      {
        label: "Suspensi Belakang",
        value: dataSpecFrame?.rear_suspension,
      },
      {
        label: "Ban Belakang",
        value: dataSpecFrame?.rear_tire,
      },
      {
        label: "Rem Depan",
        value: dataSpecFrame?.front_brake,
      },
      {
        label: "Rem Belakang",
        value: dataSpecFrame?.rear_brake,
      },
    ];
  }, [dataSpecFrame]);
  const dataDimension = useMemo(() => {
    return [
      {
        label: "Panjang",
        value: dataSpecDimensions?.length || "",
      },
      {
        label: "Lebar",
        value: dataSpecDimensions?.width || "",
      },
      {
        label: "Tinggi",
        value: dataSpecDimensions?.height || "",
      },
      {
        label: "Jarak Sumbu Roda",
        value: dataSpecDimensions?.wheelbase || "",
      },
      {
        label: "Jarak Terendah ke Tanah",
        value: dataSpecDimensions?.ground_clearance || "",
      },
      {
        label: "Tinggi Tempat Duduk",
        value: dataSpecDimensions?.seat_height || "",
      },
      {
        label: "Berat Isi",
        value: dataSpecDimensions?.curb_weight || "",
      },
      {
        label: "Kapasitas Tangki Bensin",
        value: dataSpecDimensions?.fuel_tank_capacity || "",
      },
    ];
  }, [dataSpecDimensions]);
  const dataElectrical = useMemo(() => {
    return [
      {
        label: "Sistem Pengapian",
        value: dataSpecElectrical?.ignition_system,
      },
      {
        label: "Battery",
        value: dataSpecElectrical?.battery,
      },
      {
        label: "Tipe Busi",
        value: dataSpecElectrical?.spark_plug_type,
      },
    ];
  }, [dataSpecElectrical]);
  const [isErrorPost, setIsErrorPost] = useState(false);
  const [isSuccessCreate, setIsSuccessCreate] = useState(false);
  const handlePostMotor = useCallback(
    async (e) => {
      e?.preventDefault();
      try {
        setIsLoadingCreate(true);
        const formData = new FormData();
        // THUMBNAIL
        formData.append("motor[name]", dataAddMotor?.name || "");
        formData.append("motor[description]", dataAddMotor?.description || "");
        formData.append("motor[cc]", dataAddMotor?.cc || "");
        formData.append("motor[year]", dataAddMotor?.year || "");
        formData.append("motor[categoryID]", categoryID || "");
        formData.append("files", thumbnailImg || "");

        for (let i = 0; i < variants?.length; i++) {
          // VARIANT
          formData.append(`variant[${i}][name]`, variants[i]?.name || "");
          formData.append(`variant[${i}][price]`, variants[i]?.price || "");
          for (let j = 0; j < variants[i]?.variantColors?.length; j++) {
            formData.append(
              `variant[${i}][color][${j}][name]`,
              variants[i]?.variantColors[j]?.name || ""
            );
            formData.append(
              `variant[${i}][color][${j}][code]`,
              variants[i]?.variantColors[j]?.code || ""
            );
            formData.append(
              `files`,
              variants[i]?.variantColors[j]?.picture || ""
            );
          }
        }

        for (let i = 0; i < addFeatures?.length; i++) {
          // FITUR
          const feature = addFeatures[i];
          formData.append(`features[${i}][title]`, feature?.title || "");
          formData.append(
            `features[${i}][description]`,
            feature?.description || ""
          );
          formData.append(`files`, addFeatures[i]?.image || "");
        }
        for (let i = 0; i < dataSpecification?.length; i++) {
          // SPEC
          const data = dataSpecification[i];
          formData.append(`spesifications[${i}][label]`, data?.label || "");
          formData.append(`spesifications[${i}][value]`, data?.value || "");
        }
        for (let i = 0; i < dataFrame.length; i++) {
          // FRAME
          const data = dataFrame[i];
          formData.append(`frames[${i}][label]`, data?.label || "");
          formData.append(`frames[${i}][value]`, data?.value || "");
        }
        for (let i = 0; i < dataDimension.length; i++) {
          // DIMENSI
          const data = dataDimension[i];
          formData.append(`dimensions[${i}][label]`, data?.label || "");
          formData.append(`dimensions[${i}][value]`, data?.value || "");
        }
        for (let i = 0; i < dataElectrical.length; i++) {
          // ELECTRIC
          const data = dataElectrical[i];
          formData.append(`electricities[${i}][label]`, data?.label || "");
          formData.append(`electricities[${i}][value]`, data?.value || "");
        }
        for (let i = 0; i < link?.length; i++) {
          // LINK
          formData.append(`videos[${i}][url]`, link[i]?.link_url || "");
        }
        formData.append("mediaTypes", "IMAGE");
        formData.append("file[name]", "motor");
        const response = await createMotor(formData);
        console.log(response);
        if (response?.code === 200) {
          handleResetForm();
          setIsLoadingCreate(false);
          setIsSuccessCreate(true);
        }
      } catch (error) {
        setIsLoadingCreate(false);
        setIsErrorPost(true);
        console.log(error);
      }
    },
    [
      addFeatures,
      categoryID,
      dataAddMotor?.cc,
      dataAddMotor?.description,
      dataAddMotor?.name,
      dataAddMotor?.year,
      dataDimension,
      dataElectrical,
      dataFrame,
      dataSpecification,
      handleResetForm,
      link,
      thumbnailImg,
      variants,
    ]
  );
  const handleChangeCategory = (e) => {
    setIsCategoryItems(e);
    if (e) {
      setIsErrorField((prevErrors) => ({
        ...prevErrors,
        categoryID: false,
      }));
    }
  };

  const [isErrorField, setIsErrorField] = useState({
    name: false,
    description: false,
    cc: false,
    year: false,
    imgThumbnail: false,
    categoryID: false,
    nameVariant: [false],
    priceVariant: [false],
    nameVariantColors: [[false]],
    codeVariantColors: [[false]],
    imageVariantColors: [[false]],
    titleFeature: [false],
    descriptionFeature: [false],
    imageFeature: [false],
  });
  // const handleSubmitValidate = useCallback(() => {
  //   const variantsMap = variants?.map((v) => ({
  //     name: !v?.name,
  //     price: !v?.price,
  //     nameVariantColors: v?.variantColors?.map((c) => !c?.name),
  //     codeVariantColors: v?.variantColors?.map((c) => !c?.code),
  //     imageVariantColors: v?.variantColors?.map((c) => !c?.picture),
  //   }));
  //   const featuresMap = addFeatures?.map((f) => ({
  //     titleFeature: !f?.title,
  //     descriptionFeature: !f?.description,
  //     imageFeature: !f?.image,
  //   }));
  //   const errors = {
  //     name: !dataAddMotor?.name,
  //     cc: !dataAddMotor?.cc,
  //     year: !dataAddMotor?.year,
  //     imgThumbnail: !thumbnailImg,
  //     categoryID: !categoryID,
  //     nameVariant: variantsMap?.map((v) => v?.name),
  //     priceVariant: variantsMap?.map((v) => v?.price),
  //     nameVariantColors: variantsMap?.map((v) => v?.nameVariantColors),
  //     codeVariantColors: variantsMap?.map((v) => v?.codeVariantColors),
  //     imageVariantColors: variantsMap?.map((v) => v?.imageVariantColors),
  //     titleFeature: featuresMap?.map((f) => f?.titleFeature),
  //     descriptionFeature: featuresMap?.map((f) => f?.descriptionFeature),
  //     imageFeature: featuresMap?.map((f) => f?.imageFeature),
  //   };

  //   setIsErrorField(errors);
  //   return Object.values(errors)?.some((error) =>
  //     Array.isArray(error)
  //       ? error?.some((e) => (Array.isArray(e) ? e?.some((ee) => ee) : e))
  //       : error
  //   );
  // }, [
  //   addFeatures,
  //   categoryID,
  //   dataAddMotor?.cc,
  //   dataAddMotor?.name,
  //   dataAddMotor?.year,
  //   thumbnailImg,
  //   variants,
  // ]);
  const handleSubmitValidate = useCallback(() => {
    const variantsMap = variants?.map((v) => ({
      name: !v?.name,
      price: !v?.price,
      nameVariantColors: v?.variantColors?.map((c) => !c?.name),
      codeVariantColors: v?.variantColors?.map((c) => !c?.code),
      imageVariantColors: v?.variantColors?.map((c) => !c?.picture),
    }));
    const featuresMap = addFeatures?.map((f) => ({
      titleFeature: !f?.title,
      descriptionFeature: !f?.description,
      imageFeature: !f?.image,
    }));
    const errors = {
      name: !dataAddMotor?.name,
      description: !dataAddMotor?.description,
      cc: !dataAddMotor?.cc,
      year: !dataAddMotor?.year,
      imgThumbnail: !thumbnailImg,
      categoryID: !categoryID,
      nameVariant: variantsMap?.map((v) => v?.name),
      priceVariant: variantsMap?.map((v) => v?.price),
      nameVariantColors: variantsMap?.map((v) => v?.nameVariantColors),
      codeVariantColors: variantsMap?.map((v) => v?.codeVariantColors),
      imageVariantColors: variantsMap?.map((v) => v?.imageVariantColors),
      titleFeature: featuresMap?.map((f) => f?.titleFeature),
      descriptionFeature: featuresMap?.map((f) => f?.descriptionFeature),
      imageFeature: featuresMap?.map((f) => f?.imageFeature),
    };

    setIsErrorField(errors);
    return {
      hasError: Object.values(errors)?.some((error) =>
        Array.isArray(error)
          ? error?.some((e) => (Array.isArray(e) ? e?.some((ee) => ee) : e))
          : error
      ),
      errorSection: {
        thumbnail: errors.imgThumbnail,
        variant: variantsMap?.some(
          (v) =>
            v.name ||
            v.price ||
            v.nameVariantColors.some((c) => c) ||
            v.codeVariantColors.some((c) => c) ||
            v.imageVariantColors.some((c) => c)
        ),
        feature: featuresMap?.some(
          (f) => f.titleFeature || f.descriptionFeature || f.imageFeature
        ),
      },
    };
  }, [
    addFeatures,
    categoryID,
    dataAddMotor?.cc,
    dataAddMotor?.description,
    dataAddMotor?.name,
    dataAddMotor?.year,
    thumbnailImg,
    variants,
  ]);

  const reffEmptyThumbnail = useRef(null);
  const reffEmptyVariant = useRef(null);
  const reffEmptyFeature = useRef(null);

  // const handleSubmit = useCallback(() => {
  //   const hasError = handleSubmitValidate();
  //   if (hasError) {
  //     reffEmptyThumbnail.current.scrollIntoView({
  //       top: 0,
  //       behavior: "smooth",
  //     });
  //     console.log("some fields empty or null");
  //   } else {
  //     handlePostMotor();
  //   }
  // }, [handlePostMotor, handleSubmitValidate]);
  const handleSubmit = useCallback(() => {
    const { hasError, errorSection } = handleSubmitValidate();
    if (hasError) {
      if (errorSection.thumbnail) {
        reffEmptyThumbnail.current.scrollIntoView({
          behavior: "smooth",
        });
      } else if (errorSection.variant) {
        reffEmptyVariant.current.scrollIntoView({
          behavior: "smooth",
        });
      } else if (errorSection.feature) {
        reffEmptyFeature.current.scrollIntoView({
          behavior: "smooth",
        });
      }
      console.log("some fields empty or null");
    } else {
      handlePostMotor();
    }
  }, [handlePostMotor, handleSubmitValidate]);

  // END FUNCTION

  return (
    <div className={Styles.body}>
      {isLoadingCreate && (
        <Modal>
          <div className={Styles.loadingPost}>
            <Lottie animationData={loadPost} loop className={Styles.lottie} />
            <span className={Styles.alert}>Sedang Menyimpan Data</span>
            <p className={Styles.description}>
              Mohon jangan tutup halaman ini, sistem sedang melakukan
              penyimpanan data.
            </p>
          </div>
        </Modal>
      )}
      {openModal && (
        <Modal
          handleDone={handleOpenModal}
          style={{ width: "100%", maxWidth: "469px" }}
        >
          <div className={Styles.wrapperModalReset}>
            <div className={Styles.contain}>
              <img
                src={ImgQuestion}
                alt="icon-question"
                className={Styles.iconQuestion}
              />
              <h1>Hapus Data?</h1>
              <p>
                Apakah kamu yakin ingin menghapus seluruh data yang telah diisi?
                Data yang telah dihapus tidak dapat dikembalikan
              </p>
            </div>
            <div className={Styles.wrapperButton}>
              <div
                className={Styles.buttonModal}
                onClick={() => handleOpenModal()}
              >
                <div className={Styles.btn}>Batalkan</div>
              </div>
              <div
                className={Styles.buttonModal}
                onClick={() => {
                  handleOpenModal();
                  handleResetForm();
                }}
              >
                <div className={Styles.btn}>Ya, Hapus Data</div>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {isErrorPost && (
        <Modal handleDone={setIsErrorPost} style={{ width: "469px" }}>
          <div className={Styles.wrapperModalError}>
            <div className={Styles.containError}>
              <img src={errorImgIcon} alt="error" />
              <h1>Oppss...!</h1>
              <p>Terjadi kesahalahan, data tidak dapat disimpan</p>
            </div>
            <div
              className={Styles.btnModalError}
              onClick={() => setIsErrorPost(false)}
            >
              <span>Tutup</span>
            </div>
          </div>
        </Modal>
      )}
      {isSuccessCreate && (
        <Modal handleDone={setIsSuccessCreate} style={{ width: "469px" }}>
          <div className={Styles.wrapperModalError}>
            <div className={Styles.containError}>
              <img src={succesImgIcon} alt="error" />
              <h1>Motor Berhasil Disimpan</h1>
              <p>
                Data motor berhasil dimasukan, silahkan cek pada daftar motor{" "}
              </p>
            </div>
            <div
              className={Styles.btnModalError}
              onClick={() => setIsSuccessCreate(false)}
            >
              <span>Tutup</span>
            </div>
          </div>
        </Modal>
      )}
      <NavbarAdmin
        icon={"motoradmin"}
        size={32}
        color={"#FFFFFF"}
        label={"Motor"}
        isHiddenSearchBar
      />
      <section className={Styles.mainContent}>
        <div className={Styles.header} is-sticky={"true"}>
          <div className={Styles.backHeader}>
            <span onClick={() => navigate("/admin/motor")}>
              <Icon icon={"panahkanan"} className={Styles.iconBack} />
            </span>
            <h1>Detail Motor</h1>
          </div>
          <div className={Styles.buttonCta}>
            <button
              disabled={isLoadingCreate}
              is-disabled={isLoadingCreate?.toString()}
              onClick={() => handleOpenModal()}
            >
              <span>Hapus semua data</span>
            </button>
            <button
              disabled={isLoadingCreate}
              is-disabled={isLoadingCreate?.toString()}
              onClick={() => handleSubmit()}
            >
              <span>Simpan</span>
            </button>
          </div>
        </div>
        <div className={Styles.formDetailMotor} ref={reffEmptyThumbnail}>
          <h2>Detail Motor</h2>
          <div className={Styles.thumbnailForm}>
            <div className={Styles.uploadDetail}>
              <span>
                Thumbnail <span>*</span>
              </span>
              <div
                className={Styles.wrapperUpload}
                is-field-image={Boolean(previewThumbnail).toString()}
                is-error={Boolean(errorImgThumbnail)?.toString()}
                is-empty={
                  Boolean(!previewThumbnail) && isErrorField?.imgThumbnail
                    ? "true"
                    : "false"
                }
              >
                <input
                  type="file"
                  accept=".jpg,.png"
                  ref={refImgThumbnail}
                  hidden
                  style={{ display: "none" }}
                  onChange={handleFileChangeValidate}
                />
                {!previewThumbnail ? (
                  <>
                    <div
                      className={Styles.btnUpload}
                      onClick={() => handleReffClick()}
                    >
                      <Icon icon={"download"} className={Styles.icon} />
                      <span>Upload</span>
                    </div>
                    <div className={Styles.description}>
                      <span>
                        Taruh dan lepas <span>gambar</span> disini
                      </span>
                      <p is-error={Boolean(errorImgThumbnail)?.toString()}>
                        Upload gambar dalam format PNG atau JPG.
                      </p>
                    </div>
                  </>
                ) : loadingUploadThumbnail ? (
                  <Spinner />
                ) : (
                  <img
                    src={previewThumbnail}
                    alt="motor"
                    onClick={() => handleReffClick()}
                  />
                )}
              </div>
            </div>
            <div className={Styles.inputDetail}>
              <div className={Styles.labelInput}>
                <label htmlFor="">
                  Nama Motor <span>*</span>{" "}
                </label>
                <InputForm
                  placeholder="Nama Motor"
                  name="name"
                  onChange={handleChangeInput}
                  value={dataAddMotor?.name || ""}
                  isError={isErrorField?.name}
                  textError="Nama Motor belum di isi"
                />
              </div>
              <div className={Styles.textArea}>
                <label htmlFor="">
                  Deskripsi <span>*</span>
                </label>
                <div className={Styles.wrapTextArea}>
                  <textarea
                    placeholder="Tuliskan deskripsi motor disini"
                    name="description"
                    value={dataAddMotor?.description || ""}
                    onChange={handleChangeInput}
                    is-error={isErrorField?.description ? "true" : "false"}
                  />
                  {isErrorField?.description && (
                    <>
                      <img
                        src={ErrorInfo}
                        alt="error-badge"
                        className={Styles.errorInfo}
                      />
                      <img
                        src={Polygon}
                        alt="icon"
                        className={Styles.popover}
                      />
                      <span className={Styles.errorDesc}>
                        Deskripsi belum di isi
                      </span>
                    </>
                  )}
                </div>
              </div>
              <div className={Styles.labelInput}>
                <label htmlFor="">
                  CC <span>*</span>{" "}
                </label>
                <InputForm
                  placeholder="Tulis kecapatan dalam CC"
                  name="cc"
                  onChange={handleChangeInput}
                  value={dataAddMotor?.cc || ""}
                  isError={isErrorField?.cc}
                  textError="CC belum di isi"
                />
              </div>
              <div className={Styles.labelInput}>
                <label htmlFor="">
                  Tahun Motor <span>*</span>{" "}
                </label>
                <InputForm
                  placeholder="Tulis Tahun Keluaran Motor"
                  name="year"
                  onChange={handleChangeInput}
                  value={dataAddMotor?.year || ""}
                  isError={isErrorField?.year}
                  textError="Tahun Motor belum di isi"
                />
              </div>
              <div className={Styles.labelInput}>
                <label htmlFor="">
                  Kategori Motor <span>*</span>{" "}
                </label>
                <InputForm
                  type="dropdown"
                  dataMapDropdown={categoryItemsDropdown}
                  placeholder="Belum Dipilih"
                  value={categoryItems || ""}
                  onChange={(e) => handleChangeCategory(e)}
                  name="categoryID"
                  isError={isErrorField?.categoryID}
                  textError="Kategori Belum Dipilih"
                />
              </div>
            </div>
          </div>
        </div>
        <div className={`${Styles.divider}`} ref={reffEmptyVariant}></div>
        {variants?.map((el, idx) => (
          <Variant
            key={`render-add-variant-${el?.id}`}
            onClick={() => {
              return variants?.length > 1
                ? handleRemoveVariantComponent(el?.id)
                : () => {};
            }}
            onClickAddColor={() => onClickAddColor(el?.id)}
            idx={idx}
            variant={el}
            setVariant={(id, name, value) => {
              if (value) {
                setIsErrorField((prevErrors) => ({
                  ...prevErrors,
                  [`${name}Variant`]: prevErrors[`${name}Variant`]?.map(
                    (err, i) => (i === idx ? false : err)
                  ),
                }));
              }

              setVariants(
                variants?.map((obj) => {
                  if (obj?.id === id) {
                    if (name === "price") {
                      const numericValue = value?.replace(/[^\d]/g, "");
                      if (/^\d*$/.test(numericValue)) {
                        return {
                          ...obj,
                          [name]: numericValue,
                        };
                      } else {
                        return obj;
                      }
                    } else {
                      return {
                        ...obj,
                        [name]: value,
                      };
                    }
                  }
                  return obj;
                })
              );
            }}
            previewColor={imgPreviewColor}
            setPreviewColor={setImgPreviewColor}
            dataVariant={variants}
            isReset={isResetFeature}
            isErrorField={isErrorField}
            indexVariant={idx}
            setIsErrorField={setIsErrorField}
          />
        ))}
        <div
          className={Styles.addBtnVariant}
          onClick={() => {
            setVariants([
              ...variants,
              {
                id: new Date()?.getTime(),
                name: "",
                price: "",
                variantColors: [
                  {
                    id: new Date()?.getTime(),
                    name: "",
                    code: "",
                    picture: "",
                  },
                ],
              },
            ]);
            setIsErrorField((prevErrors) => ({
              ...prevErrors,
              nameVariant: [...prevErrors?.nameVariant, false],
              priceVariant: [...prevErrors?.priceVariant, false],
              nameVariantColors: [...prevErrors?.nameVariantColors, [false]],
              codeVariantColors: [...prevErrors?.codeVariantColors, [false]],
              imageVariantColors: [...prevErrors?.imageVariantColors, [false]],
            }));
          }}
        >
          <Icon icon={"plus"} className={Styles.icon} />
          <span>Tambah Variant</span>
        </div>
        <div className={Styles.divider} ref={reffEmptyFeature}></div>
        {addFeatures?.map((el, idx) => (
          <Feature
            key={`add-feature-${el?.id}`}
            idx={idx}
            onClickRemoveAddFeature={() => handleRemoveAddFeature(el?.id)}
            value={el}
            features={addFeatures}
            setValue={(id, name, value) => {
              if (value) {
                setIsErrorField((prevErrors) => ({
                  ...prevErrors,
                  [`${name}Feature`]: prevErrors[`${name}Feature`]?.map(
                    (err, i) => (i === idx ? false : err)
                  ),
                }));
              }
              setAddFeatures(
                addFeatures?.map((obj) => {
                  if (obj?.id === id) {
                    return {
                      ...obj,
                      [name]: value,
                    };
                  }
                  return obj;
                })
              );
            }}
            isReset={isResetFeature}
            isErrorField={isErrorField}
          />
        ))}
        <div
          className={Styles.addBtnVariant}
          onClick={() =>
            setAddFeatures([
              ...addFeatures,
              {
                id: new Date()?.getTime(),
                title: "",
                description: "",
                image: "",
              },
            ])
          }
        >
          <Icon icon={"plus"} className={Styles.icon} />
          <span>Tambah Fitur</span>
        </div>
        <div className={Styles.divider}></div>
        <div className={Styles.inputSpecification}>
          <h3>Spesifikasi Mesin</h3>
          <div className={Styles.divider}></div>
          <div className={Styles.input}>
            <label htmlFor="">Jumlah/Posisi Silinder</label>
            <InputForm
              placeholder="Jumlah/Posisi Silinder"
              name="position_cilinder"
              value={dataSpecEngine?.position_cilinder || ""}
              onChange={handleChangeSpecEngine}
            />
          </div>
          <div className={Styles.input}>
            <label htmlFor="">Kapasitas Mesin</label>
            <InputForm
              placeholder="Kapasitas Mesin"
              name="engine_capacity"
              value={dataSpecEngine?.engine_capacity || ""}
              onChange={handleChangeSpecEngine}
            />
          </div>{" "}
          <div className={Styles.input}>
            <label htmlFor="">DIameter X Langkah</label>
            <InputForm
              placeholder="DIameter X Langkah"
              name="diameter"
              value={dataSpecEngine?.diameter || ""}
              onChange={handleChangeSpecEngine}
            />
          </div>
          <div className={Styles.input}>
            <label htmlFor="">Perbandingan Kompresi</label>
            <InputForm
              placeholder="Perbandingan Kompresi"
              name="compression"
              value={dataSpecEngine?.compression || ""}
              onChange={handleChangeSpecEngine}
            />
          </div>
          <div className={Styles.input}>
            <label htmlFor="">Daya Maksimum</label>
            <InputForm
              placeholder="Daya Maksimum"
              name="power_maximum"
              value={dataSpecEngine?.power_maximum || ""}
              onChange={handleChangeSpecEngine}
            />
          </div>
          <div className={Styles.input}>
            <label htmlFor="">Torsi Maksimum</label>
            <InputForm
              placeholder="Torsi Maksimum"
              name="torque_maximum"
              value={dataSpecEngine?.torque_maximum || ""}
              onChange={handleChangeSpecEngine}
            />
          </div>
          <div className={Styles.input}>
            <label htmlFor="">Sistem Starter</label>
            <InputForm
              placeholder="Sistem Starter"
              name="system_starter"
              value={dataSpecEngine?.system_starter || ""}
              onChange={handleChangeSpecEngine}
            />
          </div>
          <div className={Styles.input}>
            <label htmlFor="">Sistem Pelumanasan</label>
            <InputForm
              placeholder="Sistem Pelumanasan"
              name="system_oil"
              value={dataSpecEngine?.system_oil || ""}
              onChange={handleChangeSpecEngine}
            />
          </div>
          <div className={Styles.input}>
            <label htmlFor="">Kapasitas Oli Mesin</label>
            <InputForm
              placeholder="Kapasitas Oli Mesin"
              name="oil_capacity"
              value={dataSpecEngine?.oil_capacity || ""}
              onChange={handleChangeSpecEngine}
            />
          </div>
          <div className={Styles.input}>
            <label htmlFor="">Sistem Bahan Bakar</label>
            <InputForm
              placeholder="Sistem Bahan Bakar"
              name="system_fuel"
              value={dataSpecEngine?.system_fuel || ""}
              onChange={handleChangeSpecEngine}
            />
          </div>
          <div className={Styles.input}>
            <label htmlFor="">Tipe Kopling</label>
            <InputForm
              placeholder="Tipe Kopling"
              name="type_clucth"
              value={dataSpecEngine?.type_clucth || ""}
              onChange={handleChangeSpecEngine}
            />
          </div>
          <div className={Styles.input}>
            <label htmlFor="">Tipe Transimsi</label>
            <InputForm
              placeholder="Tipe Transimsi"
              name="type_tranmission"
              value={dataSpecEngine?.type_tranmission || ""}
              onChange={handleChangeSpecEngine}
            />
          </div>
          <div className={Styles.input}>
            <label htmlFor="">Tipe Mesin</label>
            <InputForm
              placeholder="Tipe Mesin"
              name="type_engine"
              value={dataSpecEngine?.type_engine || ""}
              onChange={handleChangeSpecEngine}
            />
          </div>
        </div>
        <div className={Styles.inputSpecification}>
          <h3>Rangka</h3>
          <div className={Styles.divider}></div>
          <div className={Styles.input}>
            <label>Tipe Rangka</label>
            <InputForm
              placeholder="Tipe Rangka"
              name="type_frame"
              value={dataSpecFrame?.type_frame || ""}
              onChange={handleChangeSpecFrame}
            />
          </div>
          <div className={Styles.input}>
            <label>Suspensi Depan</label>
            <InputForm
              placeholder="Suspensi Depan"
              name="front_suspension"
              value={dataSpecFrame?.front_suspension || ""}
              onChange={handleChangeSpecFrame}
            />
          </div>
          <div className={Styles.input}>
            <label>Suspensi Belakang</label>
            <InputForm
              placeholder="Suspensi Belakang"
              name="rear_suspension"
              value={dataSpecFrame?.rear_suspension || ""}
              onChange={handleChangeSpecFrame}
            />
          </div>
          <div className={Styles.input}>
            <label>Ban Belakang</label>
            <InputForm
              placeholder="Ban Belakang"
              name="rear_tire"
              value={dataSpecFrame?.rear_tire || ""}
              onChange={handleChangeSpecFrame}
            />
          </div>
          <div className={Styles.input}>
            <label>Rem Depan</label>
            <InputForm
              placeholder="Rem Depan"
              name="front_brake"
              value={dataSpecFrame?.front_brake || ""}
              onChange={handleChangeSpecFrame}
            />
          </div>
          <div className={Styles.input}>
            <label>Rem Belakang</label>
            <InputForm
              placeholder="Rem Belakang"
              name="rear_brake"
              value={dataSpecFrame?.rear_brake || ""}
              onChange={handleChangeSpecFrame}
            />
          </div>
        </div>
        <div className={Styles.inputSpecification}>
          <h3>Dimensi</h3>
          <div className={Styles.divider}></div>
          <div className={Styles.threeRow}>
            <div className={Styles.input}>
              <label>Panjang</label>
              <InputForm
                placeholder="Panjang"
                name="length"
                value={dataSpecDimensions?.length || ""}
                onChange={handleChangeSpecDimensions}
              />
            </div>
            <div className={Styles.input}>
              <label>Lebar</label>
              <InputForm
                placeholder="Lebar"
                name="width"
                value={dataSpecDimensions?.width || ""}
                onChange={handleChangeSpecDimensions}
              />
            </div>
            <div className={Styles.input}>
              <label>Tinggi</label>
              <InputForm
                placeholder="Tinggi"
                name="height"
                value={dataSpecDimensions?.height || ""}
                onChange={handleChangeSpecDimensions}
              />
            </div>
          </div>
          <div className={Styles.input}>
            <label>Jarak Sumbu Roda</label>
            <InputForm
              placeholder="Jarak Sumbu Roda"
              name="wheelbase"
              value={dataSpecDimensions?.wheelbase || ""}
              onChange={handleChangeSpecDimensions}
            />
          </div>
          <div className={Styles.input}>
            <label>Jarak Terendah ke Tanah</label>
            <InputForm
              placeholder="Jarak Terendah ke Tanah"
              name="ground_clearance"
              value={dataSpecDimensions?.ground_clearance || ""}
              onChange={handleChangeSpecDimensions}
            />
          </div>
          <div className={Styles.input}>
            <label>Tinggi Tempat Duduk</label>
            <InputForm
              placeholder="Tinggi Tempat Duduk"
              name="seat_height"
              value={dataSpecDimensions?.seat_height || ""}
              onChange={handleChangeSpecDimensions}
            />
          </div>
          <div className={Styles.input}>
            <label>Berat Isi</label>
            <InputForm
              placeholder="Berat Isi"
              name="curb_weight"
              value={dataSpecDimensions?.curb_weight || ""}
              onChange={handleChangeSpecDimensions}
            />
          </div>
          <div className={Styles.input}>
            <label>Kapasitas Tangki Bensin</label>
            <InputForm
              placeholder="Kapasitas Tangki Bensin"
              name="fuel_tank_capacity"
              value={dataSpecDimensions?.fuel_tank_capacity || ""}
              onChange={handleChangeSpecDimensions}
            />
          </div>
        </div>
        <div className={Styles.inputSpecification}>
          <h3>Kelistrikan</h3>
          <div className={Styles.divider}></div>
          <div className={Styles.input}>
            <label>Sistem Pengapian</label>
            <InputForm
              placeholder="Sistem Pengapian"
              name="ignition_system"
              value={dataSpecElectrical?.ignition_system || ""}
              onChange={handleChangeSpecElectrical}
            />
          </div>
          <div className={Styles.input}>
            <label>Battery</label>
            <InputForm
              placeholder="Battery"
              name="battery"
              value={dataSpecElectrical?.battery || ""}
              onChange={handleChangeSpecElectrical}
            />
          </div>
          <div className={Styles.input}>
            <label>Tipe Busi</label>
            <InputForm
              placeholder="Tipe Busi"
              name="spark_plug_type"
              value={dataSpecElectrical?.spark_plug_type || ""}
              onChange={handleChangeSpecElectrical}
            />
          </div>
        </div>
        <div className={Styles.inputSpecification}>
          <h3>Video</h3>
          <div className={Styles.divider}></div>
          {link?.map((l, idx) => (
            <AddLink
              key={`link-${l?.id}`}
              idx={idx + 1}
              onClickRemoveLink={
                link?.length > 1 ? () => handleRemoveLink(l?.id) : () => {}
              }
              data={l}
              value={link}
              setValue={(id, name, value) => {
                setLink(
                  link?.map((obj) => {
                    if (obj?.id === id) {
                      return {
                        ...obj,
                        [name]: value,
                      };
                    }
                    return obj;
                  })
                );
              }}
            />
          ))}
          <div
            className={Styles.addLink}
            onClick={() =>
              setLink([
                ...link,
                {
                  id: new Date()?.getTime(),
                  link_url: "",
                },
              ])
            }
          >
            <Icon icon={"plus"} className={Styles.icon} />
            <span>Tambah Link</span>
          </div>
        </div>
        {/* <div className={Styles.inputSpecification}>
          <h3>Y-Connect & My Yamaha Motor</h3>
          <div className={Styles.divider}></div>
          <div className={Styles.checkboxConnect}>
            <div className={Styles.flexCheckBox}>
              <input
                type="checkbox"
                onChange={() => setIsShowYConnect(!isShowYConnect)}
                checked={isShowYConnect}
              />
              <span className={Styles.textCheckbox}>
                Tampilkan Section Y-Connect
              </span>
            </div>
            <div className={Styles.flexCheckBox}>
              <input
                type="checkbox"
                onChange={() => setIsShowMyMotor(!isShowMyMotor)}
                checked={isShowMyMotor}
              />
              <span className={Styles.textCheckbox}>
                Tampilkan Section My Yamaha Motor
              </span>
            </div>
          </div>
        </div> */}
      </section>
    </div>
  );
}

const Variant = ({
  onClick,
  onClickAddColor,
  idx,
  variant,
  setVariant = () => {},
  setPreviewColor = () => {},
  previewColor,
  dataVariant = [],
  isReset = false,
  isErrorField,
  indexVariant,
  setIsErrorField = () => {},
}) => {
  return (
    <section className={Styles.varianMotor}>
      <div className={Styles.between}>
        <h2>Variant Motor {idx + 1}</h2>
        {dataVariant?.length > 1 ? (
          <Icon
            icon={"trash"}
            size={"24"}
            className={Styles.icon}
            onClick={onClick}
          />
        ) : (
          ""
        )}
      </div>
      <div className={Styles.divider}></div>

      <div className={Styles.inputDetail}>
        <div className={Styles.labelInput}>
          <label htmlFor="">
            Nama Variant <span>*</span>{" "}
          </label>
          <InputForm
            placeholder={`Nama Variant`}
            name={`name`}
            onChange={(e) => setVariant(variant?.id, "name", e?.target?.value)}
            value={variant?.name || ""}
            isError={isErrorField?.nameVariant[idx]}
            textError={"Nama Variant belum di isi"}
          />
        </div>
        <div className={Styles.rowInput}>
          <div className={Styles.labelInput}>
            <label htmlFor="">
              Harga <span>*</span>{" "}
            </label>
            <InputForm
              placeholder="Rp"
              onChange={(e) =>
                setVariant(variant?.id, "price", e?.target?.value)
              }
              value={formatPrice(variant?.price) || ""}
              isError={isErrorField?.priceVariant[idx]}
              textError={"Harga belum di isi"}
            />
          </div>
        </div>
      </div>

      {variant?.variantColors?.map((el, idx) => (
        <AddColor
          key={`render-add-color-${el?.id}`}
          onDeleteColor={() =>
            variant?.variantColors?.length > 1
              ? setVariant(
                  variant?.id,
                  "variantColors",
                  variant?.variantColors?.filter((c) => c?.id !== el?.id)
                )
              : () => {}
          }
          idx={idx}
          color={el}
          setColor={(id, name, value) => {
            if (value) {
              setIsErrorField((prevErrors) => ({
                ...prevErrors,
                [`${name}VariantColors`]: prevErrors[
                  `${name}VariantColors`
                ]?.map((err, i) =>
                  i === indexVariant
                    ? err?.map((colorErr, j) => (j === idx ? false : colorErr))
                    : err
                ),
              }));
            }
            setVariant(
              variant?.id,
              "variantColors",
              variant?.variantColors?.map((c) => {
                if (c?.id === id) {
                  if (name === "code") {
                    const hexPattern = /^#/;
                    if (hexPattern.test(value)) {
                      return {
                        ...c,
                        [name]: value,
                      };
                    } else {
                      return c;
                    }
                  }
                  return {
                    ...c,
                    [name]: value,
                  };
                }
                return c;
              })
            );
          }}
          setPreview={setPreviewColor}
          preview={previewColor}
          dataColor={variant?.variantColors}
          isReset={isReset}
          isErrorField={isErrorField}
          indexVariant={indexVariant}
        />
      ))}
      <div className={Styles.addColorAndVariant} onClick={onClickAddColor}>
        <Icon icon={"plus"} className={Styles.icon} />
        <span>Tambah Warna & Gambar</span>
      </div>
    </section>
  );
};
const AddColor = ({
  onDeleteColor = () => {},
  idx,
  color,
  setColor,
  dataColor = [],
  isReset,
  isErrorField,
  indexVariant,
}) => {
  // const {
  //   handleImageUpload: handleCompressColor,
  //   compressedImage: compressedImageColor,
  // } = useImageUploader();
  const reffAddColor = useRef();
  const clickRefImg = () => {
    reffAddColor?.current?.click();
  };
  useEffect(() => {
    if (reffAddColor.current) {
      reffAddColor.current.value = "";
    }
  }, [isReset]);
  const [preview, setPreview] = useState();
  const [errorImgColor, setErrorImgColor] = useState("");
  const handleImgColor = async (id, name, value) => {
    setErrorImgColor("");
    const file = value;
    // const optionColor = {
    //   maxSizeMB: 1,
    //   maxWidthOrHeight: 800,
    //   useWebWorker: true,
    // };
    if (file) {
      try {
        // await handleCompressColor(file, 2000, 1000, optionColor);
        setPreview(file);
        setColor(id, name, value);
      } catch (err) {
        setErrorImgColor(err.message);
      }
    }
  };
  useEffect(() => {
    setPreview(null);
  }, [isReset]);
  // useEffect(() => {
  //   setPreview(compressedImageColor);
  // }, [compressedImageColor]);
  return (
    <div className={Styles.addVariant}>
      <div className={Styles.uploadVariant}>
        <div>
          <span>
            Warna & Foto {idx + 1} <span style={{ color: "#d1001c" }}>*</span>
          </span>
          {dataColor?.length > 1 ? (
            <Icon
              icon={"trash"}
              className={Styles.icon}
              onClick={onDeleteColor}
            />
          ) : (
            ""
          )}
        </div>
        <div
          className={Styles.inputUpload}
          is-choose-file={Boolean(preview)?.toString()}
          onClick={() => clickRefImg()}
          is-error={Boolean(errorImgColor)?.toString()}
          is-empty={
            Boolean(!preview) &&
            isErrorField?.imageVariantColors[indexVariant][idx]
              ? "true"
              : "false"
          }
          // style={{ height: "126.5px" }}
        >
          <input
            type="file"
            accept=".png"
            ref={reffAddColor}
            hidden
            style={{ display: "none" }}
            onChange={(e) =>
              handleImgColor(color?.id, "picture", e?.target?.files[0])
            }
            name={`picture`}
          />
          {preview ? (
            <img src={URL.createObjectURL(preview)} alt="color-motor" />
          ) : (
            <>
              <div className={Styles.button}>
                <Icon icon={"download"} className={Styles.icon} />
                <span>Upload</span>
              </div>
              <span>Upload Gambar dalam format PNG.</span>
            </>
          )}
        </div>
      </div>
      <div className={Styles.inputVariant}>
        <div
          className={Styles.labelInput}
          // ref={variantRefs.variants[indexVariant]?.variantColors[idx]?.name}
        >
          <label htmlFor="">
            Nama Warna <span>*</span>{" "}
          </label>
          <InputForm
            placeholder="Contoh: Biru Metalik"
            name={`name`}
            onChange={(e) => setColor(color?.id, `name`, e?.target?.value)}
            value={color?.name || ""}
            isError={isErrorField?.nameVariantColors[indexVariant][idx]}
            textError={"Nama Warna belum di isi"}
          />
        </div>
        <div
          className={Styles.labelInput}
          // ref={variantRefs.variants[indexVariant]?.variantColors[idx]?.code}
        >
          <label htmlFor="">
            Kode Warna <span>*</span>{" "}
          </label>
          <InputForm
            placeholder="Contoh: #E87HS1"
            name={`code`}
            onChange={(e) => setColor(color?.id, "code", e?.target?.value)}
            value={color?.code || ""}
            isError={isErrorField?.codeVariantColors[indexVariant][idx]}
            textError={"Kode Warna belum di isi"}
          />
        </div>
      </div>
    </div>
  );
};
const Feature = ({
  onClickRemoveAddFeature = () => {},
  idx,
  setValue = () => {},
  value,
  features = [],
  isReset,
  isErrorField,
}) => {
  const [previewFeature, setPreviewFeature] = useState(null);
  // const {
  //   handleImageUpload: handleCompressFeature,
  //   compressedImage: compressedImageFeature,
  // } = useImageUploader();
  useEffect(() => {
    setPreviewFeature();
  }, [isReset]);
  const refImgFeature = useRef();
  const handleClick = () => {
    refImgFeature?.current?.click();
  };
  useEffect(() => {
    if (refImgFeature.current) {
      refImgFeature.current.value = "";
    }
  }, [isReset]);
  const [errorImgFeature, setErrorImgFeature] = useState("");
  const handleImgFeature = useCallback(
    async (id, name, value) => {
      // const optionFeature = {
      //   maxSizeMB: 1,
      //   maxWidthOrHeight: 600,
      //   useWebWorker: true,
      // };
      setErrorImgFeature("");
      const file = value;
      if (file) {
        try {
          // const imageValidate = await validateImage(file, 1000, 1000);
          // setPreviewFeature(imageValidate);
          // await handleCompressFeature(file, 1000, 1000, optionFeature);
          setPreviewFeature(file);
          setValue(id, name, value);
        } catch (err) {
          setErrorImgFeature(err.message);
        }
      }
    },
    [setValue]
  );
  useEffect(() => {
    handleImgFeature();
  }, [handleImgFeature, isReset]);
  // useEffect(() => {
  //   setPreviewFeature(compressedImageFeature);
  // }, [compressedImageFeature]);
  return (
    <div className={Styles.wrapperFeature}>
      <div className={Styles.header}>
        <h3>Fitur Motor {idx + 1}</h3>

        {features?.length > 1 ? (
          <Icon
            icon={"trash"}
            className={Styles.icon}
            onClick={() =>
              features?.length > 1 ? onClickRemoveAddFeature() : () => {}
            }
            style={{ cursor: "pointer" }}
          />
        ) : (
          ""
        )}
      </div>
      <div className={Styles.labelInput}>
        <label htmlFor="">
          Judul Fitur <span>*</span>{" "}
        </label>
        <InputForm
          placeholder={`Judul Fitur`}
          name={`title`}
          value={value?.title || ""}
          onChange={(e) => setValue(value?.id, "title", e?.target?.value)}
          isError={isErrorField?.titleFeature[idx]}
          textError={"Judul Fitur belum di isi"}
        />
      </div>
      <div
        className={Styles.inputFeature}
        is-choose-file={Boolean(previewFeature)?.toString()}
      >
        <div className={Styles.uploadPhoto}>
          <span>
            Foto <span style={{ color: "#d1001c" }}>*</span>
          </span>
          <input
            type="file"
            accept=".png"
            ref={refImgFeature}
            hidden
            style={{ display: "none" }}
            onChange={(e) =>
              handleImgFeature(value?.id, "image", e?.target?.files[0])
            }
          />
          <div
            className={Styles.upload}
            onClick={() => handleClick()}
            is-error={Boolean(errorImgFeature)?.toString()}
            is-empty={
              Boolean(!previewFeature) && isErrorField?.imageFeature[idx]
                ? "true"
                : "false"
            }
          >
            {previewFeature ? (
              <img
                src={URL.createObjectURL(previewFeature)}
                alt="color-motor-variant"
              />
            ) : (
              <>
                <div>
                  <Icon icon={"download"} className={Styles.icon} />
                  <span>Upload</span>
                </div>
                <span>Upload Gambar dalam format PNG.</span>
              </>
            )}
          </div>
        </div>
        <div className={Styles.textArea}>
          <label htmlFor="">
            Deskripsi <span>*</span>
          </label>
          <div className={Styles.wrapTextArea}>
            <textarea
              placeholder="Tuliskan deskripsi fitur disini"
              name="description"
              value={value?.description || ""}
              onChange={(e) =>
                setValue(value?.id, "description", e?.target?.value)
              }
              is-error={
                isErrorField?.descriptionFeature[idx] ? "true" : "false"
              }
            />
            {isErrorField?.descriptionFeature[idx] && (
              <>
                <img
                  src={ErrorInfo}
                  alt="error-badge"
                  className={Styles.errorInfo}
                />
                <img src={Polygon} alt="icon" className={Styles.popover} />
                <span className={Styles.errorDesc}>Deskripsi belum di isi</span>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const AddLink = ({
  onClickRemoveLink,
  idx,
  value,
  setValue = () => {},
  data,
}) => {
  return (
    <div className={`${Styles.input} ${Styles.optional}`}>
      <label htmlFor="">
        Link Video {value?.length > 1 ? idx : ""}
        <div>
          <InputForm
            placeholder="Masukkan link video youtube"
            name="link_url"
            value={data?.link_url || ""}
            onChange={(e) => setValue(data?.id, "link_url", e?.target?.value)}
            isError={data?.link_url === "" ? false : data?.isError?.toString()}
            textError={"dimulai dari: https://"}
            type="url"
          />
          {value?.length > 1 ? (
            <Icon
              className={Styles.iconDelete}
              icon={"trash"}
              onClick={onClickRemoveLink}
            />
          ) : (
            ""
          )}
        </div>
      </label>
    </div>
  );
};
