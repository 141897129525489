import Styles from "./styles.module.scss";
import image1 from "../../../assets/Screenshot 2023-02-28 at 10.15.png";
import Icon from "../../Atom/Icon";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import { IconCheck } from "@Atom/svg/IconCheck";

export const AdminPromoCard = ({
  dataPromo,
  clickChange,
  idPromo,
  selectedButton,
  displayPromo,
  addDisplay,
  displayStatus,

  // xaddDisplay2,
  // setDisplayPromo,
}) => {
  const { pathname } = useLocation();

  // const promo = idPromo;
  // console.log(displayPromo, "tes");

  return (
    <section>
      {dataPromo.map((each) => {
        const displayFilter = displayPromo.filter((el) => el === each.id);
        return (
          <>
            {selectedButton === "DetilPromo" ? (
              <>
                <div
                  className={idPromo === each.id ? Styles.xcard : Styles.card}
                  // onClick={() =>
                  //   clickChange(each.id)
                  // }
                  onClick={() =>
                    idPromo === each.id
                      ? idPromo === null
                        ? clickChange(each.id)
                        : clickChange(null)
                      : clickChange(each.id)
                  }
                >
                  <img
                    src={each.photo}
                    // alt=""
                    // style={{
                    //   width: "140px",
                    //   height: "100px",
                    //   borderRadius: "8px",
                    // }}
                    className={Styles.ProductCardImg}
                  />

                  <div className={Styles.titleProductCard}>
                    <div className={Styles.titleProductCardjudul}>
                      {each.name}
                    </div>
                    <div className={Styles.titleProductCardket}>
                      {each.tanggal}
                    </div>
                  </div>
                  {/* {pathname === "/admin/promo-details" ||
                  pathname === `/admin/promo-details/${each.id}` ||
                  pathname === `/admin/promo-details/edit/${each.id}` ? (
                    <Link to={`/admin/promo-details/${each.id}`}>
                      <Icon
                        icon={idPromo === each.id ? "checkmark" : "panahkanan"}
                        size={20}
                        color={"black"}
                        className={Styles.iconPanah}
                      />
                    </Link>
                  ) : ( */}
                  <Link>
                    {idPromo === each.id ? (
                      <IconCheck />
                    ) : (
                      <Icon
                        icon="panahkanan"
                        size={20}
                        color={"black"}
                        className={Styles.iconPanah}
                      />
                    )}
                  </Link>
                  {/* )} */}
                </div>
              </>
            ) : (
              <>
                <div
                  className={
                    // idPromo === each.id ? Styles.xcard : Styles.card
                    displayFilter.length > 0 ? Styles.xcard : Styles.card
                    // displayPromo
                  }
                  onClick={
                    displayStatus === "Edit"
                      ? () => addDisplay(each.id)
                      : () => addDisplay(null)
                  }
                  // onClick={() => addDisplay(each.id)}
                >
                  <img
                    src={each.photo}
                    alt=""
                    style={{
                      width: "140px",
                      height: "100px",
                      borderRadius: "8px",
                    }}
                  />

                  <div className={Styles.titleProductCard}>
                    <h5>{each.name}</h5>
                    <h6>{each.tanggal}</h6>
                  </div>
                  {/* {pathname === "/admin/promo-details" ||
                  pathname === `/admin/promo-details/${each.id}` ||
                  pathname === `/admin/promo-details/edit/${each.id}` ? ( */}
                  {/* <Link to={`/admin/promo-details/${each.id}`}>
                      <Icon
                        icon={
                          displayFilter.length > 0 ? "checkmark" : "panahkanan"
                        }
                        size={20}
                        color={"black"}
                        className={Styles.iconPanah}
                      />
                    </Link>
                  ) : ( */}
                  <Link>
                    {idPromo === each.id ? (
                      <IconCheck />
                    ) : (
                      <Icon
                        icon="panahkanan"
                        size={20}
                        color={"black"}
                        className={Styles.iconPanah}
                      />
                    )}
                  </Link>
                  {/* // checkmark */}
                  {/* )} */}
                </div>
              </>
            )}
          </>
        );
      })}
      {/* <div className={Styles.card}>
        <img
          src={image1}
          alt=""
          style={{
            width: "140px",
            height: "100px",
            borderRadius: "8px",
          }}
        />

        <div className={Styles.titleProductCard}>
          <h5>Yamaha Alfa Scorpii</h5>
          <h6>Created on 10 April 2022</h6>
        </div>
        {pathname === "/admin/promo-details" ||
        pathname === `/admin/promo-details/${1}` ||
        pathname === `/admin/promo-details/edit/${1}` ? (
          <Link to={`/admin/promo-details/${1}`}>
            <Icon
              icon={"panahkanan"}
              size={20}
              color={"black"}
              className={Styles.iconPanah}
            />
          </Link>
        ) : (
          <a>
            <Icon
              icon={"panahkanan"}
              size={20}
              color={"black"}
              className={Styles.iconPanah}
            />
          </a>
        )}
      </div>
      <div className={Styles.card}>
        <img
          src={image1}
          alt=""
          style={{
            width: "140px",
            height: "100px",
            borderRadius: "8px",
          }}
        />

        <div className={Styles.titleProductCard}>
          <h5>Yamaha Alfa Scorpii</h5>
          <h6>Created on 10 April 2022</h6>
        </div>
        {pathname === "/admin/promo-details" ||
        pathname === `/admin/promo-details/${1}` ||
        pathname === `/admin/promo-details/edit/${1}` ? (
          <Link to={`/admin/promo-details/${1}`}>
            <Icon
              icon={"panahkanan"}
              size={20}
              color={"black"}
              className={Styles.iconPanah}
            />
          </Link>
        ) : (
          <a>
            <Icon
              icon={"panahkanan"}
              size={20}
              color={"black"}
              className={Styles.iconPanah}
            />
          </a>
        )}
      </div>
      <div className={Styles.card}>
        <img
          src={image1}
          alt=""
          style={{
            width: "140px",
            height: "100px",
            borderRadius: "8px",
          }}
        />

        <div className={Styles.titleProductCard}>
          <h5>Yamaha Alfa Scorpii</h5>
          <h6>Created on 10 April 2022</h6>
        </div>
        {pathname === "/admin/promo-details" ||
        pathname === `/admin/promo-details/${1}` ||
        pathname === `/admin/promo-details/edit/${1}` ? (
          <Link to={`/admin/promo-details/${1}`}>
            <Icon
              icon={"panahkanan"}
              size={20}
              color={"black"}
              className={Styles.iconPanah}
            />
          </Link>
        ) : (
          <a>
            <Icon
              icon={"panahkanan"}
              size={20}
              color={"black"}
              className={Styles.iconPanah}
            />
          </a>
        )}
      </div> */}
    </section>
  );
};
