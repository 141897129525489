import { createBrowserRouter } from "react-router-dom";
import AdminMotorcycleAdd from "@Views/Admin/MotorcycleAdd";
import AdminSparepartsPage from "@Views/Admin/SparepartsPage";
import AdminServicesPage from "@Views/Admin/ServicesMotorcyclePage";
import AdminSparepartsDetailPage from "@Views/Admin/SparepartsDetailPage";
import AdminSparepartsAddNewPage from "@Views/Admin/SparepartsAddNewPage";
import AdminSparepartsEditPage from "@Views/Admin/SparepartsEditPage";
import AdminAds from "@Views/Admin/Ads";
import AdminPromoPage, {
  loader as getDataListPromo,
} from "@Views/Admin/PromoPage";
import AddPromoPage, { loader as getVariant } from "@Views/Admin/AddPromo";
import DetailPromoPage, {
  loader as getDetailPromo,
} from "@Views/Admin/DetailPromoPage";
import Admin from "@Views/Admin";
import AdminMotorcyclePage, {
  loader as getDataListMotor,
} from "@Views/Admin/MotorcyclePage";
import "moment/locale/id";
import AdminMotorcycleEditPage, {
  loader as getDataDetailMotor,
} from "@Views/Admin/MotorcycleEdit";
const routes = [
  {
    path: "",
    element: <Admin />,
    children: [
      {
        index: true,
        element: <h1>Halaman Dashboard</h1>,
      },
      {
        path: "admin/motor",
        children: [
          {
            index: true,
            element: <AdminMotorcyclePage />,
            loader: getDataListMotor,
          },
          {
            path: "add",
            element: <AdminMotorcycleAdd />,
          },
          {
            path: "edit/:id",
            element: <AdminMotorcycleEditPage />,
            loader: getDataDetailMotor,
          },
        ],
      },
      {
        path: "admin/spareparts",
        children: [
          {
            index: true,
            element: <AdminSparepartsPage />,
          },
          {
            path: "spareparts/detail/:id",
            element: <AdminSparepartsDetailPage />,
          },
          {
            path: "add",
            element: <AdminSparepartsAddNewPage />,
          },
          {
            path: "edit/:id",
            element: <AdminSparepartsEditPage />,
          },
        ],
      },

      {
        path: "servis",
        element: <AdminServicesPage />,
      },
      {
        path: "ads",
        element: <AdminAds />,
      },
      {
        path: "admin/promo",
        children: [
          {
            index: true,
            element: <AdminPromoPage />,
            loader: getDataListPromo,
          },
          {
            path: "add",
            element: <AddPromoPage />,
            loader: getVariant,
          },
          {
            path: "detail/:id",
            element: <DetailPromoPage />,
            loader: getDetailPromo,
          },
        ],
      },
    ],
  },
];

const routeAdmin = createBrowserRouter(routes);
export default routeAdmin;
