import { API } from "@Config/index";

export const getDetailPromo = (id, limit = 10, page = 1, searchQuery) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        searchQuery
          ? `/promo/${id}?limit=${limit}&page=${page}&search=${searchQuery}`
          : `/promo/${id}?limit=${limit}&page=${page}`,
        {
          headers: {
            // Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NCwiZW1wbG95ZWVJZCI6IjIwMTIxMTEyNSIsInJvbGUiOiJBRE1JTiIsImlhdCI6MTcyMTk3NTM2M30.FV43g455wl9DbxeENh4Qp9O-gI9FvKkhDrKqcCJrteQ",
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};
