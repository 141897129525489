import React, { useState } from "react";
import Styles from "./styles.module.scss";
import Icon from "@Atom/Icon";
import Dropdown from "@Atom/DropDown";
import { Link } from "react-router-dom";

export default function TableHeader({
  type,
  labelName,
  labelAdd,
  isAdd = false,
  labelNameDetail,
  isAddSpareparts = false,
  pathname,
  pathname2,
  filterChange,
  textButton,
  resetButton,
  submitButton,
}) {
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedModelCode, setSelectedModelCode] = useState(null);
  const [selectedModelId, setSelectedModelId] = useState(null);
  let option = [
    {
      id: 1,
      name: "Paling baru",
    },
    {
      id: 2,
      name: "Paling lama",
    },
  ];

  if (type === "motorcycle") {
    return (
      <div
        className={Styles.bodyMotorcycle}
        style={{ display: "flex", gap: "2rem" }}
      >
        <div className={Styles.left}>
          <span>{labelName}</span>
          {isAdd ? (
            <Link
              to={pathname}
              className={Styles.add}
              style={pathname !== "admin/promo" && { display: "none" }}
            >
              <div className={Styles.iconBox}>
                <Icon icon={"plus"} size={14} color={"white"} />
              </div>
              <span>{labelAdd}</span>
            </Link>
          ) : null}
        </div>
        {!pathname === "admin/promo" ? (
          <div className={Styles.right}>
            <Dropdown
              options={option}
              value={selectedModel}
              // setValue={setSelectedModel}
              setValue={(e) => {
                setSelectedModel(e);
                filterChange(e);
              }}
              selectedStoreCode={selectedModelCode}
              setSelectedStoreCode={setSelectedModelCode}
              selectedStoreId={selectedModelId}
              setSelectedStoreId={setSelectedModelId}
              placeholder="Urutkan dari: Paling baru"
              autoClose
              name="filterTable"
              type={"motorcycleDropdown"}
            />
          </div>
        ) : (
          <Link
            to={pathname}
            className={Styles.add}
            style={
              pathname === "admin/promo/add"
                ? { display: "none" }
                : { undefined }
            }
            replace={true}
          >
            <div className={Styles.iconBox}>
              <Icon icon={"plus"} size={14} color={"white"} />
            </div>
            <span>{labelAdd}</span>
          </Link>
        )}
      </div>
    );
  }

  if (type === "admin-detail") {
    return (
      <div className={Styles.bodyAdminDetail}>
        <div className={Styles.left}>
          {!pathname ? null : (
            <Link to={pathname}>
              <Icon icon={"arrow-left"} size={24} color={"#212121"} />
            </Link>
          )}

          <span>{labelNameDetail}</span>
        </div>

        {isAddSpareparts ? null : (
          <div className={Styles.right}>
            <div className={Styles.editBox}>
              <Link to={pathname2} className={Styles.linkBox}>
                <Icon icon={"Edit"} size={12} color={"#357abb"} />{" "}
                <span>{textButton}</span>
              </Link>
            </div>
          </div>
        )}
      </div>
    );
  }

  if (type === "admin-edit") {
    return (
      <div className={Styles.bodyAdminDetail}>
        <div className={Styles.left}>
          {!pathname ? null : (
            <Link to={pathname}>
              <Icon icon={"arrow-left"} size={24} color={"#212121"} />
            </Link>
          )}

          <span>{labelNameDetail}</span>
        </div>

        {isAddSpareparts ? null : (
          <div className={Styles.right}>
            {/* <div className={Styles.editBox}> */}
            <div onClick={() => resetButton()} className={Styles.resetButton}>
              Hapus semua data
            </div>
            <div onClick={() => submitButton()} className={Styles.saveButton}>
              Simpan
            </div>
            {/* </div> */}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={Styles.header}>
      <h3>{type}</h3>
    </div>
  );
}
