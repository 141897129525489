import React from "react";
import Styles from "./style.module.scss";
import EmptyImages from "@Assets/Images/empty-data-table.png";
import Icon from "@Atom/Icon";
export default function EmptyHandlerTable({
  onClick = () => {},
  isAddButton = false,
  title = "Belum Ada Produk Di Tambahkan",
  buttonText = "Tambahkan Produk Promo",
}) {
  return (
    <div className={Styles.wrapperEmpty}>
      <img src={EmptyImages} alt="is-empty-images" />
      <h4 dangerouslySetInnerHTML={{ __html: title }} />
      {isAddButton && (
        <div className={Styles.buttonAddPromo} onClick={onClick}>
          <Icon icon={"plus"} color={"#000"} size={"18"} />
          <h6>{buttonText}</h6>
        </div>
      )}
    </div>
  );
}
