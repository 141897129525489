import Styles from "./styles.module.scss";
import Icon from "@Atom/Icon";
import React from "react";

export default function AlertShow({
  statusAlert,
  iconAlert,
  textAlert,
  xshowAlert,
  ...props
}) {
  return (
    <div
      {...props}
      className={Styles.bodyAlert}
      style={{
        position: "fixed",
        transition: "0.3s all ease",
        top: xshowAlert ? "60px" : "-100px",
      }}
    >
      <div
        className={statusAlert === "success" ? Styles.success : Styles.error}
      >
        <Icon icon={iconAlert} size={14} className={Styles.icon} />
        <span>{textAlert}</span>
      </div>
    </div>
  );
}
