import { NavbarAdmin } from "@Molecule/NavbarAdmin";
import Styles from "./styles.module.scss";
import TableHeader from "@Molecule/TableHeader";
import { useEffect, useState } from "react";
import Icon from "@Atom/Icon";
import AlertShow from "@Molecule/Alert";
import CurrencyInput from "react-currency-input-field";
import { Link } from "react-router-dom";
import { SpaRounded } from "@mui/icons-material";

export default function SparepartsAddNew() {
  const [image, setImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");
  // console.log(image);

  const handleFile = (file) => {
    setImage(file);
    setPreviewUrl(URL.createObjectURL(file));
  };

  let fileSize;
  let fileType;
  // let fileDetail;
  if (image) {
    fileType = image.type.split("/");
    fileSize = (image.size / 1048576).toFixed(2);
    // fileDetail =
    //   fileType[0] + ({'<ul><li></li></ul>'})+
    //   // " {<ul><li></li></ul> }" +
    //   fileSize +
    //   " MB - " +
    //   fileType[1];
  }

  const handleOndrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    let imageFile = event.dataTransfer.files[0];
    handleFile(imageFile);
    setDataForm({ ...dataForm, photo: imageFile.name });
    // setDataForm({ photo: imageFile.name });
    // console.log(imageFile);
  };

  const handleOndragOver = (event) => {
    event.preventDefault();
  };

  const [dataForm, setDataForm] = useState({
    name: "",
    stok: "",
    price: "",
    priceDiskon: "",
    promo: "",
    keterangan: "",
    photo: "",
  });

  const onChange = (e) => {
    // e.persist();
    setDataForm({ ...dataForm, [e.target.name]: e.target.value });
    // xsetCatatanOrder(e.target.value.length);
  };

  const removePhoto = () => {
    setPreviewUrl("");
    setDataForm({ ...dataForm, photo: "" });

    // console.log("asdf");
  };

  const [errorsText, setErrorsText] = useState();
  const [errorsRespon, setErrorsRespon] = useState();
  const [showAlert, setShowAlert] = useState(false);

  const handleSubmit = () => {
    validateValues();
    setShowAlert(true);

    let sisa;
    if (dataForm.stok.length > 3) {
      sisa = dataForm.stok.replaceAll(",", "");
    } else {
      sisa = dataForm.stok;
    }

    let harga;
    if (dataForm.price.length > 3) {
      harga = dataForm.price.replaceAll(",", "");
    } else {
      harga = dataForm.price;
    }

    let diskon;
    if (dataForm.priceDiskon.length > 3) {
      diskon = dataForm.priceDiskon.replaceAll(",", "");
    } else {
      diskon = dataForm.priceDiskon;
    }

    // const sisa = dataForm.stok.replaceAll(",", "");
    // const harga = dataForm.price.replaceAll(",", "");
    // const diskon = dataForm.priceDiskon.replaceAll(",", "");
    console.log(
      dataForm.name +
        "-" +
        sisa.replace(/[A-Z][a-z][|&;$%@"<>()+.]/, "").trim() +
        "-" +
        harga.replace(/[A-Z][a-z][|&;$%@"<>()+.]/, "").trim() +
        // dataForm.price +
        "-" +
        diskon.replace(/[A-Z][a-z][|&;$%@"<>()+.]/, "").trim() +
        // dataForm.priceDiskon +
        "-" +
        dataForm.promo +
        "-"
      // vimg
    );
  };

  const validateValues = () => {
    // let errors = {};
    let errors1;
    let errors2;
    let errors3;
    let errors4;
    let errors5;
    let respon;
    if (dataForm.photo === "") {
      errors1 = " Gambar, ";
    } else {
      errors1 = "";
    }
    if (dataForm.name === "") {
      errors2 = " Nama produk, ";
    } else {
      errors2 = "";
    }
    if (dataForm.stok === "") {
      errors3 = " Sisa stok, ";
    } else {
      errors3 = "";
    }
    if (dataForm.price === "") {
      errors4 = " Harga utama, ";
    } else {
      errors4 = "";
    }
    // return errors;

    if (errors1 !== "" || errors2 !== "" || errors3 !== "" || errors4 !== "") {
      errors5 = errors1 + errors2 + errors3 + errors4 + " masih kosong";
      respon = "error";
    } else {
      errors5 = "Proses berhasil";
      respon = "success";
    }
    setErrorsText(errors5);
    setErrorsRespon(respon);
  };
  // console.log(errorsText);
  // const handleAlert = (e) => {
  //   setShowAlert(true);
  // };

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 5000);
  }, [showAlert]);

  function handleOnBrows(event) {
    event.preventDefault();
    event.stopPropagation();
    let ximageFile = event.target.files[0];
    // console.log(ximageFile);
    handleFile(ximageFile);
    setDataForm({ ...dataForm, photo: ximageFile.name });
  }

  // const a = dataForm.price.replace(/[A-Z][a-z][|&;$%@"<>()+.]/, "");
  // const b = a.replace(",", "");
  console.log(dataForm);
  return (
    <div className={Styles.body} id="create-course-form">
      <NavbarAdmin
        icon={"sparepartsadmin"}
        size={32}
        color={"#FFFFFF"}
        label={"Onderdil"}
        // isRoute={true}
      />

      <div className={Styles.box}>
        <TableHeader
          type="admin-detail"
          isAddSpareparts={true}
          labelNameDetail="Tambah Produk Baru"
          pathname={"/admin/spareparts"}
        />
        <div className={Styles.detailBox}>
          <div className={Styles.leftDetail}>
            <span>Foto Produk*</span>
            {previewUrl ? (
              <div className={Styles.showImagesPreview}>
                <img
                  src={previewUrl}
                  alt="preview-url"
                  // Styles={{ width: "80px", height: "50px" }}
                />
                <span>
                  {image.name}
                  <br />
                  <span className={Styles.detailFile}>
                    {fileType[0]}
                    <Icon
                      icon={"i_010-bullet"}
                      size={12}
                      style={{
                        marginBottom: "-2px",
                      }}
                    />
                    {fileSize} Mb
                    <Icon
                      icon={"i_010-bullet"}
                      size={12}
                      style={{
                        marginBottom: "-2px",
                      }}
                    />
                    {fileType[1]}
                  </span>
                </span>
                {/* {console.log(image)} */}
                <div>
                  <Icon
                    icon={"cancel-circle"}
                    size={24}
                    className={Styles.removePhoto}
                    onClick={() => removePhoto()}
                  />
                </div>
              </div>
            ) : (
              <>
                <div
                  className={Styles.inputImages}
                  onDragOver={handleOndragOver}
                  onDrop={handleOndrop}
                >
                  {" "}
                  <input
                    type="file"
                    accept="image/*"
                    className="input-field"
                    onChange={handleOnBrows}
                    id="create-course-form"
                  />
                  <Icon
                    icon={"logoImages"}
                    size={24}
                    className={Styles.iconImages}
                  />
                  <span className={Styles.h4}>
                    Taruh dan lepas <span>gambar</span>
                  </span>
                  <span className={Styles.h5}>
                    atau{" "}
                    <span
                      onClick={() =>
                        document.querySelector(".input-field").click()
                      }
                    >
                      browse files
                    </span>{" "}
                    pada komputer kamu
                  </span>{" "}
                  <p>Dimensi gambar pada ukuran: 3000 x 1500 </p>
                </div>
              </>
            )}
          </div>

          <div className={Styles.rightDetail}>
            <div className={Styles.detail}>
              <span>Nama Produk*</span>
              <input
                type="text"
                name="name"
                placeholder="Oil Filter"
                onChange={onChange}
                // ref={inputFile}
              />
            </div>

            <div className={Styles.detail}>
              <span>Stok tersisa*</span>
              {/* <input
                type="text"
                name="stok"
                placeholder="5"
                onChange={onChange}
                // value={new Intl.NumberFormat().format(dataForm.stok)}
              /> */}
              <CurrencyInput
                id="input-example"
                name="stok"
                placeholder="1"
                defaultValue={0}
                decimalsLimit={0}
                // prefix="Rp. "
                onChange={onChange}
                // ref={money}
              />
            </div>

            <div className={Styles.detail}>
              <span>Harga Utama*</span>
              {/* <input
                type="text"
                name="price"
                placeholder="500000"
                onChange={onChange}
                thousandSeparator={true}
                value={new Intl.NumberFormat().format(dataForm.price)}
                required
              /> */}
              <CurrencyInput
                id="input-example"
                name="price"
                placeholder="1.000.000"
                defaultValue={0}
                decimalsLimit={0}
                prefix="Rp. "
                onChange={onChange}
                // ref={money}
              />
            </div>

            <div className={Styles.detail}>
              <span>Harga Diskon (Opsional)</span>
              {/* <input
                type="text"
                name="priceDiskon"
                placeholder="20000"
                // thousandSeparator={true}
                onChange={onChange}
              /> */}
              <CurrencyInput
                id="input-example"
                name="priceDiskon"
                placeholder="1.000.000"
                defaultValue={0}
                decimalsLimit={0}
                prefix="Rp. "
                onChange={onChange}
                // ref={money}
              />
            </div>
            <div className={Styles.detail}>
              <span>Promo</span>
              <input
                type="text"
                name="promo"
                placeholder="2JT"
                onChange={onChange}
                // ref={inputFile}
              />
            </div>

            <div className={Styles.detail}>
              <span>Keterangan</span>
              <input
                type="text"
                name="keterangan"
                placeholder="Filter oli asli Yamaha adalah"
                onChange={onChange}
                // ref={inputFile}
              />
            </div>
          </div>
        </div>

        <div className={Styles.buttonBox}>
          <div className={Styles.null}></div>
          <div className={Styles.button}>
            <span
              className={Styles.buttonAdd}
              onClick={() => {
                handleSubmit();
                // handleAlert();
              }}
            >
              Tambah
            </span>
            <Link to="/admin/spareparts" className={Styles.buttonCancel}>
              Batalkan
            </Link>
          </div>
        </div>
      </div>
      {/* {showAlert === true ? ( */}
      <div>
        <AlertShow
          xshowAlert={showAlert}
          statusAlert={errorsRespon === "success" ? "success" : "error"}
          iconAlert={errorsRespon === "success" ? "checkmark" : "cancel-circle"}
          textAlert={errorsText}
        />
        {/* ) : (
        ""
      )} */}
      </div>
    </div>
  );
}
