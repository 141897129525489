import Styles from "./styles.module.scss";
import React from "react";
import Icon from "@Atom/Icon";

export default function AlertShowProcess({
  statusAlert,
  iconAlert,
  textAlert,
}) {
  return (
    <div className={Styles.backAlert}>
      {statusAlert === null ? (
        <div className={Styles.bodyAlert}>
          <div className={Styles.iconProsess}></div>
          <div className={Styles.ketProsess}>File sedang diproses</div>
        </div>
      ) : (
        <div className={Styles.bodyAlert}>
          {/* <div
            className={
              statusAlert === "success" ? Styles.iconSuccess : Styles.iconError
            }
          > */}
          <Icon
            icon={iconAlert}
            size={40}
            className={
              statusAlert === "success" ? Styles.iconSuccess : Styles.iconError
            }
          />
          <div className={Styles.ketProsess}>{textAlert}</div>
          {/* {statusAlert} */}
          {/* </div> */}
        </div>
      )}
    </div>
  );
}
