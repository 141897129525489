import PropTypes from "prop-types";
import Styles from "./styles.module.scss";
import Icon from "@Atom/Icon";
import { useEffect, useRef, useState } from "react";
import UseOutsideClick from "@Hooks/useOutsideClick";
import Polygon from "@Assets/Polygon.png";
import ErrorInfo from "@Assets/Images/exclamation-circle.png";
// import Spinner from "@Atom/Spinner";

const DefaultInput = ({
  placeholder,
  autoComplete,
  tabIndex,
  onKeyUp,
  min,
  onChange,
  value,
  name,
  id,
  ref,
  type = "text",
  pattern,
  isError = false,
  isDisabled = false,
  textError = "error",
}) => {
  return (
    <div className={Styles.container}>
      <input
        className={Styles.input}
        placeholder={placeholder}
        min={min}
        tabIndex={tabIndex}
        autoComplete={autoComplete}
        onKeyUp={onKeyUp}
        onChange={onChange}
        value={value}
        name={name}
        id={id}
        ref={ref}
        type={type}
        pattern={pattern}
        is-error={isError?.toString()}
        is-disabled={isDisabled?.toString()}
        disabled={isDisabled}
      />
      {type !== "url" && isError && (
        <>
          <img src={ErrorInfo} alt="" className={Styles.errorInfo} />
          <img src={Polygon} alt="icon" className={Styles.popover} />
          <span className={Styles.errorDesc}>{textError}</span>
        </>
      )}
    </div>
  );
};

const PriceInput = ({
  placeholder,
  autoComplete,
  tabIndex,
  onKeyUp,
  min,
  onChange,
  value,
  name,
}) => {
  return (
    <div className={Styles.container}>
      <div className={Styles.icon}>
        <Icon icon="plus-small" size={16} />
        <div className={Styles.text}>Rp</div>
        <div className={Styles.linePhone}></div>
      </div>
      <input
        className={`${Styles.inputPhone} ${Styles.phone}`}
        placeholder={placeholder}
        min={min}
        tabIndex={tabIndex}
        autoComplete={autoComplete}
        onKeyUp={onKeyUp}
        onChange={onChange}
        value={value}
        type="number"
        name={name}
      />
    </div>
  );
};

const PriceInput2 = ({
  placeholder,
  autoComplete,
  tabIndex,
  onKeyUp,
  min,
  onChange,
  value,
  name,
}) => {
  return (
    <div className={Styles.container}>
      <div className={Styles.icon}>
        <Icon icon="plus-small" size={16} />
        <div className={Styles.text}>Rp</div>
        <div className={Styles.linePhone}></div>
      </div>
      <input
        className={`${Styles.inputPhone} ${Styles.phone}`}
        placeholder={placeholder}
        min={min}
        tabIndex={tabIndex}
        autoComplete={autoComplete}
        onKeyUp={onKeyUp}
        onChange={onChange}
        value={value}
        type="text"
        name={name}
      />
    </div>
  );
};

const PercentInput = ({
  placeholder,
  autoComplete,
  tabIndex,
  onKeyUp,
  min,
  onChange,
  value,
  name,
}) => {
  return (
    <div className={Styles.container}>
      <div className={Styles.iconPercent}>
        <Icon icon="plus-small" size={16} />
        <div className={Styles.linePercent}></div>
        <div className={Styles.textPercent}>%</div>
      </div>
      <input
        className={`${Styles.inputPercent} ${Styles.percent}`}
        placeholder={placeholder}
        min={min}
        tabIndex={tabIndex}
        autoComplete={autoComplete}
        onKeyUp={onKeyUp}
        onChange={onChange}
        value={value}
        type="number"
        name={name}
      />
    </div>
  );
};

const Searchbar = ({
  placeholder,
  autoComplete,
  tabIndex,
  onKeyUp,
  min,
  onChange,
  value,
}) => {
  return (
    <div className={Styles.container}>
      <div className={Styles.iconSearch}>
        <Icon
          icon="magnifying-glass"
          size={20}
          className={Styles.iconMagnifyingGlass}
        />
      </div>
      <input
        className={`${Styles.searchInput} ${Styles.search}`}
        placeholder={placeholder}
        min={min}
        tabIndex={tabIndex}
        autoComplete={autoComplete}
        onKeyUp={onKeyUp}
        onChange={onChange}
        value={value}
        type="text"
      />
    </div>
  );
};

const SearchHistory = ({
  placeholder,
  autoComplete,
  tabIndex,
  onKeyUp,
  min,
  onChange,
  value,
}) => {
  return (
    <div className={Styles.container}>
      <div className={Styles.iconSearch}>
        <Icon
          icon="magnifying-glass"
          size={26}
          className={Styles.iconMagnifyingGlassSearch}
        />
      </div>
      <input
        className={`${Styles.searchInputHistory} ${Styles.search}`}
        placeholder={placeholder}
        min={min}
        tabIndex={tabIndex}
        autoComplete={autoComplete}
        onKeyUp={onKeyUp}
        onChange={onChange}
        value={value}
        type="text"
      />
    </div>
  );
};

const Date = () => {
  return <input type="date" className={Styles.datePicker} />;
};

const Dropdown = ({
  isDisabled,
  placeholder = "Placeholder",
  dataMapDropdown = ["lorem 1", "lorem 2", "lorem 3", "lorem 4"],
  value,
  setValue = () => {},
  isError = false,
  textError = "Error",
}) => {
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const handleChangeDropdown = () => {
    if (isDisabled) {
      return null;
    } else {
      setIsOpenDropdown(!isOpenDropdown);
    }
  };
  const boxRef = useRef();
  const boxOutsideClick = UseOutsideClick(boxRef);
  useEffect(() => {
    if (boxOutsideClick && isOpenDropdown) {
      setIsOpenDropdown(false);
    }
  }, [boxOutsideClick, isOpenDropdown]);
  return (
    <div className={Styles.dropdown}>
      <div
        className={Styles.wrapper}
        isopen={isOpenDropdown?.toString()}
        onClick={handleChangeDropdown}
        isdisabled={isDisabled?.toString()}
        ref={boxRef}
        ischooseitems={value ? "true" : "false"}
        is-empty={isError?.toString()}
      >
        <span ischooseitems={value ? "true" : "false"}>
          {value || placeholder}
        </span>
        <Icon
          icon={"chevron-right"}
          size={"16"}
          className={Styles.chevron}
          isopen={isOpenDropdown?.toString()}
        />
        {isError && (
          <>
            <img src={ErrorInfo} alt="" className={Styles.errorInfo} />
            <img src={Polygon} alt="icon" className={Styles.popover} />
            <span className={Styles.errorDesc}>{textError}</span>
          </>
        )}
      </div>
      <div className={Styles.dropdownItems} isopen={isOpenDropdown?.toString()}>
        <ul>
          {dataMapDropdown?.map((item, idx) => (
            <li key={idx} onClick={() => setValue(item)}>
              {item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const InputForm = ({
  type,
  placeholder,
  autoComplete,
  tabIndex,
  onKeyUp,
  min,
  onChange,
  value,
  name,
  dataMapDropdown,
  isDisabled,
  ref,
  pattern,
  id,
  isError = false,
  textError,
}) => {
  if (type === "rp") {
    return (
      <PriceInput
        placeholder={placeholder}
        autoComplete={autoComplete}
        tabIndex={tabIndex}
        onKeyUp={onKeyUp}
        min={min}
        onChange={onChange}
        value={value}
        name={name}
      />
    );
  }

  if (type === "xrp") {
    return (
      <PriceInput2
        placeholder={placeholder}
        autoComplete={autoComplete}
        tabIndex={tabIndex}
        onKeyUp={onKeyUp}
        min={min}
        onChange={onChange}
        value={value}
        name={name}
      />
    );
  }

  if (type === "percent") {
    return (
      <PercentInput
        placeholder={placeholder}
        autoComplete={autoComplete}
        tabIndex={tabIndex}
        onKeyUp={onKeyUp}
        min={min}
        onChange={onChange}
        value={value}
        name={name}
      />
    );
  }

  if (type === "search") {
    return (
      <Searchbar
        placeholder={placeholder}
        autoComplete={autoComplete}
        tabIndex={tabIndex}
        onKeyUp={onKeyUp}
        min={min}
        onChange={onChange}
        value={value}
      />
    );
  }

  if (type === "search-history") {
    return (
      <SearchHistory
        placeholder={placeholder}
        autoComplete={autoComplete}
        tabIndex={tabIndex}
        onKeyUp={onKeyUp}
        min={min}
        onChange={onChange}
        value={value}
      />
    );
  }
  if (type === "dropdown") {
    return (
      <Dropdown
        placeholder={placeholder}
        value={value}
        isDisabled={isDisabled}
        dataMapDropdown={dataMapDropdown}
        setValue={onChange}
        ref={ref}
        isError={isError}
        textError={textError}
      />
    );
  }
  if (type === "date") return <Date />;
  return (
    <DefaultInput
      placeholder={placeholder}
      autoComplete={autoComplete}
      tabIndex={tabIndex}
      onKeyUp={onKeyUp}
      min={min}
      onChange={onChange}
      value={value}
      name={name}
      id={id}
      pattern={pattern}
      type={type}
      ref={ref}
      isError={isError}
      isDisabled={isDisabled}
      textError={textError}
    />
  );
};

InputForm.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  // className: PropTypes.object,
  name: PropTypes.string,
  autoComplete: PropTypes.string,
  tabIndex: PropTypes.string,
  maxLength: PropTypes.string,
  onKeyUp: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string,
  min: PropTypes.string,
};

export default InputForm;
