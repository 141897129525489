import { NavbarAdmin } from "@Molecule/NavbarAdmin";
import Styles from "./styles.module.scss";
import TableHeader from "@Molecule/TableHeader";
import SPAREPART_DETAIL from "@Assets/Frame 106.png";

const dataProduk = {
  id: 1,
  name: "Oli Filter",
  price: "200000",
  priceDiskon: "190000",
  promo: "murah",
  keterangan:
    "Filter oli asli Yamaha adalah filter oli yang optimal untukmesin Yamaha karena daya tahan dan filtrasi kinerja yangtinggi.Dalam mengembangkan filter oli, Yamaha melakukan tesuntuk memeriksa bagaimana filter sesuai dengan mesin. filteroli asli Yamaha adalah satu-satunya filter minyak yang dapatdigunakan sesuai mesin Yamaha.Pompa oli mengatur sirkulasioli dalam mesin. Minyak menjadi kotor selama operasi normalkarena bersirkulasi melalui transmisi, piston dan silinder,kepala silinder, kopling, oil cooler, dll, dan filter olimenyaring benda asing keluar dari oli mesin.Filter olimenyaring benda asing dalam oli mesin, dan menjadi kotor.filter tidak dapat memperbaiki degradasi oli mesin itusendiri. Ketika filter oli mencapai batas kemampuanfiltrasi, mesin kotor dan oli terus beredar di dalam mesin.Oil mesin menjadi kotor karena pembersihan dan dispersieffect oli mesin membuat bagian dalam mesin bersih denganmengambil partikel jelaga dan partikel dari keausan logamyang dihasilkan di dalam mesin ke dalam minyak. Engine OilMenjadi Kotor Karena Efek Anti-karat oli mesin menempel padapermukaan logam di dalam mesin dan melindungi permukaanlogam dari karat dan korosi. melindungi bagian dalam mesindari kelembaban, udara, dan asam yang terkandung dalamcampuran udara-bahan bakar. Engine Oil Menjadi Kotor Karenamesin Panas oli mesin memberikan pelumas dengan masuk kecelah antara piston suhu tinggi dan silinder. untuk alasanini, Oli kadang-kadang terbakar dan secara bertahap menjadiberwarna lebih gelap. Ketika oli mesin beredar untuk jangkawaktu yang panjang, menjadi kotor, karena partikel logam dankarbon dibakar di mesin. Jika partikel-partikel ini dikirimke mesin dengan oli mesin, dapat menyebabkan mesin untukrusak. Filter oli yang menyaring partikel-partikel inikeluar dari oli mesin.Filter oli Asli Yamaha menjagakeseimbangan daya tahan dan kinerja filtrasi oli yangtinggi.",
  stok: 80,
  photo: SPAREPART_DETAIL,
};

const dataJual = {
  id: 1,
  sellOut: 8,
  total: "200000",
  date: "20 Juni 2021",
  status: "Available",
};

export default function SparepartsDetail() {
  return (
    <div className={Styles.body}>
      <NavbarAdmin
        icon={"sparepartsadmin"}
        size={32}
        color={"#FFFFFF"}
        label={"Onderdil"}
        // isRoute={true}
      />
      <div className={Styles.box}>
        <TableHeader
          type="admin-detail"
          labelNameDetail="Detail Produk"
          pathname={"/admin/spareparts"}
          pathname2={"/admin/spareparts/edit/" + dataProduk.id}
          textButton="Edit"
        />
        <div className={Styles.detailBox}>
          <div className={Styles.leftDetail}>
            <span>Foto Produk</span>
            <div className={Styles.imagesBox}>
              <img src={dataProduk.photo} alt="spareparts-images" />
            </div>
          </div>

          <div className={Styles.rightDetail}>
            <div className={Styles.detail}>
              <span>Nama Produk</span>
              <label>{dataProduk.name}</label>
            </div>

            <div className={Styles.detail}>
              <span>Stok tersisa</span>
              <label>{dataProduk.stok}</label>
            </div>

            <div className={Styles.detail}>
              <span>Harga Utama</span>
              <label>
                Rp. {new Intl.NumberFormat().format(dataProduk.price)}
              </label>
            </div>

            <div className={Styles.detail}>
              <span>Harga Diskon</span>
              <label>
                Rp. {new Intl.NumberFormat().format(dataProduk.priceDiskon)}
              </label>
            </div>

            <div className={Styles.detail}>
              <span>Promo</span>
              <label>{dataProduk.promo}</label>
            </div>

            <div className={Styles.detail}>
              <span>Keterangan</span>
              <label>{dataProduk.keterangan}</label>
            </div>

            <div className={Styles.detail}>
              <span>Penjualan</span>
              <label>{dataJual.sellOut}</label>
            </div>

            <div className={Styles.detail}>
              <span>Total Penjualan</span>
              Rp. {new Intl.NumberFormat().format(dataJual.total)}
            </div>

            <div className={Styles.detail}>
              <span>Tanggal data masuk</span>
              <label>{dataJual.date}</label>
            </div>

            <div className={Styles.detail}>
              <span>Status</span>
              <div
                className={
                  dataJual.status === "Available"
                    ? Styles.statusGreen
                    : Styles.statusRed
                }
              >
                {dataJual.status}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
