import React from "react";
import Styles from "./style.module.scss";
export default function Spinner({ width, height, ...props }) {
  return (
    <div className={Styles.wrapperLoading} {...props}>
      <div
        className={Styles.loadSpinner}
        style={{ width: width, height: height }}
      ></div>
    </div>
  );
}
