import EmptyHandlerGetData from "@Atom/ErrorGetData";
import Spinner from "@Atom/Spinner";
import Promo from "@Organism/Admin/Promo";
import { getListPromo } from "@Services/admin/promo/getListPromo";
import { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";
export const loader = async (route) => {
  const params = new URLSearchParams(route?.request?.url);
  const page = params?.get("page") || 1;
  const limit = params?.get("limit") || 10;
  const searchQuery = params?.get("search") || "";

  return defer({
    getListPromo: await getListPromo(limit, page, searchQuery),
  });
};
export default function AdminMotorcyclePage() {
  const { getListPromo } = useLoaderData();
  return (
    <Suspense fallback={<Spinner />}>
      <Await errorElement={<EmptyHandlerGetData />} resolve={getListPromo}>
        {(data) => <Promo data={data} />}
      </Await>
    </Suspense>
  );
}
