/* eslint-disable no-unused-vars */
import Styles from "./styles.module.scss";
import React, { useState } from "react";
import Icon from "@Atom/Icon";
import Checkbox from "@Atom/Checkbox";
import { Modal } from "antd";
import Spinner from "@Atom/Spinner";
// import LoadingAnimation from "@Atom/LoadingAnimation";
// import PLACEHOLDER_TABLE from "@Assets/images/placeholder-table.png";

const TableServices = ({
  data,
  columns,
  isLoading = false,
  type,
  itemsPerPage,
  placeholderText,
  searchTerm,
  selectedRow,
  recipient,
  selectedItems,
  setSelectedItems,
  selectedUsers,
  setSelectedUsers,
  sticky,
  onClick = () => {},
  ...props
}) => {
  // const [sortConfig, setSortConfig] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");

  // const handleSort = (columnName) => {
  //   let direction = "ascending";

  //   if (
  //     sortConfig &&
  //     sortConfig.column === columnName &&
  //     sortConfig.direction === "ascending"
  //   ) {
  //     direction = "descending";
  //   }

  //   setSortConfig({ column: columnName, direction });
  // };

  const handleChecked = (item) => {
    let isSelected = false;
    for (let i = 0; i < selectedItems.length; i++) {
      if (selectedItems[i]?._id === item?._id) {
        isSelected = true;
        break;
      }
    }
    return isSelected;
  };

  const handleCheckboxChange = (item) => {
    let isSelected = handleChecked(item);
    if (isSelected) {
      setSelectedItems(
        selectedItems.filter((selected) => selected?._id !== item?._id)
      );
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const handleSort = (columnKey) => {
    if (sortColumn === columnKey) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(columnKey);
      setSortDirection("asc");
    }
  };

  const handleMouseEnter = (rowId) => {
    // console.log(rowId, "ROW ID");
    setHoveredRow(rowId);
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedItems([]);
    } else {
      setSelectedItems(data);
    }

    setSelectAll(!selectAll);
  };

  // const handleFilterChange = (event) => {
  //   setFilterValue(event.target.value);
  // };

  const sortedData = sortColumn
    ? [...data].sort((a, b) => {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];
        if (aValue < bValue) return sortDirection === "asc" ? -1 : 1;
        if (aValue > bValue) return sortDirection === "asc" ? 1 : -1;
        return 0;
      })
    : data;
  // const sortedData = data.sort((a, b) => {
  //   const valueA = a[sortColumn];
  //   const valueB = b[sortColumn];
  //   if (sortDirection === "asc") {
  //     return valueA > valueB ? 1 : -1;
  //   } else {
  //     return valueA < valueB ? 1 : -1;
  //   }
  // });

  // Filter data based on search value
  const filteredData = searchTerm
    ? sortedData.filter((row) => {
        return Object.values(row).some((value) =>
          String(value).toLowerCase().includes(searchTerm.toLowerCase())
        );
      })
    : sortedData;

  const totalItems = filteredData?.length;
  // const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = filteredData?.slice(startIndex, endIndex);

  // console.log(selectedItems, 'selected')

  // console.log(recipient?.map((each) => each?.name), 'wwww')

  const isRecipient = recipient?.map((each) => each?.name);

  // master
  const handleCheckedMaster = (item) => {
    let isSelected = false;
    for (let i = 0; i < selectedUsers.length; i++) {
      if (selectedUsers[i]?.nrk === item?.nrk) {
        isSelected = true;
        break;
      }
    }
    return isSelected;
  };

  // console.log(selectedUsers);

  const handleCheckboxChangeMaster = (item) => {
    let isSelected = handleCheckedMaster(item);
    if (isSelected) {
      setSelectedUsers(
        selectedUsers.filter((selected) => selected?.nrk !== item?.nrk)
      );
    } else {
      setSelectedUsers([...selectedUsers, item]);
    }
  };

  if (type === "Jadwal Distribusi") {
    return (
      <div className={Styles.body}>
        {isLoading ? (
          <div className={Styles.loading}>
            {/* <LoadingAnimation /> */}
            loading...
          </div>
        ) : (
          <div className={Styles.tableContainer}>
            {data?.length === 0 ? (
              <div className={Styles.placeholder}>
                {/* <img src={PLACEHOLDER_TABLE} alt="placeholder" /> */}
                <span>{placeholderText}</span>
              </div>
            ) : (
              <table is-promo={type === "promo" ? "true" : "false"}>
                <thead>
                  <tr
                  // className={Styles.withCheckbox}
                  >
                    <th className={Styles.stickyColumn}>
                      {/* <Checkbox
                        checked={selectAll}
                        onChange={handleSelectAll}
                        disabled={recipient?.name ? false : true}
                      /> */}
                    </th>
                    {columns?.map((column) => (
                      <th
                        key={column.name}
                        onClick={() => handleSort(column.name)}
                      >
                        <div className={Styles.column}>
                          {column.label}
                          {sortColumn === column.name && (
                            <span>
                              {sortDirection.direction === "asc" ? (
                                <Icon icon="chevron-up-down" size="14" />
                              ) : (
                                <Icon icon="chevron-up-down" size="14" />
                              )}
                            </span>
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {currentItems?.map((row, index) => (
                    <tr
                      key={index}
                      className={`${
                        row.id === hoveredRow ? Styles.hovered : ""
                      } ${Styles.hoveredFirstRow}`}
                      onMouseEnter={() => handleMouseEnter(row.id)}
                      onMouseLeave={handleMouseLeave}
                    >
                      <td className={Styles.stickyColumn}>
                        <Checkbox
                          checked={handleChecked(row)}
                          onChange={() => handleCheckboxChange(row)}
                          disabled={row.recipient.name ? true : false}
                        />
                      </td>

                      {columns?.map((column) => (
                        <td key={column.name}>
                          {column.render
                            ? column.render(row)
                            : row[column.name]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        )}
      </div>
    );
  }

  if (type === "Master") {
    return (
      <div className={Styles.body}>
        {isLoading ? (
          <div className={Styles.loading}>
            {/* <LoadingAnimation /> */}
            loading....
          </div>
        ) : (
          <div className={Styles.tableContainer}>
            {data?.length === 0 ? (
              <div className={Styles.placeholder}>
                {/* <img src={PLACEHOLDER_TABLE} alt="placeholder" /> */}
                <span>{placeholderText}</span>
              </div>
            ) : (
              <table>
                <thead>
                  <tr
                  // className={Styles.withCheckbox}
                  >
                    <th className={Styles.stickyColumn}>
                      {/* tester */}
                      {/* <Checkbox
                        checked={selectAll}
                        onChange={handleSelectAll}
                        disabled={recipient?.name ? false : true}
                      /> */}
                    </th>
                    {columns?.map((column) => (
                      <th
                        key={column.name}
                        onClick={() => handleSort(column.name)}
                      >
                        <div className={Styles.column}>
                          {column.label}
                          {sortColumn === column.name && (
                            <span>
                              {sortDirection.direction === "asc" ? (
                                <Icon icon="chevron-up-down" size="14" />
                              ) : (
                                <Icon icon="chevron-up-down" size="14" />
                              )}
                            </span>
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {currentItems?.map((row, index) => (
                    <tr
                      key={index}
                      className={`${
                        row.id === hoveredRow ? Styles.hovered : ""
                      } ${Styles.hoveredFirstRow}`}
                      onMouseEnter={() => handleMouseEnter(row.id)}
                      onMouseLeave={handleMouseLeave}
                    >
                      <td className={Styles.stickyColumn}>
                        <Checkbox
                          checked={handleCheckedMaster(row)}
                          onChange={() => handleCheckboxChangeMaster(row)}
                          // disabled={row.recipient.name ? true : false}
                        />
                      </td>

                      {columns?.map((column) => (
                        <td key={column.name}>
                          {column.render
                            ? column.render(row)
                            : row[column.name]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        )}
      </div>
    );
  }

  // console.log(sticky);
  if (type === "sticky") {
    return (
      <div className={Styles.body}>
        {isLoading ? (
          <div className={Styles.loading}>
            <Spinner
              style={{
                background: "transparent",
                padding: "0",
                border: "none",
              }}
            />
          </div>
        ) : (
          <div className={Styles.tableContainer}>
            {data?.length === 0 ? (
              <div className={Styles.placeholder}>
                {/* <img src={PLACEHOLDER_TABLE} alt="placeholder" /> */}
                <span>{placeholderText}</span>
              </div>
            ) : (
              <table
                style={{
                  width: type?.toLowerCase()?.includes("sales") ? "100%" : "",
                }}
              >
                <thead>
                  <tr>
                    {/* <th className={Styles.stickyColumnDefault}></th> */}
                    {columns?.map((column) => (
                      <th
                        key={column.name}
                        className={
                          column.label === sticky ? Styles.stickyColumn : ""
                        }
                      >
                        <div className={Styles.column}>
                          {
                            column.label
                            //  + "/" + column
                          }
                          {sortColumn === column.name && (
                            <span>
                              {sortDirection.direction === "asc" ? (
                                <Icon icon="chevron-up-down" size="14" />
                              ) : (
                                <Icon icon="chevron-up-down" size="14" />
                              )}
                            </span>
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                  {/* 
                  <tr>
                    <th className="sticky-column"></th>
                    {columns.map((column) => (
                      <th key={column.key}>{column.label}</th>
                    ))}
                  </tr> */}
                </thead>
                <tbody>
                  {currentItems?.map((row, index) => (
                    <tr
                      key={index}
                      className={`${
                        row.id === hoveredRow || row?.id === selectedRow
                          ? Styles.hovered
                          : ""
                      } ${Styles.hoveredFirstRow}`}
                      onMouseEnter={() => handleMouseEnter(row.id)}
                      onMouseLeave={handleMouseLeave}
                    >
                      {/* <td className={Styles.stickyColumnDefault}>{row.id}</td> */}
                      {columns?.map((column) => (
                        <td
                          key={column.name}
                          // className={Styles.stickyColumnDefault}
                          className={
                            column.label === sticky ? Styles.stickyColumn : ""
                          }
                        >
                          {column.render
                            ? column.render(row)
                            : row[column.name]}
                        </td>
                      ))}
                    </tr>
                  ))}

                  {/* <td className="sticky-column">{row.id}</td>
                  {columns.map((column) => (
                    <td key={column.key}>
                      {column.render ? (
                        column.render(row)
                      ) : (
                        row[column.key]
                      )}
                    </td>
                  ))} */}
                </tbody>
              </table>
            )}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={Styles.body} {...props}>
      {isLoading ? (
        <div className={Styles.loading}>
          <Spinner
            style={{
              background: "transparent",
              padding: "0",
              border: "none",
            }}
          />
        </div>
      ) : (
        <div className={Styles.tableContainer}>
          {data?.length === 0 ? (
            <div className={Styles.placeholder}>
              <span>{placeholderText}</span>
            </div>
          ) : (
            <table
              style={{
                width: type?.toLowerCase()?.includes("sales") ? "100%" : "",
              }}
              is-promo={type === "promo" ? "true" : "false"}
            >
              <thead>
                <tr>
                  {columns?.map((column) => (
                    <th
                      key={column.name}
                      onClick={() => handleSort(column.name)}
                    >
                      <div className={Styles.column}>
                        {column.label}
                        {sortColumn === column.name && (
                          <span>
                            {sortDirection.direction === "asc" ? (
                              <Icon icon="chevron-up-down" size="14" />
                            ) : (
                              <Icon icon="chevron-up-down" size="14" />
                            )}
                          </span>
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {currentItems?.map((row, index) => (
                  <tr
                    key={index}
                    className={`${
                      row.id === hoveredRow || row?.id === selectedRow
                        ? Styles.hovered
                        : ""
                    } ${Styles.hoveredFirstRow}`}
                    onMouseEnter={() => handleMouseEnter(row.id)}
                    onMouseLeave={handleMouseLeave}
                    onClick={onClick}
                  >
                    {/* <td className={Styles.stickyColumnDefault}>{row.id}</td> */}
                    {columns?.map((column) => (
                      <td
                        key={column.name}
                        // className={Styles.stickyColumnDefault}
                      >
                        {column.render ? column.render(row) : row[column.name]}
                      </td>
                    ))}
                  </tr>
                ))}

                {/* <td className="sticky-column">{row.id}</td>
                {columns.map((column) => (
                  <td key={column.key}>
                    {column.render ? (
                      column.render(row)
                    ) : (
                      row[column.key]
                    )}
                  </td>
                ))} */}
              </tbody>
            </table>
          )}
        </div>
      )}
    </div>
  );
};

export default TableServices;
